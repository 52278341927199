import { API } from '.'

export const upsertClient = (payload) => {
  return API.post(
    'upsertclient',
    payload,
  )
};

export const upsertProject = (payload) => {
  return API.post(
    'upsertproject',
    payload,
  )
};


export const getteammatching = (payload) => {
  return API.post(
    'getteammatching',
    payload,
  )
};

export const getteamfeasibility = (payload) => {
  return API.post(
    'getteamfeasibility',
    payload,
  )
};

export const getclienttagsengine = (payload) => {
  return API.post(
    'getclienttagsengine',
    payload,
  )
};

export const generateclientteams = (payload) => {
  return API.post(
    'generateclientteams',
    payload,
  )
};

export const sendClientContract = (payload) => {
  return API.post(
    'sendClientContract',
    payload,
  )
};

export const getClient = (payload) => {
  return API.get(
    'getclient',
    payload,
  )
};