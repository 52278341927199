import React, { createContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [userData, setUserData] = useState(null);

  return (
    <AppContext.Provider
      value={{
        userData,
        setUserData
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;


