import React, { useState } from 'react'
import { MainFooter, SignupHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import { forgotpasswordCode } from '../../api/auth'
import { useSnackbar } from 'notistack'
import { isEmail } from '../../utils/utilities'

export default function ForgotPassword() {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        "emailAddress": "",
        "loading": false,
        "required": false,
    })
    const { loading, required, emailAddress } = state;

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }


    const handleSignin = async () => {
        try {
            // history.push('/reset-password')
            if (disabled) {
                handleChange("required", true)
                return
            }
            handleChange('required', false)
            handleChange('loading', true)
            const payload = {
                emailAddress
            }
            const res = await forgotpasswordCode(payload)
            if (res.data.statusCode === 200) {
                handleChange('loading', false)
                localStorage.setItem("email", emailAddress)
                enqueueSnackbar(`Email Has Been Sent!`, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                history.push('/reset-password')
            } else {
                handleChange('loading', false)
                enqueueSnackbar(`Error: ${res.data.result.message}`, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            }
        } catch (error) {
            handleChange('loading', false)
            // console.log('err', error);
            enqueueSnackbar(`Error: ${error.message}`, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }
    }

    const disabled = !emailAddress || loading

    return (
        <div>
            <SignupHeader />
            <section class="forgotOuter">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-8 col-sm-12">
                            <div class="login col-lg-12 col-md-10 col-sm-12">
                                <div class="loginIn">
                                    <div class="loginPara">
                                        <h1 className='text_blue'>Reset your password</h1>
                                        <p className='text_blue mt-2'>We’ll send you instructions to reset your password and get you back on track.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="logingroup mt-5 col-lg-7 col-md-10 col-sm-12">
                                <input type="text" onChange={(value) => handleChange('emailAddress', value.target.value)} placeholder="Email" />
                                {
                                    emailAddress && isEmail(emailAddress) &&
                                    <div>
                                        <small> Email must be a valid email
                                            address !</small>
                                    </div>
                                }
                                {
                                    required && !emailAddress &&
                                    <div>
                                        <small>Email Address is required !</small>
                                    </div>
                                }
                            </div>
                            <div class="logingroup mt-5 col-lg-7 col-md-10 col-sm-12">
                                <button
                                    onClick={() => handleSignin()}
                                    // disabled={disabled}
                                    class={`btn ${"btn-outline-warning"}`}
                                // class={`btn ${disabled ? "btn-login-outline-warning" : "btn-login-outline-warning-active"}`}
                                >Reset Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MainFooter />
        </div >
    )
}