import React from 'react'
import { SearchOutlined, ArrowForward } from '@mui/icons-material'
import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@mui/material'
import UserList from '../../assets/img/userList.png'

function generate (element) {
  return [0, 1, 2, 0, 1, 2, 0, 1, 2].map(value =>
    React.cloneElement(element, {
      key: value
    })
  )
}

function generate1 (element) {
  return [0].map(value =>
    React.cloneElement(element, {
      key: value
    })
  )
}

export default function Notifications ({ item }) {
  const [dense, setDense] = React.useState(false)
  const [secondary, setSecondary] = React.useState(false)
  return (
    <section class='sec-4 height100vh'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <div class='content'>
              <div class='d-flex justify-content-between'>
                <div className='d-flex'>
                  <h5
                    className={`${
                      dense ? 'text_green' : 'text_grey_dark'
                    } cursor-pointer mr-2`}
                    onClick={() => setDense(true)}
                  >
                    All{' '}
                  </h5>
                  <h5
                    className={`${
                      !dense ? 'text_green' : 'text_grey_dark'
                    } cursor-pointer`}
                    onClick={() => setDense(false)}
                  >
                    Team
                  </h5>
                </div>
                <div class='searchInput'>
                  <input
                    type='text'
                    // onChange={(value) => handleChange('voucher', value.target.value)}
                    formcontrolname='text'
                    placeholder='Search'
                  />
                  <SearchOutlined />
                </div>
              </div>
            </div>
          </div>
        </div>

        <List>
          {item?.notifications?.map((notification, index) => (
            <ListItem
              secondaryAction={
                <div className='row align-items-center'>
                  <p className='mr-2'>00/00/00</p>
                  <p className='mr-5 mt-0'>00:00</p>
                  <p className='mt-0 ml-5'>View</p>
                  <IconButton edge='end' aria-label='delete'>
                    <ArrowForward />
                  </IconButton>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar src={UserList} />
              </ListItemAvatar>
              <ListItemText
                className='hoverGreen'
                primary={'Adu has uploaded a new file'}
                secondary={secondary ? 'Secondary text' : null}
              />
            </ListItem>
          ))}
        </List>
        <h5>Old</h5>
        <List>
          {item?.notifications?.map((notification, index) => (
            <ListItem
              secondaryAction={
                <div className='row align-items-center'>
                  <p className='mr-2'>00/00/00</p>
                  <p className='mr-5 mt-0'>00:00</p>
                  <p className='mt-0 ml-5'>View</p>
                  <IconButton edge='end' aria-label='delete'>
                    <ArrowForward />
                  </IconButton>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar src={UserList} />
              </ListItemAvatar>
              <ListItemText
                primary='Adu has uploaded a new file'
                secondary={secondary ? 'Secondary text' : null}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </section>
  )
}
