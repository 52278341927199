import React from 'react'
import { HomeFooter, MainFooter, HomeHeader, Card } from '../../components'
import Scroll, { Link, Element } from 'react-scroll'
import Humility from '../../assets/svg/Group 6179.svg'
import curiosity from '../../assets/svg/Group 6314.svg'
import excellence from '../../assets/svg/Group 6345.svg'
import diversity from '../../assets/svg/diversity.svg'
import governence from '../../assets/svg/governence.svg'
import social from '../../assets/svg/social.svg'
import Model from '../../assets/svg/Model.svg'
import environment from '../../assets/svg/environment.svg'
import monitor from '../../assets/svg/monitor.svg'
import positioning from '../../assets/svg/Group 7124.svg'
import respect from '../../assets/svg/respect.svg'
import checkedCircle from '../../assets/svg/checkedCircle.svg'
import dollarBuilding from '../../assets/svg/dollarBuilding.svg'
import exclaimation from '../../assets/svg/exclaimation.svg'
import eye from '../../assets/svg/eye.svg'
import card from '../../assets/svg/card.svg'
import comingsoon from '../../assets/svg/comingsoon.svg'
import inexus from '../../assets/svg/inexus.svg'
import gartner from '../../assets/svg/gartner.svg'
import prosci from '../../assets/svg/prosci.svg'
import affairs from '../../assets/svg/affairs.svg'
import image20 from '../../assets/svg/Group 5634.svg'
import image21 from '../../assets/svg/Group 5635.svg'
import image22 from '../../assets/svg/Group 5636.svg'
import './index.css'

export default function RequestADemo ({}) {
  var scroll = Scroll.animateScroll
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      {/* <!-- banner section start --> */}
      <Element name='Element1' className='element'>
        <section class='request'>
          <div class='container height100 d-flex align-items-center mt-5'>
            <div class='banner_content'>
              <h1 className={'font-80-home'}>Request A Demo</h1>
              <p className={'text-white homeSubText mt-3'}>
                Accelerate value realization through expert teams
              </p>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      <section class=''>
        <div class='container'>
          <div className='row justify-content-between'>
            <div className='col-md-6'>
              <h1 className='text_grey_dark font-open-bold mb-5 font-mobile-34'>
                The problem with
                <br className='displayMobileHide' /> today's market
              </h1>
              <div className='grey_box'>
                <img className='mr-3' src={card} height={80} />
                <div className='col-md-10'>
                  <h1 className='text_green font-mobile-24'>$1.9T Lost</h1>
                  <p className='font-12 '>
                    Over $1.9T spent on digital transformation efforts in 2019
                  </p>
                </div>
              </div>
              <div className='grey_box'>
                <img className='mr-3' src={eye} height={80} />
                <div className='col-md-10'>
                  <h1 className='text_green font-mobile-24'>84% Failed</h1>
                  <p className='font-12 '>
                    84% of digital transformation efforts failed to meet
                    expectations
                  </p>
                </div>
              </div>
              <div className='red_box'>
                <img
                  className='red_box_image'
                  src={exclaimation}
                  height={80}
                />
                <div className='col-md-10 mhm-0'>
                  <p className='font-12 text-white width80'>The problem:</p>
                  <h3 className='text-white font-mobile-16'>
                    People and Expertise.
                  </h3>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <h1 className='text_blue font-open-bold mtm-5 mb-5 font-mobile-34'>
                The solution
                <br className='displayMobileHide' /> with Egtos
              </h1>
              <div className='blue_light_box'>
                <img className='mr-3' src={dollarBuilding} height={80} />
                <div className='col-md-10'>
                  <p className='text_blue_light font-18'>
                    Carefully selected global teams of industry experts
                  </p>
                </div>
              </div>
              <div className='blue_light_box'>
                <img className='mr-3' src={respect} height={80} />
                <div className='col-md-10'>
                  <p className='text_blue_light font-18'>
                    Value-based engagement delivery over the traditional model
                  </p>
                </div>
              </div>
              <div className='blue_box'>
                <img className='mr-3' src={checkedCircle} height={80} />
                <div className='col-md-10'>
                  <h5 className='text-white font-weight-bold'>
                    Build Expert teams in seconds for critical projects with
                    money back guarantee
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container phm-1 mhm-0'>
          <div className='grey_box_full pl-4 pr-4'>
            <h5 className='text_green'>Learn about Egtos technology!</h5>
            <p className='text_blue width80 mt-3'>
              One of egtos team member will contact you after receiving to your
              request demo our world-class technology. Please note our demo
              takes about 90 minutes to complete.
            </p>
            <div className='row mt-4'>
              <div className='col-md-6'>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='First name'
                  />
                </div>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Last name'
                  />
                </div>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Phone number'
                  />
                </div>
                <div class='logingroup'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Business Email Address'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Company Name'
                  />
                </div>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Job title'
                  />
                </div>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Company size'
                  />
                </div>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Country'
                  />
                </div>
                <div class='logingroup mt-5 mb-0'>
                  <div class='row'>
                    <div class=' col-sm-12'>
                      <div class='logingroupIn'>
                        <div class='select_wrap'>
                          <select
                            // ref={openSelect}
                            id={'Select1'}
                            class='form-select'
                            // onChange={value =>
                            //   handleChange(
                            //     'referenceSource',
                            //     value.target.value
                            //   )
                            // }
                          >
                            <option value=''>
                              How did you hear about Egtos?
                            </option>
                            <option value={'Email from egtos'}>
                              Email from egtos
                            </option>
                            <option value={'Google'}>Google</option>
                            <option value={'In Person Event'}>
                              In Person Event
                            </option>
                            <option value={'In the News'}>In the News</option>
                            <option value={'LinkedIn'}>LinkedIn</option>
                            <option value={'Referral'}>Referral</option>
                            <option value={'Twitter'}>Twitter</option>
                            <option value={'Webinar'}>Webinar</option>
                          </select>
                          <button
                            onClick={() =>
                              document.getElementById('Select1').click()
                            }
                            type='button'
                          >
                            <i class='fas fa-arrow-down'></i>
                          </button>
                        </div>
                      </div>
                      {/* {required && !referenceSource && (
                        <div style={{ marginTop: -15 }}>
                          <small>Reference Source is required !</small>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <label class='container'>
                  <input
                    type='checkbox'
                    checked='checked'
                    className='checkboxRequest'
                  />
                  <span class='checkmark'></span>
                  By checking this box, you consent to receiving the latest
                  research, news, events and webinars from egtos.
                </label>
                {/* </div> */}
                <div class='logingroup m-0 col-lg-8 col-md-10 col-sm-12'>
                  <button
                    // onClick={() => handleSignup()}
                    // disabled={disabled}
                    // class={`btn ${disabled ? "btn-outline-warning" : "btn-outline-warning"}`}
                    class={`btn ${'submitbutton fullWidthMobile'}`}
                  >
                    {'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Element>
        <section class='agile-section'>
          <div class='container'>
            <h2 className='text_blue mb-5 font-open-bold text-center'>
              Join our happy clients
            </h2>
            <div class='row justify-content-between'>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
            </div>
            <div class='row justify-content-around'>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={comingsoon} className={'width80'} />
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* <!--section 4 end--> */}

      {/* <div className='hideWeb'>
        <HomeFooter />
      </div> */}
      <MainFooter />
    </div>
  )
}
