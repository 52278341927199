import React, { useState } from 'react'
import { Uploader } from 'rsuite'

export default function ClientProcess1 ({
  onClickButton,
  handleChange,
  taxnumber,
  voucher
}) {
  const [state, setState] = useState({
    required: false
  })
  const { required } = state
  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const disabled = !voucher || !taxnumber

  const _onClickButton = () => {
    if (disabled) {
      handleChangeLocal('required', true)
      return
    }
    handleChangeLocal('required', false)
    onClickButton('process2')
  }

  return (
    <section class='loginOuter'>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-6 col-md-8 col-sm-12'>
            <div class='login'>
              <div class='loginIn'>
                <div class='loginPara'>
                  <h3>Sign Up Egtos</h3>
                  <h5>
                    Thank you for signing the Egtos agreement and welcome to a
                    truly future of work platform
                  </h5>
                  <h4 className={'mt-5'}>Taxes</h4>
                </div>

                <div class='question_box'>
                  <h5>Yeah, we all have to pay it.</h5>
                  <h5>
                    What is your national identification number for reporting
                    your taxes in your country?
                  </h5>
                  <div class='logingroup'>
                    <input
                      type='text'
                      onChange={value =>
                        handleChange('taxnumber', value.target.value)
                      }
                      placeholder='National identification number'
                    />
                    {required && !taxnumber && (
                      <div>
                        <small>
                          National identification number is required !
                        </small>
                      </div>
                    )}
                  </div>
                </div>

                <div class='question_box'>
                  <h5>
                    If you pay your taxes through a registered business entity
                    as an independent consultant, please upload the official
                    document with your business registration number or employer
                    identification number:{' '}
                  </h5>

                  <div class='logingroup'>
                    <div class='logingroupUpload'>
                      <div class='upload-btn-wrapper'>
                        <Uploader
                          listType='picture-text'
                          accept='application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                          action='//jsonplaceholder.typicode.com/posts/'
                          onChange={value => handleChange('files', value)}
                          renderFileInfo={(file, fileElement) => {
                            return (
                              <div>
                                <span>File Name: {file.name}</span>
                                <p style={{ marginLeft: 13 }}>
                                  File type: {file.blobFile.type}
                                </p>
                              </div>
                            )
                          }}
                        >
                          <button class='btn'>
                            Upload your official document
                          </button>
                        </Uploader>
                        {/* <input type="file" name="myfile" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> */}
                      </div>

                      <span>File formates: Word or PDF</span>
                    </div>
                  </div>
                </div>

                <div class='question_box'>
                  <h5>What is your prefered payment option?</h5>

                  <div class='loginGenderRadio'>
                    <div class='row'>
                      <div class='col-lg-4 col-md-4 col-xs-6'>
                        <div class='form-check mobilemb'>
                          <input
                            class='form-check-input'
                            type='radio'
                            // checked={preferedPayment === 'ACH'}
                            // onChange={(value) => handleChange('preferedPayment', value.target.value)}
                            value='ACH'
                          />
                          <label class='form-check-label' for=''>
                            Bank transfer - ACH
                          </label>
                        </div>
                      </div>
                      <div class='col-lg-4 col-md-4 col-xs-6'>
                        <div class='form-check mobilemb'>
                          <input
                            class='form-check-input'
                            type='radio'
                            // checked={preferedPayment === 'PayPal'}
                            // onChange={(value) => handleChange('preferedPayment', value.target.value)}
                            value='PayPal'
                          />
                          <label class='form-check-label' for=''>
                            PayPal
                          </label>
                        </div>
                      </div>

                      <div class='col-lg-4 col-md-4 col-xs-6'>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            // checked={preferedPayment === 'Wise'}
                            // onChange={(value) => handleChange('preferedPayment', value.target.value)}
                            value='Wise'
                          />
                          <label class='form-check-label' for=''>
                            Wise
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='question_box'>
                  <h5>
                    Please enter the applicable bank information for earnings
                    transfer
                  </h5>
                  <div class='row'>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin2'>
                        <input
                          type='text'
                          formcontrolname='text'
                          placeholder='Account Number'
                        />
                      </div>
                    </div>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin2'>
                        <input
                          type='text'
                          formcontrolname='text'
                          placeholder='Routing Number'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='row marginInputMobile'>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin2'>
                        <input
                          type='text'
                          formcontrolname='text'
                          placeholder='IBAN'
                        />
                      </div>
                    </div>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin2'>
                        <input
                          type='text'
                          formcontrolname='text'
                          placeholder='Bank code (BIC/SWIFT)'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class='question_box'>
                  <h5>Please enter the applicable discount code/voucher</h5>
                  <div class='row'>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin2'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange('voucher', value.target.value)
                          }
                          formcontrolname='text'
                          placeholder='Discount code'
                        />
                        {required && !voucher && (
                          <div>
                            <small>Discount code is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='logingroup mt-5'>
                  <button
                    onClick={() => _onClickButton()}
                    class='btn btn-outline-warning'
                  >
                    Save and move on
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
