import React, { useState } from 'react'
import { HomeFooter, MainFooter, HomeHeader } from '../../components'
import { Element } from 'react-scroll'
import './index.css'
import { Loader, Uploader } from 'rsuite'

export default function Career ({}) {
  const [isActive, setIsActive] = useState(false)
  const [state, setState] = useState({})
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      <Element name='Element1' className='element'>
        <section class='explore'>
          <div class='container height100 d-flex align-items-center mt-5'>
            <div class='banner_content'>
              <h1 className={'font-80-home'}>Career @ egtos</h1>
              <p className={'text-white homeSubText mt-3'}>
                Let's a build a great culture together
              </p>
            </div>
          </div>
        </section>
      </Element>
      {isActive ? (
        <section class=''>
          <div class='container height100 d-flex align-items-center'>
            <div class='row'>
              <div class='col-md-8'>
                <div>
                  <h4 className={'text_green'}>{'VP of EMEA Growth'}</h4>
                  <p className={'mt-2 font-18 font-weight-bold'}>
                    Location: Euprope
                  </p>
                  <div className='d-flex justify-content-between'>
                    <p className={'mt-2'}>
                      Short descriptive text about the project goes over a
                      couple of lines descriptive text about the project goes
                      over a couple of lines Short descriptive text about the
                      project goes over a couple of lines. Short descriptive
                      text about the project goes over a couple of lines
                      descriptive text about the project goes over a couple of
                      lines Short descriptive text about the project goes over a
                      couple of lines. Short descriptive text about the project
                      goes over a couple of lines descriptive text about the
                      project goes over a couple of lines Short descriptive text
                      about the project goes over a couple of lines. Short
                      descriptive text about the project goes over a couple of
                      lines descriptive text about the project goes over a
                      couple of lines Short descriptive text about the project
                      goes over a couple of lines. Short descriptive text about
                      the project goes over a couple of lines descriptive text
                      about the project goes over a couple of lines Short
                      descriptive text about the project goes over a couple of
                      lines. Short descriptive text about the project goes over
                      a couple of lines descriptive text about the project goes
                      over a couple of lines Short descriptive text about the
                      project goes over a couple of lines.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <p className={'mt-2 mb-3 font-24 font-weight-bold'}>Apply</p>
                <div class='d-flex justify-content-between'>
                  <div class='logingroup mr-2 mb-2'>
                    <input
                      type='text'
                      // onChange={value =>
                      //   handleChange('conflictCompany1', value.target.value)
                      // }
                      placeholder='First Name'
                    />
                  </div>
                  <div class='logingroup mb-2'>
                    <input
                      type='text'
                      // onChange={value =>
                      //   handleChange('conflictCompany1', value.target.value)
                      // }
                      placeholder='Last Name'
                    />
                  </div>
                </div>
                <div class='logingroup mb-2'>
                  <input
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Email'
                  />
                </div>
                <div class='logingroup mb-2'>
                  <textarea
                    type='text'
                    // onChange={value =>
                    //   handleChange('conflictCompany1', value.target.value)
                    // }
                    placeholder='Message'
                  />
                </div>
                <div class='logingroup'>
                  <div class='logingroupUpload'>
                    <div class='upload-btn-wrapper'>
                      <Uploader
                        listType='picture-text'
                        accept='application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        action='//jsonplaceholder.typicode.com/posts/'
                        onChange={value => handleChange('files', value)}
                        renderFileInfo={(file, fileElement) => {
                          return (
                            <div>
                              <span>File Name: {file.name}</span>
                              <p style={{ marginLeft: 13 }}>
                                File type: {file.blobFile.type}
                              </p>
                            </div>
                          )
                        }}
                      >
                        <button class='btn'>Upload your resume / CV</button>
                      </Uploader>
                      {/* <input type="file" name="myfile" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> */}
                    </div>
                  </div>
                  <div className='mt-2'>File formates: Word or PDF</div>
                </div>
                {/* </div> */}
                <div class='logingroup'>
                  <button
                    onClick={() => setIsActive(false)}
                    // disabled={disabled}
                    // class={`btn ${disabled ? "btn-outline-warning" : "btn-outline-warning"}`}
                    class={`btn ${'applybutton mt-3 fullWidthMobile'}`}
                  >
                    {'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Element name='Element2'>
          <section class=''>
            <div class='container mb-5'>
              <p className={'text_blue subText'}>
                Disqui venis et et quiae libus ullam esto ipsae plabore reici
                del is eario volorerro imus voluptatus, conserf erovite
                ratibeatur aut asitium et eossita taspis aut eium experro
                videlentiae. Lore disqui venis et et quiae libus ullam esto
                ipsae plabore reici del is eario.
              </p>
              <div className='d-flex justify-content-end'>
                <button className='positionbutton'>Open Position</button>
              </div>
            </div>
            <div class='container height100 d-flex align-items-center'>
              <div class='row'>
                <div class='col-md-12 shadowBox'>
                  <div>
                    <h4 className={'text_green'}>{'VP of EMEA Growth'}</h4>
                    <p className={'mt-2 font-18 font-weight-bold'}>
                      Location: Euprope
                    </p>
                    <div className='d-flex justify-content-between'>
                      <p className={'mt-2'} style={{ width: '80%' }}>
                        Short descriptive text about the project goes over a
                        couple of lines descriptive text about the project goes
                        over a couple of lines Short descriptive text about the
                        project goes over a couple of lines. Short descriptive
                        text about the project goes over a couple of lines
                        descriptive text about the project goes over a couple of
                        lines Short descriptive text about the project goes over
                        a couple of lines.
                      </p>
                      <button
                        onClick={() => setIsActive(true)}
                        className='positionbutton'
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                <div class='col-md-12 shadowBox'>
                  <div>
                    <h4 className={'text_green'}>{'HR Analyst'}</h4>
                    <p className={'mt-2 font-18 font-weight-bold'}>
                      Location: Americas
                    </p>
                    <div className='d-flex justify-content-between'>
                      <p className={'mt-2'} style={{ width: '80%' }}>
                        Short descriptive text about the project goes over a
                        couple of lines descriptive text about the project goes
                        over a couple of lines Short descriptive text about the
                        project goes over a couple of lines. Short descriptive
                        text about the project goes over a couple of lines
                        descriptive text about the project goes over a couple of
                        lines Short descriptive text about the project goes over
                        a couple of lines.
                      </p>
                      <button
                        onClick={() => setIsActive(true)}
                        className='positionbutton'
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
                <div class='col-md-12 shadowBox'>
                  <div>
                    <h4 className={'text_green'}>{'VP of EMEA Growth'}</h4>
                    <p className={'mt-2 font-18 font-weight-bold'}>
                      Location: Euprope
                    </p>
                    <div className='d-flex justify-content-between'>
                      <p className={'mt-2'} style={{ width: '80%' }}>
                        Short descriptive text about the project goes over a
                        couple of lines descriptive text about the project goes
                        over a couple of lines Short descriptive text about the
                        project goes over a couple of lines. Short descriptive
                        text about the project goes over a couple of lines
                        descriptive text about the project goes over a couple of
                        lines Short descriptive text about the project goes over
                        a couple of lines.
                      </p>
                      <button
                        onClick={() => setIsActive(true)}
                        className='positionbutton'
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>
      )}

      <div className='hideWeb'>
        <HomeFooter displayMobileHide />
      </div>
      <MainFooter />
    </div>
  )
}
