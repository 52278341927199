import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/logo.png'
import LogoBlue from '../../assets/img/logob.png'
import { useHistory } from 'react-router-dom'
import menu_icon from '../../assets/img/menu_icon.png'
import { Drawer, Message, toaster } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'

export default function HomeHeader ({ bgBlue, bgBlackLight, blueTop }) {
  const history = useHistory()
  const [state, setState] = useState({
    visible: false,
    dropdownOpen: false
  })
  const { dropdownOpen, visible } = state
  const showDrawer = () => {
    setState(pre => ({
      ...pre,
      visible: true
    }))
  }

  const onClose = () => {
    setState(pre => ({
      ...pre,
      visible: false
    }))
  }

  const handleStateChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const onlogout = () => {
    localStorage.removeItem('userData')
    toaster.push(
      <Message
        style={{ backgroundColor: 'rgb(56, 142, 60)', color: '#fff' }}
        type={'success'}
      >
        {'Logout!'}
      </Message>,
      {
        placement: 'topEnd'
      }
    )
    history.push('/login')
  }

  let userData = localStorage.getItem('userData')
  userData = JSON.parse(userData)
  return (
    <div>
      <header class='login_nav home_nav'>
        <div class={`greenTopbg ${blueTop ? 'homebg_blue' : ''}`}>
          <div class='container'>
            <div class='row'>
              <div class='col-12'>
                <div class='greenTop'>
                  {/* {
                                        userData ?
                                            <ul>
                                                <li><Link class="login_btn">{userData?.firstName}</Link></li>
                                                <li><span onClick={() => onlogout()} className="login_btn-logout">{"Logout"}</span></li>
                                            </ul>
                                            : */}
                  <ul className='login_btn'>
                    <li>
                      <Link
                        to={'/become-expert'}
                        class='create_account_btn underlineNone hoverWhite'
                      >
                        Create Account
                      </Link>
                    </li>
                    <li class='login_btn'>
                      <Link
                        to={'/login'}
                        class='login_btn underlineNone hoverWhite'
                      >
                        Login{' '}
                        <i class='border-round borderhoverWhite far fa-user'></i>
                      </Link>
                    </li>
                  </ul>
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class={`custom_login_nav ${
            dropdownOpen
              ? 'bg-white'
              : bgBlue
              ? 'homebg_blue'
              : bgBlackLight
              ? 'homebgBlackLight'
              : 'home_nav_bg'
          } `}
        >
          <div class='container'>
            <Drawer
              placement={'left'}
              size={'xs'}
              className='drawer'
              onClose={onClose}
              open={visible}
              key={'left'}
            >
              <div class='greenTopMobile'>
                <Link
                  to={'/become-expert'}
                  class='create_account_btn text_blue underlineNone hoverWhite'
                >
                  Create Account
                </Link>
                <Link to={'/login'} class='text_yellow d-flex ml-2 mt-0 underlineNone hoverWhite'>
                  Login{' '}
                  <i class='border-round borderhoverWhite far fa-user'></i>
                </Link>
              </div>
              <Drawer.Header className='drawer-header'>
                <div>
                  <Drawer.Title>
                    <img src={Logo} width={100} />
                  </Drawer.Title>
                  <Drawer.Actions></Drawer.Actions>
                </div>
              </Drawer.Header>
              <Drawer.Body className='drawer-body'>
                <p className='text-white'>
                  <Link className='text-white' to={'/why-choose-us'}>
                    Why choose us?
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/explore'}>
                  Industries
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/insight'}>
                    Insights
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/explore'}>
                    Careers
                  </Link>
                </p>
                <p>
                  <Link className='text-white' to={'/about-us'}>
                    About Us
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/request-a-demo'}>
                    Request a Demo
                  </Link>
                </p>
              </Drawer.Body>
            </Drawer>
            <nav class='navbar navbar-expand-lg navbar-light widthfull'>
              <Link class='navbar-brand' to={'/'}>
                <img src={dropdownOpen ? LogoBlue : Logo} alt='' />
              </Link>
              <button onClick={showDrawer} class='navbar-toggler' type='button'>
                <i style={{ color: '#fff' }} class='menu-icon fal fa-bars'></i>
              </button>
              <div
                class='collapse displayMobileIpadHide navbar-collapse align-items-center justify-content-end'
                id='navbarNav'
              >
                <ul class='navbar-nav d-flex align-items-center'>
                  <li class='nav-item'>
                    <Link
                      to={'/why-choose-us'}
                      class={`${dropdownOpen ? 'text_blue' : ''} nav-link`}
                    >
                      Why choose us?
                    </Link>
                  </li>
                  <li
                    class='nav-item'
                    // onMouseEnter={() => handleStateChange('dropdownOpen', true)}
                    // onMouseLeave={() =>
                    //   handleStateChange('dropdownOpen', false)
                    // }
                  >
                    <div class='dropdown megamenu'>
                      <Link
                        to={'/explore'}
                        class={`${
                          dropdownOpen ? 'text_blue_light' : ''
                        } nav-link`}
                      >
                        Industries
                      </Link>
                      {/* <div class='dropdown-content'>
                        <div class='container align-items-start'>
                          <div class='row'>
                            <div class='col-md-3'>
                              <div class='menu_box mb-4'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Healthcare
                                </p>
                                <a href=''>Biotechnology </a>
                                <a href=''>Health Care Equipment & Supplies </a>
                                <a href=''>Health Care Providers & Services </a>
                                <a href=''>Health Care Technology </a>
                                <a href=''>Life Sciences Tools & Services </a>
                                <a href=''>Pharmaceuticals </a>
                              </div>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Materials
                                </p>
                                <a href=''>Chemicals </a>
                                <a href=''>Construction Materials </a>
                                <a href=''>Containers & Packaging </a>
                                <a href=''>Metals & Mining </a>
                                <a href=''>Paper & Forest Products </a>
                              </div>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Consumer Discretionary
                                </p>
                                <a href=''>Automobile Components </a>
                                <a href=''>Automobiles </a>
                                <a href=''>Distributors </a>
                                <a href=''>Diversified Consumer Services </a>
                                <a href=''>Hotels, Restaurants & Leisure </a>
                                <a href=''>Household Durables </a>
                                <a href=''>Leisure Products </a>
                                <a href=''>Multiline Retail</a>
                                <a href=''>Specialty Retail</a>
                                <a href=''>Textile, Apparel & Luxury Goods</a>
                                <a href=''>Internet & Direct Marketing</a>
                              </div>
                            </div>

                            <div class='col-md-3'>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Industrial
                                </p>
                                <a href=''>Aerospace & Defense </a>
                                <a href=''>Air Freight & Logistics </a>
                                <a href=''>Airlines </a>
                                <a href=''>Building Products </a>
                                <a href=''>Commercial Services & Supplies </a>
                                <a href=''>Construction & Engineering </a>
                                <a href=''>Electrical Equipment </a>
                                <a href=''>Industrial Conglomerates </a>
                                <a href=''>Machinery </a>
                                <a href=''>Marine </a>
                                <a href=''>Professional Services </a>
                                <a href=''>Road & Rail </a>
                                <a href=''>Trading Companies & Distributors </a>
                                <a href=''>Transportation Infrastructure </a>
                              </div>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Consumer Services
                                </p>
                                <a href=''>
                                  Diversified Telecommunication Services
                                </a>
                                <a href=''>
                                  Wireless Telecommunication Services
                                </a>
                                <a href=''>Entertainment</a>
                                <a href=''>Media</a>
                                <a href=''>Interactive Media and Services</a>
                              </div>
                            </div>

                            <div class='col-md-3'>
                              <div class='menu_box mb-4'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Information Technology
                                </p>
                                <a href=''>Communications Equipment </a>
                                <a href=''>
                                  Electronic Equipment, Instruments & Components{' '}
                                </a>
                                <a href=''>IT Services </a>
                                <a href=''>Semiconductors & Semiconductor </a>
                                <a href=''>Equipment Software </a>
                                <a href=''>
                                  Technology Hardware, Storage & Peripherals{' '}
                                </a>
                              </div>

                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Real Estate{' '}
                                </p>
                                <a href=''>
                                  Equity Real Estate Investment Trusts{' '}
                                </a>
                                <a href=''>
                                  Real Estate Management & Development{' '}
                                </a>
                              </div>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Consumer Staples
                                </p>
                                <a href=''>Beverages</a>
                                <a href=''>Food & Staples Retailing</a>
                                <a href=''>Food Products</a>
                                <a href=''>Household Products</a>
                                <a href=''>Personal Products</a>
                                <a href=''>Tobacco</a>
                              </div>
                            </div>

                            <div class='col-md-3'>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Utilities
                                </p>
                                <a href=''>Electric Utilities </a>
                                <a href=''>Gas Utilities </a>
                                <a href=''>
                                  Independent Power and Renewable Electricity
                                  Producers{' '}
                                </a>
                                <a href=''>Multi-Utilities </a>
                                <a href=''>Water Utilities </a>
                              </div>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Energy
                                </p>
                                <a href=''>Energy Equipment & Services</a>
                                <a href=''>Oil, Gas & Consumable Fuels</a>
                              </div>
                              <div class='menu_box'>
                                <p class='menu_title font-16 font-weight-bold'>
                                  Finacial Services
                                </p>
                                <a href=''>Banking</a>
                                <a href=''>Capital Markets</a>
                                <a href=''>Consumer Finance</a>
                                <a href=''>Diversified Financial Services</a>
                                <a href=''>Insurance</a>
                                <a href=''>
                                  Mortgage Real Estate Investment Trusts (REITs)
                                </a>
                                <a href=''>Thrifts & Mortgage Finance</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </li>
                  <li class='nav-item underlineHidden'>
                    <Link
                      to={'/insight'}
                      class={`${dropdownOpen ? 'text_blue' : ''} nav-link`}
                    >
                      Insights
                    </Link>
                  </li>

                  <li class='nav-item'>
                    <Link
                      to={'/career'}
                      class={`${dropdownOpen ? 'text_blue' : ''} nav-link`}
                    >
                      Careers
                    </Link>
                  </li>
                  <li class='nav-item'>
                    <Link
                      to={'/about-us'}
                      class={`${dropdownOpen ? 'text_blue' : ''} nav-link`}
                    >
                      About Us
                    </Link>
                  </li>
                  <li class='nav-item'>
                    <Link
                      to={'/request-a-demo'}
                      class={`${dropdownOpen ? 'text_blue' : ''} nav-link`}
                    >
                      Request a Demo
                    </Link>
                  </li>

                  {/* 
                                </ul>

                                <ul> */}
                  <li class='ml-3'>
                    <i
                      style={{ color: dropdownOpen ? '#000' : '#fff' }}
                      class='menu-icon fal fa-bars'
                    ></i>
                  </li>
                </ul>
              </div>
              <div class='clearfix displayMobileIpadHide'></div>
            </nav>
          </div>
        </div>
      </header>
      {/* <!--header end here--> */}
    </div>
  )
}
