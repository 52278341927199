import React, { useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import Admocs from '../../assets/img/Admocs.png'
import IBM from '../../assets/img/IBM.png'
import Astra from '../../assets/img/Astra.png'
import BAR from '../../assets/img/BAR.png'
import showmore from '../../assets/svg/showmore.svg'
import GoogleMapReact from 'google-map-react'
import './index.css'

const MAP_API_KEY = 'AIzaSyC-1dUdU_nJ8N4Zh3ijPzLF7MANu6sIkKQ'
const AnyReactComponent = ({ text }) => <div>{text}</div>

export default function ExecutiveDashboard () {
  const history = useHistory()
  const [state, setState] = useState({
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  })
  const list = [
    {
      image: Admocs,
      title: 'Project name 1',
      warning: true,
      alarm: true,
      messageRead: true
    },
    {
      image: Admocs,
      title: 'Project name 2',
      warning: true,
      alarm: true,
      attached: true,
      messageRead: true
    },
    { image: Admocs, title: 'New Project', create: true }
  ]
  let userData = localStorage.getItem('userData')
  userData = JSON.parse(userData)

  const gotoUser = () => {
    history.push('/executive/user')
  }
  return (
    <div>
      <MainHeader />

      <section class='sec-4'>
        <div class='container'>
          <div class='col-md-6 mb-5'>
            <div class='content'>
              <div className='d-flex justify-content-between align-items-start'>
                <h4 class='heading_font_black'>
                  Welcome back {userData?.firstName + ' ' + userData?.lastName}.
                </h4>
              </div>
            </div>
          </div>
          <div class='col-md-12 row mt-4'>
            <div class='col-md-4 teamProject' onClick={gotoUser}>
              <div className='blue-head'>
                <b class='text-white'>FINANCE</b>
              </div>

              <div className='white-body border'>
                <img className='mb-4' src={BAR} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>6 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject' onClick={gotoUser}>
              <div className='blue-head'>
                <b class='text-white'>TECHNOLOGY</b>
              </div>

              <div className='white-body border'>
                <img className='mb-4' src={IBM} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>12 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject' onClick={gotoUser}>
              <div className='blue-light-head'>
                <b class='text-white'>PHARMACEUTICAL</b>
              </div>

              <div className='white-body border'>
                <img className='mb-4 minus-margin' src={Astra} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>4 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
          </div>
          <div class='col-md-12 row mt-5'>
            <div class='col-md-4 teamProject' onClick={gotoUser}>
              <div className='blue-head'>
                <b class='text-white'>FINANCE</b>
              </div>

              <div className='white-body border'>
                <img className='mb-4' src={BAR} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>6 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject' onClick={gotoUser}>
              <div className='blue-head'>
                <b class='text-white'>TECHNOLOGY</b>
              </div>

              <div className='white-body border'>
                <img className='mb-4' src={IBM} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>12 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject' onClick={gotoUser}>
              <div className='blue-light-head'>
                <b class='text-white'>PHARMACEUTICAL</b>
              </div>

              <div className='white-body border'>
                <img className='mb-4 minus-margin' src={Astra} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>4 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='sec-4'>
        <div class='container'>
          <h4 class='heading_font_black'>Teams</h4>
          <div className='overviewDiv border-top border-bottom mt-5 '>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Total Investment To-date:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Paid Invoice:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12'>
              <p className='text_blue'>{'Outstanding Invoice:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='overviewDiv border-bottom'>
            <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Current level:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'Associate'}
              </p>
            </div>
            <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
              <p className='text_blue mt-4 '>{'Teams average rating:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'97%'}</p>
            </div>
            <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Projects completed:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'17'}</p>
            </div>
            <div className='typeDiv pb-4 col-md-3 col-sm-12'>
              <p className='text_blue mt-4'>{'Client rating:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'92%'}</p>
            </div>
          </div>
          <div className='overviewDiv border-bottom'>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Current level:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'Associate'}
              </p>
            </div>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4 '>{'Teams average rating:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'97%'}</p>
            </div>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Projects completed:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'17'}</p>
            </div>
          </div>
        </div>
      </section>
      <section className='mapSec agile-section'>
        <div className='container'>
          <p class='text_blue font-30 mb-5'>Location breakdown</p>
        </div>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAP_API_KEY }}
          defaultCenter={state.center}
          defaultZoom={state.zoom}
        >
          <AnyReactComponent lat={59.955413} lng={30.337844} />
        </GoogleMapReact>
      </section>

      <section class='sec-4 agile-section'>
        <div class='container'>
          <div className='overviewDiv'>
            <div className='typeDiv pb-4'>
              <p className='text_blue mt-4'>{'North America:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'2,806'}
              </p>
            </div>
            <div className='typeDiv pb-4'>
              <p className='text_blue mt-4 '>{'South America:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'1,754'}
              </p>
            </div>
            <div className='typeDiv pb-4'>
              <p className='text_blue mt-4'>{'Europe:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'3,888'}
              </p>
            </div>
            <div className='typeDiv pb-4'>
              <p className='text_blue mt-4'>{'Africa:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'785'}</p>
            </div>
            <div className='typeDiv pb-4'>
              <p className='text_blue mt-4'>{'Asia:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'6,853'}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class='sec-4'>
        <div class='container'>
          <p class='text_blue font-30 mt-4'>Scenario Modeling</p>
          <div className='overviewDiv border-top mt-5'>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Target for the year:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'$000,000'}
              </p>
            </div>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4 '>{'Earnings this year:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'$000,000'}
              </p>
            </div>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Earnings so far:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'$000,000'}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-4 text-center'>
          <img src={showmore} className='cursor-pointer' alt='' />
        </div>
      </section>
      <MainFooter />
    </div>
  )
}
