/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { HomeFooter, MainFooter, HomeHeader } from '../../components'
import PortImage from '../../assets/img/port_img.png'
import green_energey from '../../assets/img/green_energey.png'
import scientist from '../../assets/img/scientist.png'
import ScrollIcon from '../../assets/svg/scrollIcon.svg'
import scrollIconblue from '../../assets/svg/scrollIconblue.svg'
import videobg from '../../assets/video/bgVideo.mp4'
import { Link, Element } from 'react-scroll'
import WOW from 'wowjs'
import ReactPlayer from 'react-player'

export default function Home ({}) {
  const [headingText, setHeadingText] = useState('Future of Work')
  const [subText, setSubText] = useState(
    'A better vision for the Future of Work'
  )

  useEffect(() => {
    new WOW.WOW({
      live: true
    }).init()
  }, [headingText])
  return (
    <div>
      <HomeHeader blueTop />
      {/* <!-- banner section start --> */}
      <video className='videoTag' autoPlay loop muted data-object-fit='cover'>
        <source src={videobg} type='video/mp4' />
      </video>
      <Element name='Element1' className='element'>
        <section class='top_banner'>
          {/* <ReactPlayer
            class={'videoemail'}
            url={videobg}
            // onProgress={e => {
            //   console.log(
            //     'e.playedSeconds.toFixed(0)',
            //     e.playedSeconds.toFixed(0)
            //   )
            //   if (e.playedSeconds.toFixed(0) == 117) {
            //     setPlayed(true)
            //     setplay(false)
            //   }
            // }}
            // onDuration={e => console.log('onDuration', e)}
            playing={false}
            loop={true}
          /> */}

          <div class='container height100 d-flex align-items-center'>
            <div class='row'>
              <div class='col-md-12'>
                <div class='banner_content'>
                  {/* <div className='d-flex text-white align-items-center'>
                <div className='hGreenLine' /> ESG
              </div> */}
                  {headingText === `Future of Work` && (
                    <h1
                      className='wow fadeInLeft font-80-home'
                      data-wow-duration='2s'
                    >
                      <span className=''>
                        Future <br />
                        of Work
                      </span>
                    </h1>
                  )}
                  {headingText === `Agile Operating Model` && (
                    <h1
                      className='wow fadeInLeft font-80-home'
                      data-wow-duration='2s'
                    >
                      <span>
                        Agile <br />
                        Operating Model
                      </span>
                    </h1>
                  )}
                  {headingText === `Strategy Execution` && (
                    <h1
                      className='wow fadeInLeft font-80-home'
                      data-wow-duration='2s'
                    >
                      <span>
                        Strategy <br />
                        Execution
                      </span>
                    </h1>
                  )}
                  {headingText === `Environmental, Social and Governance` && (
                    <h1
                      className='wow fadeInLeft font-80-home'
                      data-wow-duration='2s'
                    >
                      <span>
                        Environmental
                        <br />
                        Social and Governance
                      </span>
                    </h1>
                  )}
                  {headingText === `Change Equilibrium` && (
                    <h1
                      className='wow fadeInLeft font-80-home'
                      data-wow-duration='2s'
                    >
                      <span>
                        Change <br />
                        Equilibrium
                      </span>
                    </h1>
                  )}
                  <p
                    className={'text-white wow fadeInLeft homeSubText'}
                    data-wow-duration='2s'
                  >
                    {subText}
                  </p>
                  {/* <p className={'text-white homeSubText'}>
                {
                  'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been'
                }
              </p> */}
                  <div className='readmoreBox'>
                    <a href='' class='underlineNone'>
                      READ MORE<i class='ml-4 fas fa-arrow-right'></i>
                    </a>
                  </div>
                  {/* <div>
                                <Link to="Element2" spy={true} smooth={true}>
                                    <i class="text-white slide-arrow fas fa-arrow-down"></i>
                                </Link>
                            </div> */}
                </div>
              </div>
            </div>
          </div>

          <div class='banner_btm_link'>
            <div class='container'>
              <div class='banner_btm_link_div'>
                <div
                  onClick={() => {
                    setHeadingText(`Future of Work`)
                    setHeadingText(`Future of Work`)
                    setSubText('A better vision for the Future of Work')
                  }}
                >
                  <a
                    href='#'
                    class={`${headingText === `Future of Work` &&
                      'active'} hoverEffect`}
                  >
                    <div></div>Future of Work
                  </a>
                </div>
                <div
                  onClick={() => {
                    setHeadingText('Agile Operating Model')
                    setSubText(
                      'A passion for agility must be sparked ‘across and through’ the organization'
                    )
                  }}
                >
                  <a
                    href='#'
                    class={`${headingText === 'Agile Operating Model' &&
                      'active'} hoverEffect`}
                  >
                    <div></div>
                    Agile Operating Model
                  </a>
                </div>
                <div
                  onClick={() => {
                    setHeadingText('Strategy Execution')
                    setSubText(
                      'How do you ensure effective Strategy Execution?'
                    )
                  }}
                >
                  <a
                    href='#'
                    class={`${headingText === 'Strategy Execution' &&
                      'active'} hoverEffect`}
                  >
                    <div></div>
                    Strategy Execution
                  </a>
                </div>
                <div
                  onClick={() => {
                    setHeadingText('Environmental, Social and Governance')
                    setSubText(
                      'Making ESG part of your business DNA is a win-win'
                    )
                  }}
                >
                  <a
                    href='#'
                    class={`${headingText ===
                      'Environmental, Social and Governance' &&
                      'active'} hoverEffect`}
                  >
                    <div></div>
                    ESG
                  </a>
                </div>
                <div
                  onClick={() => {
                    setHeadingText('Change Equilibrium')
                    setSubText(
                      'Achieving the Change Equilibrium Successful Organizations Thrive On'
                    )
                  }}
                >
                  <a
                    href='#'
                    class={`${headingText === 'Change Equilibrium' &&
                      'active'} hoverEffect`}
                  >
                    <div></div>
                    Change Equilibrium
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Link className={'scrollIcon'} to='Element2' spy={true} smooth={true}>
            <div className={'scrollIcon'}>
              <img src={ScrollIcon} alt='' />
            </div>
          </Link>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      {/* <!-- second section start --> */}
      <img src={scientist} className='scientist' />
      <Element name='Element2'>
        <section class='second_section'>
          <div class='container height100 d-flex align-items-center'>
            <div class='row'>
              <div class='col-md-6 col-lg-9'>
                <div class='content'>
                  <h1 class='heading_font'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut
                    eium experro videlentiae. Sum fuga.
                  </p>
                  <a href='' class='btn_link hoverWhite'>
                    Find out more <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                  {/* <div>
                                        <Link to="Element3" spy={true} smooth={true}>
                                            <i class="text-white slide-arrow fas fa-arrow-down"></i>
                                        </Link>
                                    </div> */}
                </div>
              </div>
              <div class='col-md-6 col-lg-3'></div>
            </div>
          </div>
        </section>
        {/* <!-- second section end --> */}

        {/* <!-- offer section start --> */}
        <section class='offer_section sec_pd'>
          <div class='container mobileWidth90'>
            <div class='row text-center justify-content-center'>
              <h1 class='heading_font'>Headline (H2)</h1>
              <p>
                Disqui venis et et quiae libus ullam esto ipsae plabore reici
                del is eario volorerro imus voluptatus, conserf erovite
                ratibeatur aut asitium et eossita taspis aut eium experro
                videlentiae. Lore disqui venis et et quiae libus ullam esto
                ipsae plabore reici del is eario.
              </p>
              <a href='' class='btn_link text_green'>
                See what we can offer <i class='ml-1 fas fa-arrow-right'></i>{' '}
              </a>
            </div>
          </div>
          {/* <Link className={'scrollIcon'} to="Element3" spy={true} smooth={true}>
                        <img src={ScrollIcon} alt='' />
                    </Link> */}
          {/* <Link className={'scrollIcon'} to="Element3" spy={true} smooth={true}>
                        <img src={scrollIconblue} alt='' />
                    </Link> */}
        </section>
      </Element>

      {/* <!-- offer section end --> */}

      {/* <!--section 4 start--> */}
      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class='row row-mobile-reverse'>
              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-custom'>
                <div class='content content-right'>
                  <h1 class='heading_font heading_font_black'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                  {/* <div>
                                        <Link to="Element4" spy={true} smooth={true}>
                                            <i class="text-black slide-arrow fas fa-arrow-down"></i>
                                        </Link>
                                    </div> */}
                </div>
              </div>

              <div class='col-md-6 d-flex ph-0 phm-0 justify-content-start'>
                <div class='image_box'>
                  <img src={PortImage} alt='' />
                </div>
              </div>
            </div>

            <div class='row mt-5'>
              <div class='col-md-6 ph-0 phm-0 d-flex justify-content-end'>
                <div class='image_box'>
                  <img src={green_energey} alt='' />
                </div>
              </div>

              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-sm-start'>
                <div class='content content-left'>
                  <h1 class='heading_font heading_font_black'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <Link className={'scrollIcon'} to="Element4" spy={true} smooth={true}>
                        <img src={scrollIconblue} alt='' />
                    </Link> */}
        </section>
      </Element>

      {/* <!--section 4 end--> */}

      {/* <!--service section start--> */}
      <Element name={'Element4'}>
        <section class='services_sec'>
          <div class=' align-items-end'>
            <h1 class='service_sec_heading firstSection4SecHead heading_font displayMobileHide'>
              Headline (H2)
            </h1>
            <div class='sec4'>
              <div class='firstSection4Sec contentDiv'>
                <div class='content contentDivMobile border-right-none'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Subheading (H3)</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>

              <div class='middle4Sec contentDiv'>
                <div class='content pl-2 d-flex align-items-end border-right-none'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Subheading (H3)</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>

              <div class='middle4Sec contentDiv'>
                <div class='content pl-2 d-flex border-right-none align-items-end'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Subheading (H3)</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>

              <div class='lastSection4Sec contentDiv'>
                <div class='content d-flex align-items-end pl-2 lastSection4SecContent border-none'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Subheading (H3)</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <section class='recent_post_sec'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6 showMobile'>
              <div class='gray_box rowMobile j-end border-right'>
                <h3 className='font-mobile-small'>Recent post</h3>
                <i class='ml-1 font-mobile-small mobile-mr-4 fas fa-arrow-left'></i>
              </div>
            </div>

            <div class='col-md-6 showMobile d-flex justify-content-end'>
              <div class='gray_box rowMobile1 mh-0 text-right align-items-center arrow-center-sm'>
                <h3 className='font-mobile-small'>Next Page</h3>
                <i class='ml-1 font-mobile-small mobile-mr-4 fas fa-arrow-right'></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--service section end--> */}

      {/* <!--recent post section start--> */}
      {/* <section class="recent_post_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="gray_box border-right">
                                <h3>Most recent post (H3)</h3>
                                <i class="ml-1 fas fa-arrow-left"></i>
                            </div>
                        </div>

                        <div class="col-md-6 d-flex justify-content-end">
                            <div class="gray_box text-right arrow-center-sm">
                                <h3>Next Page (H3)</h3>
                                <i class="ml-1 fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <!--recent post section end--> */}

      <HomeFooter showCOntactUsMobile />
      <MainFooter />
    </div>
  )
}
