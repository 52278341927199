import React from 'react'
import { HomeFooter, MainFooter, HomeHeader, Card } from '../../components'
import Scroll, { Link, Element } from 'react-scroll'
import Humility from '../../assets/svg/Humility.svg'
import curiosity from '../../assets/svg/curiosity.svg'
import excellence from '../../assets/svg/excellence.svg'
import diversity from '../../assets/svg/diversity.svg'
import governence from '../../assets/svg/governence.svg'
import social from '../../assets/svg/social.svg'
import Vline from '../../assets/svg/Vline.svg'
import environment from '../../assets/svg/environment.svg'
import monitor from '../../assets/svg/monitor.svg'
import positioning from '../../assets/img/positioning.png'
import respect from '../../assets/svg/respect.svg'
import charthob from '../../assets/svg/charthob.svg'
import microsoft from '../../assets/svg/microsoft.svg'
import stripe from '../../assets/svg/stripe.svg'
import downicon from '../../assets/svg/Group 1110.svg'
import comingsoon from '../../assets/svg/comingsoon.svg'
import inexus from '../../assets/svg/inexus.svg'
import gartner from '../../assets/svg/gartner.svg'
import prosci from '../../assets/svg/prosci.svg'
import affairs from '../../assets/svg/affairs.svg'
import ModelMobile from '../../assets/svg/ModelMobile.svg'
import technology from '../../assets/svg/technology.svg'
import risk from '../../assets/svg/risk.svg'
import marketing from '../../assets/svg/marketing.svg'
import operational from '../../assets/svg/operational.svg'
import finance from '../../assets/svg/finance.svg'
import coprate from '../../assets/svg/coprate.svg'
import product from '../../assets/svg/product.svg'
import human from '../../assets/svg/human.svg'
import './index.css'
import { Grid } from '@mui/material'

export default function AboutUs ({}) {
  var scroll = Scroll.animateScroll

  const listBox = [
    { title: 'Technology', image: technology },
    { title: 'Risk & Compliance', image: risk },
    { title: 'Marketing & Sales', image: marketing },
    { title: 'Operational Innovation', image: operational },
    { title: 'Finance & Accounting', image: finance },
    { title: 'Corporate Development & Strategy', image: coprate },
    { title: 'Product Design & Experience', image: product },
    { title: 'Human Capital', image: human }
  ]
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      {/* <!-- banner section start --> */}
      <Element name='Element1' className='element'>
        <section class='whoweare'>
          <div class='container height100 d-flex align-items-center mt-5'>
            <div class='banner_content'>
              <h1 className={'font-80-home'}>Who are we?</h1>
              <p className={'text-white homeSubText mt-3'}>
                A company with purpose and accountability
              </p>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      {/* <!-- second section start --> */}
      <Element name='Element2'>
        <section class=''>
          <div class='container height100 d-flex align-items-center'>
            <div className='col-md-8'>
              <h2 className={'text_blue font-open-bold font-40 mt-4 mb-3'}>
                Egtos operating model
              </h2>
              <p className={'text_blue  mb-4'}>
                Disqui venis et et quiae libus ullam esto ipsae plabore reici
                del is eario volorerro imus voluptatus, conserf erovite
                ratibeatur aut asitium et eossita taspis aut eium experro
                videlentiae. Lore disqui venis et et quiae libus ullam esto
                ipsae plabore reici del is eario.
              </p>
              <a href='' class='text_green btn_link'>
                See what we can offer <i class='ml-1 fas fa-arrow-right'></i>{' '}
              </a>
            </div>
          </div>
          <div class='container'>
            <div className='mt-5 col-md-12'>
              <button className='btn btn_blue_outline'>
                <img src={monitor} alt='' /> Vision & culture
              </button>
              <div className='modelCenter displayMobileHide'>
                <div className='modelVLine' />
                <div className='width90'>
                  <div className='modelHLine' />
                  <div className='rowbetween'>
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                    <div className='modelVLine' />
                  </div>
                </div>
                <div className='rowbetween'>
                  {listBox.map((item, index) => (
                    <div className='boxGrey' key={index}>
                      <img
                        src={item.image}
                        style={{ width: 50, height: 40, marginBottom: 10 }}
                      />
                      <p style={{ width: '80%' }}>{item.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className='modelCenter hideWeb'>
                <div className='width90'>
                  <img src={Vline} className='modelVLineMobile' />
                  {/* <div className='modelVLineMobile' />
                  <div className='rowAbsolute'>
                    <div className='rowbetweenColumn'>
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                      <div className='modelHLineMobile' />
                    </div>
                  </div> */}
                </div>
                <Grid container className='rowbetween'>
                  {listBox.map((item, index) => (
                    <Grid xs={5} md={12} item className='boxGrey' key={index}>
                      <img
                        src={item.image}
                        style={{ width: 50, height: 40, marginBottom: 10 }}
                      />
                      <p>{item.title}</p>
                    </Grid>
                  ))}
                </Grid>
              </div>
              {/* <img src={Model} className='displayMobileHide' alt='' /> */}
              {/* <img
                src={ModelMobile}
                width={'100%'}
                className='hideWeb'
                alt=''
              /> */}
            </div>
          </div>
        </section>
        {/* <!-- second section end --> */}

        {/* <!-- offer section start --> */}
        <section class='offer_section sec_pd '>
          <div class='container'>
            <div className='col-md-6'>
              <p className={'text_blue_light font-40 font-open-bold mt-4'}>
                Our commitment to ESG
              </p>
              <p className={'text_blue  mb-5'}>
                Conserf erovite ratibeatur aut asitium et eossita taspis aut
                eium experro videlentiae. Sum fuga.
              </p>
            </div>
            <div class='row'>
              <div class='col-md-4'>
                <img src={environment} alt='' />
                <h4 className={'text_blue_light mt-2'}>Environment</h4>
                <p className={'text_blue mt-2'}>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines.
                </p>
                <img src={downicon} className={'mt-2 mb-4'} />
              </div>
              <div class='col-md-4'>
                <img src={social} alt='' />
                <h4 className={'text_blue_light mt-2'}>Social</h4>
                <p className={'text_blue mt-2'}>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines.
                </p>
                <img src={downicon} className={'mt-2 mb-4'} />
              </div>
              <div class='col-md-4'>
                <img src={governence} alt='' />
                <h4 className={'text_blue_light mt-2'}>Governance</h4>
                <p className={'text_blue mt-2'}>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines.
                </p>
                <img src={downicon} className={'mt-2 mb-4'} />
              </div>
            </div>
          </div>
        </section>
      </Element>
      <section class='sec-4'>
        <div class='container'>
          <div class='row mt-5'>
            <div class='col-md-6 d-flex justify-content-end'>
              <div class='image_box'>
                <img src={positioning} alt='' />
              </div>
            </div>

            <div class='col-md-6 d-flex align-items-center justify-content-sm-center'>
              <div class='content content-left'>
                <h1 class='heading_font heading_font_black font-open-bold'>
                  Our positioning
                </h1>
                <p>
                  {' '}
                  Next generation consulting. Uniquely high-performance
                  consulting from global experts in balanced teams. Egtos
                  delivers world beating, high-performance consulting tailored
                  to client needs for a fraction of the cost through empowered
                  expert blanked teams. We build the right engagement teams to
                  help clients deliver the value only we can.
                </p>
                <a href='' class='btn_link link_black'>
                  See what we can offer <i class='ml-1 fas fa-arrow-right'></i>{' '}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <Link className={'scrollIcon'} to="Element4" spy={true} smooth={true}>
                        <img src={scrollIconblue} alt='' />
                    </Link> */}
      </section>

      {/* <!-- offer section end --> */}
      <Element>
        <section class='bg-grey'>
          <div class='container justify-content-center'>
            <h2 className='text_blue mb-5 text-center font-open-bold'>Our values</h2>
            <div class='row justify-content-between justify-content-sm-around'>
              <div class=' text-center mb-5'>
                <img src={respect} className='widthMobile' />
                <p className={'text_blue font-18 font-weight-bold mt-2'}>
                  Respect
                </p>
              </div>
              <div class=' text-center mb-5'>
                <img src={diversity} className='widthMobile' />
                <p className={'text_blue font-18 font-weight-bold mt-2'}>
                  Diversity
                </p>
              </div>
              <div class=' text-center mb-5'>
                <img src={excellence} className='widthMobile' />
                <p className={'text_blue font-18 font-weight-bold mt-2'}>
                  Excellence
                </p>
              </div>
              <div class=' text-center mb-5'>
                <img src={curiosity} className='widthMobile' />
                <p className={'text_blue font-18 font-weight-bold mt-2'}>
                  Curiosity
                </p>
              </div>
              <div class=' text-center mb-5'>
                <img src={Humility} className='widthMobile' />
                <p className={'text_blue font-18 font-weight-bold mt-2'}>
                  Humility
                </p>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <Element>
        <section class='agile-section'>
          <div class='container'>
            <h2 className='text_blue mb-5 font-open-bold font-40 text-center'>
              Our strategic partners
            </h2>
            <div class='row justify-content-between'>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={charthob} width={'80%'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={stripe} width={'80%'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={microsoft} width={'80%'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={affairs} width={'80%'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={gartner} width={'80%'} />
              </div>
              <div class='col-md-2 col-xs-4 mb-5'>
                <img src={prosci} width={'80%'} />
              </div>
            </div>
            <div class='row pl-5 pr-5 justify-content-around nexusIcon'>
              <div class='col-md-3 col-xs-4 mb-5'>
                <img src={inexus} className={'web80Mobile100'} />
              </div>
              <div class='col-md-3 col-xs-4 mb-5'>
                <img src={comingsoon} className={'web80Mobile100'} />
              </div>
              <div class='col-md-3 col-xs-4 mb-5'>
                <img src={comingsoon} className={'web80Mobile100'} />
              </div>
              <div class='col-md-3 col-xs-4 mb-5'>
                <img src={comingsoon} className={'web80Mobile100'} />
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* <!--section 4 end--> */}

      <div className='hideWeb'>
        <HomeFooter displayMobileHide />
      </div>
      <MainFooter />
    </div>
  )
}
