import React from 'react'
import { useRecordWebcam, CAMERA_STATUS } from 'react-record-webcam'
const OPTIONS = {
  filename: 'test-filename',
  fileType: 'mp4',
  width: '100% !important',
  height: 450
}

export default function Process5 ({ onClickButton }) {
  const recordWebcam = useRecordWebcam(OPTIONS)

  const offCamera =
    recordWebcam.status === CAMERA_STATUS.OPEN ||
    recordWebcam.status === CAMERA_STATUS.RECORDING ||
    recordWebcam.status === CAMERA_STATUS.PREVIEW
  const onCamera =
    recordWebcam.status === CAMERA_STATUS.CLOSED ||
    recordWebcam.status === CAMERA_STATUS.PREVIEW

  const onstart = () => {
    if (recordWebcam.status === CAMERA_STATUS.PREVIEW) {
      recordWebcam.close()
      recordWebcam.retake()
    } else {
      recordWebcam.start()
    }
  }
  return (
    <section class='loginOuter ptm-0'>
      <div class='ph-0'>
        <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12 mh-0'>
          <div class='signupProgress hideWeb pb-5 pl-2 mb-5 pt-5'>
            <div class='signupProgress hideWeb'>
              <div class='signupProgressIn'>
                <h4>Sign-up progress</h4>
                <div class='signProgStep'>
                  <ul>
                    <li>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Experience
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Values
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        About you
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Financial details
                      </a>
                      <a href='#' class='active'>
                        <span>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Elevator pitch{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-6 col-md-8 col-sm-122'>
            <div class='login'>
              <div class='loginIn'>
                <div class='loginPara'>
                  <h3>Joining Egtos</h3>
                  <p class='mb-3'>
                    We are ready to add you to Egtos client project team, now
                    give us. your 2 minutes elevator pitch.
                  </p>
                  <p>
                    In 2 minutes or less, please tell us how you would help
                    clients accelerate strategy execution to enable growth?
                    Please give tangible examples, such as: I've led at least
                    four post-merger integrations, I enjoy transforming clinical
                    trial operations, Agile operation model is my forte, helping
                    companies build internal change management capability is
                    what clients call me for, I help transform legacy banking
                    infrastructure to 21st digital power-house, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1'>
            <div class='signupProgress'>
              <div class='signupProgress'>
                <div class='signupProgressIn'>
                  <h4>Sign-up progress</h4>
                  <div class='signProgStep'>
                    <ul>
                      <li>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Experience
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Values
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          About you
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Financial details
                        </a>
                        <a href='#' class='active'>
                          <span></span> Elevator pitch{' '}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-sm-12'}>
            <div class='joinVideoBlack'>
              <div class='joinVideoBox'>
                <div className={'buttonBox'}>
                  <button
                    className={'camerabutton'}
                    disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                    onClick={recordWebcam.download}
                  >
                    <i class='fas fa-download'></i>
                  </button>
                  <button
                    className={'camerabutton'}
                    onClick={
                      recordWebcam.status !== CAMERA_STATUS.RECORDING
                        ? onstart
                        : recordWebcam.status === CAMERA_STATUS.RECORDING
                        ? recordWebcam.stop
                        : console.log('')
                    }
                  >
                    {recordWebcam.status !== CAMERA_STATUS.RECORDING ? (
                      <i class='fas fa-plus'></i>
                    ) : (
                      <i class='fas fa-check'></i>
                    )}
                  </button>
                  <button
                    className={'closebutton'}
                    disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                    onClick={onstart}
                  >
                    <i class='fas fa-times'></i>
                  </button>
                  <button
                    className={'camerabutton'}
                    onClick={onCamera ? recordWebcam.open : recordWebcam.close}
                  >
                    {offCamera ? (
                      <i class='fas fa-video'></i>
                    ) : (
                      <i class='fas fa-video-slash'></i>
                    )}
                  </button>
                </div>

                <div className={'videoemailRecording'}>
                  <video
                    ref={recordWebcam.webcamRef}
                    style={{
                      display: `${
                        recordWebcam.status === CAMERA_STATUS.OPEN ||
                        recordWebcam.status === CAMERA_STATUS.RECORDING
                          ? 'block'
                          : 'none'
                      }`,
                      width: '91%',
                      height: '100%'
                    }}
                    autoPlay
                    // controls
                    // muted
                  />

                  <video
                    ref={recordWebcam.previewRef}
                    style={{
                      display: `${
                        recordWebcam.status === CAMERA_STATUS.PREVIEW
                          ? 'block'
                          : 'none'
                      }`,
                      width: '91%',
                      height: '100%'
                    }}
                    autoPlay
                    // muted
                    loop
                  />
                  {/* <a href=""><i class="fas fa-play"></i></a> */}
                </div>
              </div>
            </div>

            <div class='logingroup mt-5'>
              <button
                onClick={() => onClickButton('process6')}
                class='btn btn-outline-warning'
              >
                Save and move on
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
