import React from 'react'
import Logo from '../../assets/img/logob.png'

export default function InsightFooter () {
  return (
    <div>
      {/* <!--Home footer--> */}
      <footer class='insight_footer border-bottom'>
        {/* <div class="container"> */}
        <div class='row'>
          <div class='col-md-6 displayMobileHide col-xs-12 col-sm-12 container bg-yellow bg-blue'>
            <div class='stay_up'>
              <h1>Stay up to date <br /> with Egtos</h1>
              <a href='' class='text_yellow btn_link mt-4 hoverWhite'>
                Find out more <i class='ml-1 fas fa-arrow-right'></i>{' '}
              </a>
            </div>
          </div>
          <div class='col-md-6 subs_newsletter_main bg-blue'>
            {/* <div class="col-md-10"> */}
            <div class='subs_newsletter'>
              <form>
                <div class='row align-items-end'>
                  <div class='row div-center' style={{ width: '80%' }}>
                    <h4 className='text-white mb-3'>
                      <b>Subscribe </b>to our newsletter
                    </h4>
                    <div class='logingroup footerInputs grey-border'>
                      <input
                        type='text'
                        formControlName='firstName'
                        placeholder='First Name'
                      />
                    </div>
                    <div class='logingroup footerInputs grey-border'>
                      <input
                        type='text'
                        formControlName='Surname'
                        placeholder='Surname'
                      />
                    </div>
                    <div class='logingroup footerInputs grey-border'>
                      <input
                        type='text'
                        formControlName='Company'
                        placeholder='Company'
                      />
                    </div>
                    <div class='logingroup footerInputs grey-border'>
                      <input
                        type='text'
                        formControlName='Email'
                        placeholder='Email'
                      />
                    </div>
                  </div>
                  <div className='subscribeArrow'>
                    <i class='ml-1 font-mobile-small text_blue mobile-mr-4 text-right fas fa-arrow-right'></i>
                  </div>
                </div>
              </form>
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* </div> */}
      </footer>
    </div>
  )
}
