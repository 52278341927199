/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@mui/material'
import React from 'react'
import Logo from '../../assets/img/logo.png'
import './index.css'
export default function ViewProject({ }) {
  let date = new Date().getFullYear()
  return (
    <div>
      <section class='top_banner_email2'>
        <div class='container height100 '>
          <div class='row'>
            <div class='col-md-12'>
              <img src={Logo} alt='' />
            </div>
          </div>
        </div>
      </section>
      <section class=''>
        <div class='container'>
          <div class='row'>
            <div class='col-md-12'>
              <p>
               
                  Hi {'<Firstname>'},<br />
                  <br />We’re pleased to tell you that you’ve been selected for a new project on egtos. To view the project details and to accept or decline the engagement please click the link below.
              </p>
              <div className='displayCenter'>
                <button className='signButton'>View project</button>
              </div>
              <p>
                If you have any problems verifying you account, please get in
                touch with our support team.
                <br />
                <br /> support@egtos.com
                <br />
                <br /> +00 (0)00 0000 0000
              </p>
            </div>
          </div>
        </div>
      </section>
      <div>
        {/* <!--Main footer--> */}
        <footer class='common_footer'>
          <div class='container'>
            <Grid container alignItems={'center'}>
              <Grid item xs={3} md={6}>
                <div class=''>
                  <i class='fab fa-twitter-square'></i>
                  <i class='fab fa-linkedin'></i>
                </div>
              </Grid>
              <Grid item xs={9} md={6}>
                <div class='footleft'>
                  <ul>
                    <li>
                      <a href='#'>
                        <i class='far fa-copyright'></i> {date} Egtos
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        </footer>
      </div>
    </div>
  )
}
