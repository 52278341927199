import { makeRequest } from './requestBuilder'

export const API = {
  get: (url, body) =>
    makeRequest({
      method: 'get',
      url,
      body
    }),

  post: (url, body) =>
    makeRequest({
      method: 'post',
      body,
      url
    }),

  patch: (url, body) =>
    makeRequest({
      method: 'patch',
      body,
      url
    }),

  put: (url, body) =>
    makeRequest({
      method: 'put',
      body,
      url
    }),

  delete: url =>
    makeRequest({
      method: 'delete',
      url
    })
}
