/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useHistory } from 'react-router'
import { HomeHeader } from '../../components'

export default function BacomeExpert ({}) {
  const history = useHistory()
  const goToSignup = type => {
    localStorage.setItem('type', type)
    history.push('/signup')
  }
  return (
    <div>
      <HomeHeader bgBlue />
      {/* <!-- banner section start --> */}
      <section class='top_banner_expert become_an_expert'>
        <div class=' height100 become_an_expert_div'>
          <div class='col-md-6  contentHover contentBorder'>
            <div class='col-md-10 contentHoverDiv'>
              <h1>Client</h1>
              <p class='text-white mt-0'>
                Enough with the long decks; get results {<br />} with true
                experts who actually do work
              </p>
              <p onClick={() => goToSignup('client')}>
                JOIN NOW <i class='ml-1 fas fa-arrow-right'></i>
              </p>
            </div>
          </div>
          <div class='col-md-6 contentHover'>
            <div class='col-md-12 contentHoverDiv'>
              <h1>Expert</h1>
              <p class='text-white mt-0'>
                Work with global teams of experts - earn more
                {<br />} without compromising work-life-balance
              </p>
              <p onClick={() => goToSignup('expert')}>
                JOIN NOW <i class='ml-1 fas fa-arrow-right'></i>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner section end --> */}
    </div>
  )
}
