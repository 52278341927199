import React, { useState } from 'react'
import { MainFooter, SignupHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import { forgotpassword } from '../../api/auth'
import { useSnackbar } from 'notistack'
import { isPassword } from '../../utils/utilities'

export default function ResetPass() {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        "confirmPassword": "",
        "verificationCode": "",
        "newPassword": "",
        "required": false,
        "loading": false
    })
    const { loading, required, confirmPassword, verificationCode, newPassword } = state;

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }

    const disabled = !verificationCode || !newPassword || !confirmPassword || newPassword !== confirmPassword || loading

    const handleSignin = async () => {
        try {
            // history.push('/login')
            if (disabled) {
                handleChange("required", true)
                return
            }
            handleChange('required', false)
            handleChange('loading', true)
            const emailAddress = localStorage.getItem("email")
            const payload = {
                emailAddress,
                verificationCode,
                newPassword
            }
            const res = await forgotpassword(payload)
            if (res.data.statusCode = 200) {
                handleChange('loading', false)
                enqueueSnackbar(`Password Has Been Changed!`, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                localStorage.removeItem("email")
                history.push('/login')
            } else {
                handleChange('loading', false)
                enqueueSnackbar(`Error: ${res.data.result.message}`, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            }
        } catch (error) {
            handleChange('loading', false)
            // console.log('err', error);
            enqueueSnackbar(`Error: ${error.message}`, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }
    }


    return (
        <div>
            <SignupHeader />
            <section class="forgotOuter">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 col-sm-12">
                            <div class="login col-lg-8 col-md-10 col-sm-12">
                                <div class="loginIn">
                                    <div class="loginPara">
                                        <h3 className='text_blue'>Reseting password</h3>
                                        <p className='text_blue'>Please enter a new password:</p>
                                    </div>
                                </div>
                            </div>
                            <div class="logingroup col-lg-10 col-md-10 col-sm-12">
                                <input type="text" onChange={(value) => handleChange('verificationCode', value.target.value)} placeholder="Code" />
                                {
                                    required && !verificationCode &&
                                    <div>
                                        <small>Verification code is required !</small>
                                    </div>
                                }
                            </div>
                            <div class="logingroup col-lg-10 col-md-10 col-sm-12">
                                <input type="text" onChange={(value) => handleChange('newPassword', value.target.value)} placeholder="New Password" />
                                {
                                    required && !newPassword &&
                                    <div>
                                        <small>Password is required !</small>
                                    </div>
                                }
                                {
                                    newPassword && isPassword(newPassword) &&
                                    <div >
                                        <small>Must be at least 8 characters long
                                            and include upper and lowercase letters and at least one number !</small>
                                    </div>
                                }

                            </div>
                            <div class="logingroup col-lg-10 col-md-10 col-sm-12">
                                <input type="text" onChange={(value) => handleChange('confirmPassword', value.target.value)} placeholder="Confirm Password" />
                                {
                                    required && !confirmPassword &&
                                    <div>
                                        <small>Confirm password is required !</small>
                                    </div>
                                }
                                {
                                    confirmPassword && isPassword(confirmPassword) &&
                                    <div >
                                        <small>Must be at least 8 characters long
                                            and include upper and lowercase letters and at least one number !</small>
                                    </div>
                                }
                            </div>
                            <div class="logingroup col-lg-12 col-md-10 col-sm-12">
                                <p className='mt-3'>Must be at least 8 character long and include upper and lower case enters and at least one number</p>
                            </div>
                            <div class="logingroup mt-5 col-lg-10 col-md-10 col-sm-12">
                                <button
                                    onClick={() => handleSignin()}
                                    // disabled={disabled}
                                    class={`btn ${"btn-outline-warning"}`}
                                // class={`btn ${disabled ? "btn-login-outline-warning" : "btn-login-outline-warning-active"}`}
                                >Reset Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MainFooter />
        </div >
    )
}