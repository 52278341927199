import React, { useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import LogoImage from '../../assets/img/LogoImage.png'
import LogoImage1 from '../../assets/img/LogoImage1.png'
import showmore from '../../assets/svg/showmore.svg'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import { Redo } from '@mui/icons-material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import Admocs from '../../assets/img/Admocs.png'
import BAR from '../../assets/img/BAR.png'
import IBM from '../../assets/img/IBM.png'
import { useHistory } from 'react-router-dom'
import Chart from 'react-google-charts'

export default function Matrix ({}) {
  const history = useHistory()

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  var scroll = Scroll.animateScroll
  return (
    <div>
      <MainHeader />
      <section class='sec-4'>
        <div class='container'>
          <h4 class='heading_font_black'>Sector revenues</h4>
          <div className='overviewDiv border-top border-bottom mt-5 '>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Total Investment To-date:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Paid Invoice:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12'>
              <p className='text_blue'>{'Outstanding Invoice:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='overviewDiv border-bottom'>
            <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Current level:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'Associate'}
              </p>
            </div>
            <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
              <p className='text_blue mt-4 '>{'Teams average rating:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'97%'}</p>
            </div>
            <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Projects completed:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'17'}</p>
            </div>
            <div className='typeDiv pb-4 col-md-3 col-sm-12'>
              <p className='text_blue mt-4'>{'Client rating:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'92%'}</p>
            </div>
          </div>
          <div className='overviewDiv border-bottom'>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Current level:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'Associate'}
              </p>
            </div>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4 '>{'Teams average rating:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'97%'}</p>
            </div>
            <div className='typeDiv pb-4 col-md-4 col-sm-12 border-right'>
              <p className='text_blue mt-4'>{'Projects completed:'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'17'}</p>
            </div>
          </div>
        </div>
      </section>
      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class='col-md-12'>
              <div class='content'>
                <h4 class=' heading_font_black mt-4 mb-5'>Industry One</h4>
                <div class='row align-items-center'>
                  <div className='col-md-8'>
                    <h6 class=' heading_font_black mt-4 mb-2'>
                      PROGRESS & DEADLINES
                    </h6>
                    <Chart
                      width={'100%'}
                      height={'250px'}
                      chartType='BarChart'
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          'City',
                          '2010 Population',
                          { role: 'style' },
                          {
                            sourceColumn: 0,
                            role: 'annotation',
                            type: 'string',
                            calc: 'stringify'
                          }
                        ],
                        ['New York City, NY', 8175000, '#00A099', null],
                        ['Los Angeles, CA', 1792000, '#DE0009', null],
                        ['Chicago, IL', 6695000, '#0E2051', null],
                        ['Houston, TX', 0, '#b87333', null]
                      ]}
                      options={{
                        title: 'Status',
                        backgroundColor: '#fff',
                        chartArea: { width: '100%' },
                        hAxis: {
                          title: 'Total Population',
                          minValue: 0
                        },
                        axes: {
                          y: {
                            0: { side: 'right' }
                          }
                        }
                      }}
                      // For tests
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
                  <div className='col-md-3'>
                    <div class='content'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <p className='text_blue'>Project stage</p>
                        <p className='text_blue'>Final</p>
                      </div>
                      <div className='d-flex align-items-center justify-content-between'>
                        <p className='text_blue_opacity'>Stage 1</p>
                        <p className=' font-12 text_red'>00.00.2021</p>
                      </div>
                      <div className='d-flex align-items-center mt-2 justify-content-between'>
                        <p className='text_blue_opacity'>Stage 1</p>
                        <p className='text_green font-12'>00.00.2021</p>
                      </div>
                      <div className='d-flex align-items-center mt-2 justify-content-between'>
                        <p className='text_blue_opacity'>Stage 1</p>
                        <p className='text_blue_opacity font-12'>00.00.2021</p>
                      </div>
                      <div className='d-flex align-items-center mt-2 justify-content-between'>
                        <p className='text_blue_opacity'>Stage 1</p>
                        <p className='text_blue_opacity font-12'>00.00.2021</p>
                      </div>
                    </div>
                  </div>
                </div>

                <Chart
                  width={'100%'}
                  height={600}
                  chartType='LineChart'
                  loader={<div>Loading Chart</div>}
                  data={[
                    [
                      { type: 'number', label: 'x' },
                      { type: 'number', label: 'values' },
                      { id: 'i0', type: 'number', role: 'interval' },
                      { id: 'i1', type: 'number', role: 'interval' },
                      { id: 'i2', type: 'number', role: 'interval' },
                      { id: 'i2', type: 'number', role: 'interval' },
                      { id: 'i2', type: 'number', role: 'interval' },
                      { id: 'i2', type: 'number', role: 'interval' }
                    ],
                    [1, 100, 90, 110, 85, 96, 104, 120],
                    [2, 120, 95, 130, 90, 113, 124, 140],
                    [3, 130, 105, 140, 100, 117, 133, 139],
                    [4, 90, 85, 95, 85, 88, 92, 95],
                    [5, 70, 74, 63, 67, 69, 70, 72],
                    [6, 30, 39, 22, 21, 28, 34, 40],
                    [7, 80, 77, 83, 70, 77, 85, 90],
                    [8, 100, 90, 110, 85, 95, 102, 110]
                  ]}
                  options={{
                    title: 'Revenue',
                    curveType: 'function',
                    series: [{ color: '#00A099' }],
                    chartArea: { width: '100%' },
                    intervals: { style: 'sticks' },
                    legend: 'none'
                  }}
                />
                <div className='d-flex'>
                  <div className='col-md-6'>
                    <Chart
                      width={'100%'}
                      height={600}
                      chartType='ColumnChart'
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          'City',
                          '2010 Population',
                          { role: 'style' },
                          {
                            sourceColumn: 0,
                            role: 'annotation',
                            type: 'string',
                            calc: 'stringify'
                          }
                        ],
                        ['New York City, NY', 8175000, '#00A099', null],
                        ['Los Angeles, CA', 3792000, '#DE0009', null],
                        ['Chicago, IL', 2695000, '#00A099', null],
                        ['Houston, TX', 2099000, '#DE0009', null],
                        ['Philadelphia, PA', 1526000, '#00A099', null]
                      ]}
                      options={{
                        title: 'Profits',
                        chartArea: { width: '100%' },
                        hAxis: {
                          title: 'Total Population',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'City'
                        }
                      }}
                      legendToggle
                    />
                  </div>
                  <div className='col-md-6'>
                    <Chart
                      width={'100%'}
                      height={600}
                      chartType='ColumnChart'
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          'City',
                          '2010 Population',
                          { role: 'style' },
                          {
                            sourceColumn: 0,
                            role: 'annotation',
                            type: 'string',
                            calc: 'stringify'
                          }
                        ],
                        ['New York City, NY', 8175000, '#00A099', null],
                        ['Los Angeles, CA', 3792000, '#DE0009', null],
                        ['Chicago, IL', 2695000, '#00A099', null],
                        ['Houston, TX', 2099000, '#DE0009', null],
                        ['Philadelphia, PA', 1526000, '#00A099', null]
                      ]}
                      options={{
                        title: 'Profits',
                        chartArea: { width: '100%' },
                        hAxis: {
                          title: 'Total Population',
                          minValue: 0
                        },
                        vAxis: {
                          title: 'City'
                        }
                      }}
                      legendToggle
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <MainFooter />
    </div>
  )
}
