import React from 'react'
import Chart from 'react-google-charts'

export default function Overview({item}) {
  console.log(item);
  const project = item;
  return (
    <>
      <section class='sec-4'>
        <div class='container'>
          <div class='row'>
            <div className='col-md-8'>
              <Chart
                width={'100%'}
                height={'250px'}
                chartType='BarChart'
                loader={<div>Loading Chart</div>}
                data={[
                  [
                    'City',
                    '2010 Population',
                    { role: 'style' },
                    {
                      sourceColumn: 0,
                      role: 'annotation',
                      type: 'string',
                      calc: 'stringify'
                    }
                  ],
                  ['New York City, NY', 8175000, '#00A099', null],
                  ['Los Angeles, CA', 1792000, '#DE0009', null],
                  ['Chicago, IL', 6695000, '#0E2051', null],
                  ['Houston, TX', 0, '#b87333', null]
                ]}
                options={{
                  title: 'Status',
                  backgroundColor: '#fff',
                  chartArea: { width: '100%' },
                  hAxis: {
                    title: 'Total Population',
                    minValue: 0
                  },
                  axes: {
                    y: {
                      0: { side: 'right' }
                    }
                  }
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            </div>
            <div className='col-md-3'>
              <div class='content'>
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='text_blue'>Project stage</p>
                  <p className='text_blue'>Final</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='text_blue_opacity'>Stage 1</p>
                  <p className=' font-12 text_red'>00.00.2021</p>
                </div>
                <div className='d-flex align-items-center mt-2 justify-content-between'>
                  <p className='text_blue_opacity'>Stage 1</p>
                  <p className='text_green font-12'>00.00.2021</p>
                </div>
                <div className='d-flex align-items-center mt-2 justify-content-between'>
                  <p className='text_blue_opacity'>Stage 1</p>
                  <p className='text_blue_opacity font-12'>00.00.2021</p>
                </div>
                <div className='d-flex align-items-center mt-2 justify-content-between'>
                  <p className='text_blue_opacity'>Stage 1</p>
                  <p className='text_blue_opacity font-12'>00.00.2021</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="sec-4">
        <div class="container">
          <h4 class="heading_font_black">Overview</h4>
          <div className='overviewDiv border-top mt-5 '>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{"Project"}</p>
              <p className='text_blue_opacity font-24'>{project.projectType}</p>
            </div>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{"Company"}</p>
              <p className='text_blue_opacity font-24'>{project.company}</p>
            </div>
            <div className='col-md-4 col-sm-12'>
              <p className='text_blue'>{"Business Function"}</p>
              <p className='text_blue_opacity font-24'>{project.businessFunction}</p>
            </div>
          </div>
          <div className='overviewDiv border-top'>
            <div className='typeDiv col-sm-12'>
              <p className='text_blue mt-4'>{"Project brief"}</p>
              <p className='text_blue_opacity font-18'>{project.projectBrief}</p>
              <p className='text_blue_opacity font-16'><i className='fas fa-info-circle'></i> Click to view full brief</p>
            </div>
          </div>
          <div className='overviewDiv border-top mt-5 '>
            <div className='typeDiv col-md-3 col-sm-12 border-right'>
              <p className='text_blue'>{"Start Date:"}</p>
              <p className='text_blue_opacity font-24'>{project.expectStartDate}</p>
            </div>
            <div className='typeDiv col-md-3 col-sm-12 border-right'>
              <p className='text_blue'>{"End Date:"}</p>
              <p className='text_blue_opacity font-24'>{project.expectedEndDate}</p>
            </div>
            <div className='col-md-3 col-sm-12'>
              <p className='text_blue'>{"Team members:"}</p>
              <p className='text_blue_opacity font-24'>{project.teams.length}</p>
            </div>
            <div className='col-md-3 col-sm-12'>
              <p className='text_blue'>{"Balanced Expert Team Score:"}</p>
              <p className='text_blue_opacity font-24'>{"98%"}</p>
            </div>
          </div>
          <div className='overviewDiv border-top'>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{"Budget:"}</p>
              <p className='text_blue_opacity font-24'>{project.budget}</p>
            </div>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{"Business registration number:"}</p>
              <p className='text_blue_opacity font-24'>{"0000000000000"}</p>
            </div>
            <div className='col-md-4 col-sm-12'>
              <p className='text_blue'>{"Pre-screen:"}</p>
              <p className='text_blue_opacity font-24'>{project.prescreening}</p>
            </div>
          </div>
          <div className='overviewDiv border-top border-bottom'>
            <div className='typeDiv col-sm-12'>
              <p className='text_blue'>{"Additional Language Requirements:"}</p>
              <p className='text_blue_opacity font-24'>{project.primarilyLanguage1 + ' , ' + project.primarilyLanguage2 + ' & ' + project.primarilyLanguage3} </p>
            </div>
          </div>
        
        </div>
      </section>
    </>
  )
}
