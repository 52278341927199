import React, { useEffect, useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import LogoImage from '../../assets/img/LogoImage.png'
import LogoImage1 from '../../assets/img/LogoImage1.png'
import GoogleMapReact from 'google-map-react'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import { upsertprojectTeam } from '../../api/projects'
import { useSnackbar } from 'notistack'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import Admocs from '../../assets/img/Admocs.png'
import BAR from '../../assets/img/BAR.png'
import IBM from '../../assets/img/IBM.png'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useHistory } from 'react-router-dom'
import test from '../../assets/ProjectManagement.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js` // import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';



export default function ClientContract({ }) {
  const history = useHistory()

  const list = history?.location?.state?.selectedteam

  const { enqueueSnackbar } = useSnackbar()
  const project = history?.location?.state?.project
  const [expanded, setExpanded] = React.useState('panel1')
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const handleChangeAccordion = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [state, setState] = useState({
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  })
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }



  var scroll = Scroll.animateScroll
  return (
    <div>
      <MainHeader />


      <section class='sec-4 '>
        <div class='container bg-grey height800'>
          <Document
            file={test}
            onLoadError={e => {
              console.log('e', e)
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page className={'react-pdf__Page'} pageNumber={pageNumber} />
          </Document>
        </div>
        {/* 
          <div className='content p-3'>
            <h3 className='text_blue'>Invoice</h3>
            <p className='text_blue'>Thank you for using Egtos.</p>
            <p className='text_blue width50'>
              Muscien iendant, nonsequodicatu repremquam voluptatem soloresequis
              magnimi ncidelentlbus, nitat qui doloribus moluptae commost
              aliquae. Nem fugiaeoffice temqui qui doluptaqui as quis aut eaqui
              odi beaquam.
            </p>
          </div>
         */}
      </section>
      <section class='sec-4'>
        <div class='container'>
          <div class='d-flex'>
            <div class='logingroup width30 mr-3'>
              <button
                onClick={() => alert('Rejected')}
                class='btn btn-outline-warning btn_red'
              >
                Reject
              </button>
            </div>
            <div class='logingroup width30'>
              <button
                onClick={() => alert('Accepted')}
                class='btn btn-outline-warning'
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
    </div>
  )
}
