/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid } from '@mui/material'
import React from 'react'
import Logo from '../../assets/img/logo.png'
import './index.css'
export default function EmailVerification ({}) {
  let date = new Date().getFullYear()
  return (
    <div>
      <section class='top_banner_email'>
        <div class='container height100 '>
          <div class='row'>
            <div class='col-md-12'>
              <img src={Logo} alt='' />
            </div>
          </div>
        </div>
      </section>
      <section class=''>
        <div class='container'>
          <div class='row'>
            <div class='col-md-12'>
              <p>
                Hi {'<Firstname>'},<br />
                <br /> Please enter the verification code below to authenticate
                your email account.
              </p>
              <div className='dashedline' />
              <div>
                <h2 className='verification'>Verification code:</h2>
                <h1 className='numberCode'>0 0 0 0 0 0</h1>
              </div>
              <div className='dashedline' />
              <p>
                If you have any problems verifying you account, please get in
                touch with our support team.
                <br />
                <br /> support@egtos.com
                <br />
                <br /> +00 (0)00 0000 0000
              </p>
            </div>
          </div>
        </div>
      </section>
      <div>
        {/* <!--Main footer--> */}
        <footer class='common_footer'>
          <div class='container'>
            <Grid container alignItems={'center'}>
              <Grid item xs={3} md={6}>
                <div class=''>
                  <i class='fab fa-twitter-square'></i>
                  <i class='fab fa-linkedin'></i>
                </div>
              </Grid>
              <Grid item xs={9} md={6}>
                <div class='footleft'>
                  <ul>
                    <li>
                      <a href='#'>
                        <i class='far fa-copyright'></i> {date} Egtos
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        </footer>
      </div>
    </div>
  )
}
