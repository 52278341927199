import React, { useState } from 'react'

export default function Process3 ({
  onClickButton,
  countrylist,
  handleChange,
  gender,
  veteran,
  singleParent,
  specialChild,
  nonprofitCommitment,
  nonprofitCompany1,
  nonprofitCompany2,
  ethnicity,
  citizenCountry1,
  citizenCountry2,
  citizenCountry3,
  visaUSA,
  visaCanada,
  visaAUS,
  visaUK,
  visaEU,
  residenceCountry,
  livingCountry,
  knownLanguage1,
  knownLanguage2,
  knownLanguage3,
  knownLanguage4,
  knownLanguage5
}) {
  const [state, setState] = useState({
    required: false,
    addCompany: '',
    addLanguage: '',
    addCountry: ''
  })
  const { required, addCompany, addCountry, addLanguage } = state
  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const disabled =
    // !nonprofitCompany1 ||
    // !nonprofitCompany2 ||
    // !citizenCountry1 ||
    // !citizenCountry2 ||
    // !citizenCountry3 ||
    !residenceCountry || !livingCountry
  // ||
  // !knownLanguage1 ||
  // !knownLanguage2 ||
  // !knownLanguage3 ||
  // !knownLanguage4 ||
  // !knownLanguage5

  const _onClickButton = () => {
    if (disabled) {
      handleChangeLocal('required', true)
      return
    }
    handleChangeLocal('required', false)
    onClickButton('process4')
  }
  const filterCountrylist = () => {
    const list = countrylist.filter(e => e.country === residenceCountry)
    return list[0]?.cities
  }

  return (
    <section class='loginOuter ptm-0'>
      <div class='ph-0'>
        <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12 mh-0'>
          <div class='signupProgress mb-5 hideWeb pb-5 pl-2 pt-5'>
            <div class='signupProgressIn'>
              <h4>Sign-up progress</h4>
              <div class='signProgStep'>
                <ul>
                  <li>
                    <a href='#' class='active'>
                      <span class='fill'>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Experience
                    </a>
                    <a href='#' class='active'>
                      <span class='fill'>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Values
                    </a>
                    <a href='#' class='active'>
                      <span>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      About you
                    </a>
                    <a href='#'>
                      <span></span> Financial details
                    </a>
                    <a href='#'>
                      <span></span> Elevator pitch{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='col-sm-12'>
            <div class='login'>
              <div class='loginIn'>
                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='loginPara'>
                      <h3>Joining Egtos</h3>
                      <p>
                        We want to build an inclusive platform, your help here
                        is invaluable.
                      </p>
                    </div>

                    <div class='question_box'>
                      <h3 class='title'>Gender</h3>
                      <h5>I am:</h5>
                      <div class='loginGenderRadio'>
                        <div class='row'>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                name='inlineRadioOptions'
                                id='inlineRadio1'
                                value='option1'
                                checked={gender === 'Female'}
                                onChange={() =>
                                  handleChange('gender', 'Female')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='inlineRadio1'
                              >
                                Female
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                name='inlineRadioOptions'
                                id='inlineRadio2'
                                value='option2'
                                checked={gender === 'Male'}
                                onChange={() => handleChange('gender', 'Male')}
                              />
                              <label
                                class='form-check-label'
                                for='inlineRadio2'
                              >
                                Male
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class='row marginInputMobile'>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                name='inlineRadioOptions'
                                id='inlineRadio1'
                                value='option1'
                                checked={gender === 'Transder'}
                                onChange={() =>
                                  handleChange('gender', 'Transder')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='inlineRadio1'
                              >
                                Transder
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                name='inlineRadioOptions'
                                id='inlineRadio2'
                                value='option2'
                                checked={gender === 'Agender'}
                                onChange={() =>
                                  handleChange('gender', 'Agender')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='inlineRadio2'
                              >
                                Agender
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- <div class="loginGenderRadio">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-12">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio4" value="option1">
                                                        <label class ="form-check-label"
                                                        for="inlineRadio4">Transder</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-12">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio"
                                                            name="inlineRadioOptions" id="inlineRadio5" value="option2" />
                                                        <label class ="form-check-label" for="inlineRadio5">Prefer not to
                                                        say</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> --> */}

                      <div class='logingroup'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange('gender', value.target.value)
                          }
                          placeholder='other'
                        />
                      </div>
                    </div>
                  </div>

                  <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12'>
                    <div class='signupProgress'>
                      <div class='signupProgressIn'>
                        <h4>Sign-up progress</h4>
                        <div class='signProgStep'>
                          <ul>
                            <li>
                              <a href='#' class='active'>
                                <span class='fill'>
                                  <i class='fas fa-check'></i>
                                </span>{' '}
                                Experience
                              </a>
                              <a href='#' class='active'>
                                <span class='fill'>
                                  <i class='fas fa-check'></i>
                                </span>{' '}
                                Values
                              </a>
                              <a href='#' class='active'>
                                <span></span> About you
                              </a>
                              <a href='#'>
                                <span></span> Financial details
                              </a>
                              <a href='#'>
                                <span></span> Elevator pitch{' '}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='question_box' style={{ marginBottom: 10 }}>
                      <h3 class='title'>Background</h3>
                      <h5>Are you a veteran?</h5>
                    </div>

                    <div class='buttonn b2 mb-5' id='button-13'>
                      <input
                        checked={!veteran}
                        onChange={() => handleChange('veteran', !veteran)}
                        type='checkbox'
                        class='checkbox'
                      />
                      <div class='knobs'>
                        <span></span>
                      </div>
                    </div>
                    <div class='question_box' style={{ marginBottom: 10 }}>
                      <h5>Are you a single working parent?</h5>
                    </div>
                    <div class='buttonn b2 mb-5' id='button-13'>
                      <input
                        checked={!singleParent}
                        onChange={() =>
                          handleChange('singleParent', !singleParent)
                        }
                        type='checkbox'
                        class='checkbox'
                      />
                      <div class='knobs'>
                        <span></span>
                      </div>
                    </div>
                    <div class='question_box' style={{ marginBottom: 10 }}>
                      <h5>
                        Are you a parent with special needs child/children?
                      </h5>
                    </div>
                    <div class='buttonn b2 mb-5' id='button-13'>
                      <input
                        checked={!specialChild}
                        onChange={() =>
                          handleChange('specialChild', !specialChild)
                        }
                        type='checkbox'
                        class='checkbox'
                      />
                      <div class='knobs'>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='question_box'>
                      <h3 class='title'>Non-profit commitment</h3>
                      <h5>
                        One of egtos missions is to help non-profit
                        organizations benefit from our work and from time to
                        time donate free consulting hours. Please help us build
                        eco-system of non-profit organizations where egtos can
                        help them with our services free of charge - thank you.
                      </h5>
                      <h5>
                        Do you do community service, or are you associated with
                        any charitable organization? If yes, please list up to
                        two charitable organizations
                      </h5>
                    </div>
                    <div class='col-lg-4 col-md-4 col-sm-12 mb-4'>
                      <div class='loginSwitch d-flex align-items-center mobilemb'>
                        <div class='toggle-button-cover'>
                          <div class='button-cover'>
                            <div class='buttonn b2' id='button-13'>
                              <input
                                checked={!nonprofitCommitment}
                                onChange={() =>
                                  handleChange(
                                    'nonprofitCommitment',
                                    !nonprofitCommitment
                                  )
                                }
                                type='checkbox'
                                class='checkbox'
                              />
                              <div class='knobs'>
                                <span></span>
                              </div>
                              <div class='layer'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {nonprofitCommitment && (
                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange(
                                  'nonprofitCompany1',
                                  value.target.value
                                )
                              }
                              placeholder='Company name 1'
                            />
                            {/* {required && !nonprofitCompany1 && (
                              <div>
                                <small>Company name 1 is required !</small>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button
                              onClick={() =>
                                handleChangeLocal('addCompany', '2ndCompany')
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {addCompany === '2ndCompany' && (
                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange(
                                  'nonprofitCompany2',
                                  value.target.value
                                )
                              }
                              placeholder='Company name 2'
                            />
                            {/* {required && !nonprofitCompany2 && (
                              <div>
                                <small>Company name 2 is required !</small>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='question_box'>
                      <h3 class='title'>
                        Help us build amazing global dynamic teams
                      </h3>
                      <h5>Please complete the short table below:</h5>

                      <div class='loginGenderRadio'>
                        <div class='row'>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Cambodia'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Cambodia')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='inlineRadio1'
                              >
                                Cambodia
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Malaysia'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Malaysia')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio2'
                              >
                                Malaysia
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Native American'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Native American')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio3'
                              >
                                Native American
                              </label>
                            </div>
                          </div>
                          {/* </div>
                      </div>
                      <div class='loginGenderRadio'>
                        <div class='row marginInputMobile'> */}
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'China'}
                                onChange={() =>
                                  handleChange('ethnicity', 'China')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio4'
                              >
                                China
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Pakistan'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Pakistan')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio5'
                              >
                                Pakistan
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Black'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Black')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio6'
                              >
                                Black
                              </label>
                            </div>
                          </div>
                          {/* </div>
                      </div>

                      <div class='loginGenderRadio'>
                        <div class='row marginInputMobile'> */}
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'India'}
                                onChange={() =>
                                  handleChange('ethnicity', 'India')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio7'
                              >
                                India
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'The Philippine Islands'}
                                onChange={() =>
                                  handleChange(
                                    'ethnicity',
                                    'The Philippine Islands'
                                  )
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio8'
                              >
                                The Philippine Islands
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Hispanic or Latino'}
                                onChange={() =>
                                  handleChange(
                                    'ethnicity',
                                    'Hispanic or Latino'
                                  )
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio8'
                              >
                                Hispanic or Latino
                              </label>
                            </div>
                          </div>
                          {/* </div>
                      </div>
                      <div class='loginGenderRadio'>
                        <div class='row marginInputMobile'> */}
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Japan'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Japan')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio9'
                              >
                                Japan
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Thailand'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Thailand')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio10'
                              >
                                Thailand
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'White'}
                                onChange={() =>
                                  handleChange('ethnicity', 'White')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio11'
                              >
                                White
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='loginGenderRadio'>
                        <div class='row marginInputMobile'>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Korea'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Korea')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio12'
                              >
                                Korea
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={ethnicity === 'Vietnam'}
                                onChange={() =>
                                  handleChange('ethnicity', 'Vietnam')
                                }
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio13'
                              >
                                Vietnam
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class='logingroup'>
                        <input
                          type='text'
                          formcontrolname='text'
                          placeholder='other'
                        />
                      </div>
                    </div>

                    <div class='question_box mt-3'>
                      <h5>
                        What is your country of citizenship (please list up to
                        three if you have more than one)?
                      </h5>
                      <div class='row'>
                        <div class='companyInput'>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              onChange={value =>
                                handleChange(
                                  'citizenCountry1',
                                  value.target.value
                                )
                              }
                            >
                              <option value=''>Please Select</option>
                              {countrylist?.map((country, index) => (
                                <option key={index} value={country?.country}>
                                  {country?.country}
                                </option>
                              ))}
                            </select>
                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='displayMobileHide'>
                          <div class='loginPlus'>
                            <button
                              onClick={() =>
                                handleChangeLocal('addCountry', '2ndCountry')
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>

                      {(addCountry === '2ndCountry' ||
                        addCountry === '3rdCountry') && (
                        <div class='row'>
                          <div class='companyInput'>
                            <div class='select_wrap'>
                              <select
                                class='form-select'
                                onChange={value =>
                                  handleChange(
                                    'citizenCountry2',
                                    value.target.value
                                  )
                                }
                              >
                                <option value=''>Please Select</option>
                                {countrylist?.map((country, index) => (
                                  <option key={index} value={country?.country}>
                                    {country?.country}
                                  </option>
                                ))}
                              </select>
                              <button type='button'>
                                <i class='fas fa-arrow-down'></i>
                              </button>
                            </div>
                          </div>
                          <div class='displayMobileHide'>
                            <div class='loginPlus'>
                              <button
                                onClick={() =>
                                  handleChangeLocal('addCountry', '3rdCountry')
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan x='0' y='0'>
                                        +
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {addCountry === '3rdCountry' && (
                        <div class='row'>
                          <div class='companyInput'>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              onChange={value =>
                                handleChange(
                                  'citizenCountry3',
                                  value.target.value
                                )
                              }
                            >
                              <option value=''>Please Select</option>
                              {countrylist?.map((country, index) => (
                                <option key={index} value={country?.country}>
                                  {country?.country}
                                </option>
                              ))}
                            </select>
                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                          </div>
                          <div class='displayMobileHide'>
                            <div class='loginPlus'>
                              <button>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan x='0' y='0'>
                                        +
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div class='question_box'>
                      <h5>
                        Do you need visa to visit the following countries?
                      </h5>
                      <div class='row mb-4'>
                        <div class='col-lg-4 col-md-4 col-xs-6'>
                          <div class='loginSwitch d-flex align-items-center mobilemb'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    checked={!visaUSA}
                                    onChange={() =>
                                      handleChange('visaUSA', !visaUSA)
                                    }
                                    type='checkbox'
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>USA</p>
                          </div>
                        </div>

                        <div class='col-lg-4 col-md-4 col-xs-6'>
                          <div class='loginSwitch d-flex align-items-center mobilemb'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    checked={!visaCanada}
                                    onChange={() =>
                                      handleChange('visaCanada', !visaCanada)
                                    }
                                    type='checkbox'
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>Canada</p>
                          </div>
                        </div>

                        <div class='col-lg-4 col-md-4 col-xs-6'>
                          <div class='loginSwitch d-flex align-items-center mobilemb'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    type='checkbox'
                                    checked={!visaAUS}
                                    onChange={() =>
                                      handleChange('visaAUS', !visaAUS)
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>Australia</p>
                          </div>
                        </div>

                        {/* <div class='row mb-4 marginInputMobile'> */}
                        <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                          <div class='loginSwitch d-flex align-items-center mobilemb'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    type='checkbox'
                                    checked={!visaUK}
                                    onChange={() =>
                                      handleChange('visaUK', !visaUK)
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>UK</p>
                          </div>
                        </div>

                        <div class='col-lg-4 mtw-2 col-md-4 col-xs-6'>
                          <div class='loginSwitch d-flex align-items-center mobilemb'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    type='checkbox'
                                    checked={!visaEU}
                                    onChange={() =>
                                      handleChange('visaEU', !visaEU)
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>EU</p>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>

                    <div class='question_box'>
                      <h5>Where is your country of residence?</h5>
                      <div class='select_wrap mt-4'>
                        {/* <!-- <select class="form-select" aria-label="Default select example">
                                            <option selected>Please Select</option>
                                            <option value="1">USA</option>
                                            <option value="2">New York</option>
                                        </select> --> */}
                        <select
                          class='form-select'
                          onChange={value =>
                            handleChange('residenceCountry', value.target.value)
                          }
                        >
                          <option value=''>Please Select</option>
                          {countrylist?.map((country, index) => (
                            <option key={index} value={country?.country}>
                              {country?.country}
                            </option>
                          ))}
                        </select>
                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !residenceCountry && (
                        <div>
                          <small>Country of residence is required !</small>
                        </div>
                      )}
                    </div>

                    <div class='question_box'>
                      <h5>In which city do you currently live?</h5>
                      <div class='select_wrap mt-4'>
                        <select
                          class='form-select'
                          onChange={value =>
                            handleChange('livingCountry', value.target.value)
                          }
                        >
                          <option value=''>Please Select</option>
                          {filterCountrylist()?.map((city, index) => (
                            <option key={index} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !livingCountry && (
                        <div>
                          <small>Currently live is required !</small>
                        </div>
                      )}
                    </div>

                    <div class='question_box'>
                      <h5>
                        Please list up-to five languages you can read, write and
                        speak fluently
                      </h5>
                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange(
                                  'knownLanguage1',
                                  value.target.value
                                )
                              }
                              placeholder='Language 1'
                            />
                            {/* {required && !knownLanguage1 && (
                              <div>
                                <small>Language 1 is required !</small>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button
                              onClick={() =>
                                handleChangeLocal(
                                  'addLanguage',
                                  addLanguage === '2ndLanguage'
                                    ? '1stLanguage'
                                    : '2ndLanguage'
                                )
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan
                                      x={
                                        addLanguage === '2ndLanguage'
                                          ? '1.5'
                                          : '0'
                                      }
                                      y={
                                        addLanguage === '2ndLanguage'
                                          ? '-1'
                                          : '0'
                                      }
                                    >
                                      {addLanguage === '2ndLanguage'
                                        ? '-'
                                        : '+'}
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      {(addLanguage === '4thLanguage' ||
                        addLanguage === '2ndLanguage' ||
                        addLanguage === '3rdLanguage' ||
                        addLanguage === '5thLanguage') && (
                        <div class='row'>
                          <div class='companyInput1'>
                            <div class='logingroup'>
                              <input
                                type='text'
                                onChange={value =>
                                  handleChange(
                                    'knownLanguage2',
                                    value.target.value
                                  )
                                }
                                placeholder='Language 2'
                              />
                              {/* {required && !knownLanguage2 && (
                                <div>
                                  <small>Language 2 is required !</small>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div class=''>
                            <div class='loginPlus'>
                              <button
                                onClick={() =>
                                  handleChangeLocal(
                                    'addLanguage',
                                    addLanguage === '3rdLanguage'
                                      ? '2ndLanguage'
                                      : '3rdLanguage'
                                  )
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan
                                        x={
                                          addLanguage === '3rdLanguage'
                                            ? '1.5'
                                            : '0'
                                        }
                                        y={
                                          addLanguage === '3rdLanguage'
                                            ? '-1'
                                            : '0'
                                        }
                                      >
                                        {addLanguage === '3rdLanguage'
                                          ? '-'
                                          : '+'}
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {(addLanguage === '4thLanguage' ||
                        addLanguage === '3rdLanguage' ||
                        addLanguage === '5thLanguage') && (
                        <div class='row'>
                          <div class='companyInput1'>
                            <div class='logingroup'>
                              <input
                                type='text'
                                onChange={value =>
                                  handleChange(
                                    'knownLanguage3',
                                    value.target.value
                                  )
                                }
                                placeholder='Language 3'
                              />
                              {/* {required && !knownLanguage3 && (
                                <div>
                                  <small>Language 3 is required !</small>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div class=''>
                            <div class='loginPlus'>
                              <button
                                onClick={() =>
                                  handleChangeLocal(
                                    'addLanguage',
                                    addLanguage === '4thLanguage'
                                      ? '3rdLanguage'
                                      : '4thLanguage'
                                  )
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan
                                        x={
                                          addLanguage === '4thLanguage'
                                            ? '1.5'
                                            : '0'
                                        }
                                        y={
                                          addLanguage === '4thLanguage'
                                            ? '-1'
                                            : '0'
                                        }
                                      >
                                        {addLanguage === '4thLanguage'
                                          ? '-'
                                          : '+'}
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {(addLanguage === '4thLanguage' ||
                        addLanguage === '5thLanguage') && (
                        <div class='row'>
                          <div class='companyInput1'>
                            <div class='logingroup'>
                              <input
                                type='text'
                                onChange={value =>
                                  handleChange(
                                    'knownLanguage4',
                                    value.target.value
                                  )
                                }
                                placeholder='Language 4'
                              />
                              {/* {required && !knownLanguage4 && (
                                <div>
                                  <small>Language 4 is required !</small>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div class=''>
                            <div class='loginPlus'>
                              <button
                                onClick={() =>
                                  handleChangeLocal(
                                    'addLanguage',
                                    addLanguage === '5thLanguage'
                                      ? '4thLanguage'
                                      : '5thLanguage'
                                  )
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan
                                        x={
                                          addLanguage === '5thLanguage'
                                            ? '1.5'
                                            : '0'
                                        }
                                        y={
                                          addLanguage === '5thLanguage'
                                            ? '-1'
                                            : '0'
                                        }
                                      >
                                        {addLanguage === '5thLanguage'
                                          ? '-'
                                          : '+'}
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {addLanguage === '5thLanguage' && (
                        <div class='row'>
                          <div class='companyInput1'>
                            <div class='logingroup'>
                              <input
                                type='text'
                                onChange={value =>
                                  handleChange(
                                    'knownLanguage5',
                                    value.target.value
                                  )
                                }
                                placeholder='Language 5'
                              />
                              {/* {required && !knownLanguage5 && (
                                <div>
                                  <small>Language 5 is required !</small>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div class=''>
                            <div class='loginPlus'>
                              <button
                                onClick={() =>
                                  handleChangeLocal(
                                    'addLanguage',
                                    '4thLanguage'
                                  )
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan x='1.5' y='-1'>
                                        -
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div class='logingroup mt-5'>
                      <button
                        onClick={() => _onClickButton()}
                        class='btn btn-outline-warning'
                      >
                        Save and move on
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
