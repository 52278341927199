import React from 'react'
import { Grid } from '@mui/material'

export default function MainFooter () {
  let date = new Date().getFullYear()
  return (
    <div>
      {/* <!--Main footer--> */}
      <footer class='common_footer'>
        <div class='container'>
          <Grid container alignItems={'center'}>
            <Grid item xs={3} md={6}>
              <div class=''>
                <i class='fab fa-twitter-square'></i>
                <i class='fab fa-linkedin'></i>
              </div>
            </Grid>
            <Grid item xs={9} md={6}>
              <div class='footleft'>
                <ul>
                  <li>
                    <a href='#'>Privacy Policy</a>
                  </li>
                  <li>
                    <a href='#'>Term of Use</a>
                  </li>
                  <li>
                    <a href='#'>
                      {' '}
                      <i class='far fa-copyright'></i> {date} Egtos
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  )
}
