import React from 'react'

export default function Brief({ item }) {
  return (
    <section class='sec-4'>
      <div class='container'>
        <h1 class='heading_font heading_font_black'>{item?.projectName}</h1>
        <div class='row'>
          <div class='col-md-8'>
            <div class='content'>
              <h5 class='text_green mb-2'>Brief</h5>
              <p>
                {item?.brief}

              </p>
            </div>
          </div>

          <div class='col-md-4 mtm-5'>
            <div class='content'>
              <h5 class='text_green mb-2'>Deliverables</h5>
              <p>
              {item?.egtosTeamExpectation}
              </p>
            </div>
          </div>
        </div>
        <button class={`btn btn-primary mt-5 blue-btn`}>
          {'Update brief'}
        </button>
      </div>
    </section>
  )
}
