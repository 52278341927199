import { API } from '.'

export const upsertProject = payload => {
  return API.post('upsertproject', payload)
}

export const getProjects = () => {
  return API.get('getproject')
}

export const getProject = payload => {
  return API.get('getproject', payload)
}

export const upsertprojectapps = payload => {
  return API.post('upsertprojectapps', payload)
}



export const getSingleProject = body => {
  return API.post(`getproject`, body)
}

export const getprojectfiles = payload => {
  return API.get('getprojectfiles', payload)
}

export const getprojectnotifications = payload => {
  return API.get('getprojectnotifications', payload)
}

export const getprojectmessages = payload => {
  return API.get('getprojectmessages', payload)
}

export const getprojectapps = payload => {
  return API.get('getprojectapps', payload)
}

export const upsertexpertfeedback = payload => {
  return API.post('upsertexpertfeedback', payload)
}

export const upsertexpertrefcheck = payload => {
  return API.post('upsertexpertrefcheck', payload)
}


export const getexpertrefercheck = payload => {
  return API.post('getexpertrefercheck', payload)
}


export const upsertprojectTeam = payload => {
  return API.post('upsertprojectTeam', payload)
}

export const getexpertProjects = payload =>  {
  return API.post('getexpertprojects', payload)
}

export const getclientprojects = payload =>  {
  return API.post('getclientprojects', payload)
}
