import React from 'react'

export default function Process6 ({ onClickButton, handleChangeProcess }) {
  return (
    <section class='loginOuter ptm-0' style={{ minHeight: '100vh' }}>
      <div class='ph-0'>
        <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12 mh-0'>
          <div class='signupProgress hideWeb pb-5 mb-5 pl-2 pt-5'>
            <div class='signupProgress hideWeb'>
              <div class='signupProgressIn'>
                <h4>Sign-up progress</h4>
                <div class='signProgStep'>
                  <ul>
                    <li>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Experience
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Values
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        About you
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Financial details
                      </a>
                      <a href='#' class='active'>
                        <span class='fill'>
                          <i class='fas fa-check'></i>
                        </span>{' '}
                        Elevator pitch{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-6 col-md-8 col-sm-122'>
            <div class='login'>
              <div class='loginIn'>
                <form>
                  <div class='loginPara'>
                    <h3>Joining Egtos</h3>
                    <p>
                      Welcome the Egtos family! Now tell us when you are
                      available to start working for Egto's clients.
                    </p>
                  </div>

                  <div class='question_box'>
                    <div class='logingroup mt-5'>
                      <a
                        href='#'
                        onClick={() => onClickButton()}
                        class='btn btn-outline-warning'
                      >
                        Open calendar
                      </a>
                    </div>
                  </div>

                  <div class='question_box'>
                    <h5>
                      Oonce you've shown your availability, please complete your
                      public facing profile for prospective clients.
                    </h5>
                    <div class='logingroup mt-5'>
                      <div
                        onClick={() => handleChangeProcess('process1')}
                        class='btn btn-outline-warning'
                      >
                        Edit personal profile
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1'>
            <div class='signupProgress'>
              <div class='signupProgress'>
                <div class='signupProgressIn'>
                  <h4>Sign-up progress</h4>
                  <div class='signProgStep'>
                    <ul>
                      <li>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Experience
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Values
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          About you
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Financial details
                        </a>
                        <a href='#' class='active'>
                          <span class='fill'>
                            <i class='fas fa-check'></i>
                          </span>{' '}
                          Elevator pitch{' '}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
