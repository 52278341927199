import React, { useState } from 'react'
import { MainFooter, SignupHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import VIDEO from "../../assets/video/my.mp4"
import ReactPlayer from 'react-player'
import { useSnackbar } from 'notistack';

export default function ConfirmEmail() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const [play, setplay] = useState(false)
    const [played, setPlayed] = useState(true)

    const goToSignupProcess = () => {
        if (!played) {
            enqueueSnackbar('Please Watch Full Video!', {
                variant: "warning",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
            return
        }
        const userType = localStorage.getItem("type")
        if (userType === "expert") {
            history.push('/signup/progress')
        } else {
            history.push('/signup/client/progress')
        }
    }
    return (
        <div>
            <SignupHeader />

            <section class="loginOuter">
                <div class="container">
                    <div>
                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <div class="login">
                                <div class="signupPara">
                                    <h3>Joining Egtos</h3>
                                    <p>Thank you for confirming your email address.</p>
                                    <p className={"mt-2"}>Please watch the short video below to understand more about Egtos, our values and how we
                                        work. Once it's finished playing you will be emailed the Expert Agreement for you to read
                                        and sign.</p>
                                </div>
                            </div>
                        </div>

                        <div class="joinVideoEmail"
                        //  onClick={() => goToSignupProcess()}
                        >
                            <div class="joinVideoBoxEmail">
                                {
                                    !play &&
                                        <span onClick={() => setplay(true)}><i class="cursor-pointer fas fa-play"></i></span>
                                        // :
                                        // <span onClick={() => setplay(false)}><i class="cursor-pointer fas fa-pause"></i></span>
                                }
                                <ReactPlayer
                                    class={"videoemail"} url={VIDEO}
                                    onProgress={(e) => {
                                        if (e.playedSeconds.toFixed(0) == 117) {
                                            setPlayed(true)
                                            setplay(false)
                                        }
                                    }}
                                    onDuration={(e) => console.log('onDuration', e)}
                                    playing={play}
                                />

                                {/* <video
                                    autoPlay
                                    width={"100%"}
                                    controls
                                    src={VIDEO} /> */}
                            </div>
                        </div>
                        <div class="logingroup mt-4 d-flex justify-content-center col-lg-12 col-md-12 col-sm-12">
                            <button
                                style={{ maxWidth: 300 }}
                                // disabled={!played}
                                onClick={() => goToSignupProcess()}
                                class={`btn btn-outline-warning`}>Next</button>
                        </div>
                    </div>
                </div>
            </section>

            <MainFooter />
        </div >
    )
}