/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { MainFooter, HomeHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import { loginUser } from '../../api/auth'
import { Loader } from 'rsuite'
import './index.css'
import { useSnackbar } from 'notistack'
import { isEmail, isPassword } from '../../utils/utilities'
import ReCAPTCHA from 'react-google-recaptcha'

export default function Login () {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [state, setState] = useState({
    emailAddress: '',
    password: '',
    usertype: false,
    loading: false,
    required: false,
    invalidPassword: false
  })
  const {
    loading,
    required,
    emailAddress,
    password,
    usertype,
    invalidPassword
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const disabled = !emailAddress || !password

  const handleSignin = async () => {
    try {
      if (disabled) {
        handleChange('required', true)
        return
      }
      handleChange('required', false)
      handleChange('loading', true)
      const payload = {
        emailAddress,
        password,
        usertype: usertype ? 'client' : 'expert'
      }
      const res = await loginUser(payload)
      if (res.data.result.token) {
        handleChange('loading', false)
        enqueueSnackbar('Login Successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        localStorage.setItem('userData', JSON.stringify(res.data.result.user))
        history.push(
          res?.data?.result?.user?.usertype === 'client'
            ? '/client/dashboard'
            : '/dashboard'
        )
      } else {
        handleChange('loading', false)
        enqueueSnackbar(`Error: ${res.data.result.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }

  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      <section class='top_banner loginbg'>
        <div class='container height100 d-flex align-items-center mobileMT-0'>
          {/* <div class='banner_content'>
            <h1 className='font-60'>Login</h1>
            <p className={'text-white shippingsubText'}>
              Disqui venis et et quiae libus ullam esto ipsae plabore reici del
              is eario volorerro imus voluptatus,
            </p>
          </div> */}
          <div class='container phm-0'>
            <div class='row'>
              <div class='col-lg-8 col-md-8 col-sm-12'>
                <div class='login col-lg-12 phm-0 col-md-10 col-sm-12'>
                  <div class='loginIn'>
                    <div class='loginPara'>
                      <p
                        className='wow fadeInLeft mtm-6 delay-3s font-80-login'
                        data-wow-duration='2s'
                      >
                        <span>
                          Security is a <br className='displayMobileHide' /> shared responsibility
                        </span>
                      </p>
                      <p
                        className={
                          'text-white wow fadeInLeft delay-1s homeSubText1'
                        }
                        data-wow-duration='2s'
                      >
                        Our team does it best through our multi-secure
                        authentication process; please do your part in choosing
                        complex credentials. Thank you for collaborating
                        securely.
                      </p>
                      {/* <h3>Security<br /> is a shared responsibility</h3> */}
                    </div>
                  </div>
                </div>
                <div class='logingroup col-lg-6 col-md-10 col-sm-12'>
                  <input
                    type='text'
                    onChange={value =>
                      handleChange('emailAddress', value.target.value)
                    }
                    placeholder='Email'
                  />
                  {emailAddress && isEmail(emailAddress) && (
                    <div>
                      <small> Email must be a valid email address !</small>
                    </div>
                  )}
                  {required && !emailAddress && (
                    <div>
                      <small>Email Address is required !</small>
                    </div>
                  )}
                </div>
                <div class='logingroup col-lg-6 col-md-10 col-sm-12'>
                  <input
                    type='password'
                    style={{ marginTop: -20 }}
                    onChange={value =>
                      handleChange('password', value.target.value)
                    }
                    placeholder='Password'
                  />
                  {password && isPassword(password) && (
                    <div>
                      <small>
                        Must be at least 8 characters long and include upper and
                        lowercase letters and at least one number !
                      </small>
                    </div>
                  )}
                  {required && !password && (
                    <div>
                      <small>Password profile is required !</small>
                    </div>
                  )}
                </div>
                {/* <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="loginSwitch d-flex align-items-center mobilemb">

                                    <div class="toggle-button-cover">
                                        <div class="button-cover">
                                            <div class="buttonn b2" id="button-13">
                                                <input
                                                    checked={!usertype}
                                                    onChange={() => handleChange('usertype', !usertype)}
                                                    type="checkbox" class="checkbox" />
                                                <div class="knobs">
                                                    <span></span>
                                                </div>
                                                <div class="layer"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="ms-3 ml-2">client</p>
                                </div>
                            </div> */}
                <div class='logingroup mt-3 col-lg-7 col-md-10 col-sm-12'>
                  <button
                    onClick={() => handleSignin()}
                    // disabled={disabled}
                    class={`btn ${'btn-outline-warning'}`}
                  >
                    {loading ? <Loader /> : 'Login'}
                  </button>
                </div>
                <div class='login col-lg-8 col-md-10 col-sm-12'>
                  <p
                    className='mt-3 text-white cursor-pointer font-18'
                    onClick={() => history.push('/forgot-password')}
                  >
                    Forgot Password ?
                  </p>
                  <p className='mt-3 text-white font-16'>
                    Please{' '}
                    <span
                      className='text-white text-underline cursor-pointer'
                      onClick={() => history.push('/signup')}
                    >
                      click here
                    </span>{' '}
                    to sign up if you don’t have an existing account with Egtos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class='loginOuter'></section> */}
      {/* <ReCAPTCHA
        sitekey='6LcRSnAdAAAAAPKl17E7-hhDCWn3QjXErHgu6wwi'
        // onChange={onChange}
        size='invisible'
      /> */}
      <MainFooter />
    </div>
  )
}
