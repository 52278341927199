import React, { useState } from 'react'

export default function Process2 ({
  onClickButton,
  handleChange,
  respect,
  diversity,
  excellence,
  learning,
  humility,
  listening,
  communication,
  management,
  analysis,
  thinking,
  planning,
  collaborating,
  collaborating2,
  inspiration,
  washingDishes,
  stuckTraffic,
  publicSpeaking,
  peopleLate,
  talktivePeople,
  conflictCompany1,
  conflictCompany2,
  conflictCompany3,
  book,
  color,
  hobby,
  animal,
  sport,
  fruit,
  actor
}) {
  const [state, setState] = useState({
    required: false,
    addCompany: ''
  })
  const { required, addCompany } = state
  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const disabled =
    // !conflictCompany1 ||
    // !conflictCompany2 ||
    // !conflictCompany3 ||
    !book || !color || !hobby || !animal || !sport || !fruit || !actor

  const _onClickButton = () => {
    if (disabled) {
      handleChangeLocal('required', true)
      return
    }
    handleChangeLocal('required', false)
    onClickButton('process3')
  }

  const selectList = [
    {
      title:
        'I have a competent grasp of this value and have demonstrated it in all my projects',
      value: '1'
    },
    {
      title: "I regularly demonstrate this value. It's important to me.",
      value: '2'
    },
    {
      title:
        ' This value is very strong for me, I am passionate about this value and demonstrate it continually.',
      value: '3'
    }
  ]

  return (
    <section class='loginOuter ptm-0'>
      <div class='ph-0'>
        <div class='col-lg-3  offset-lg-3 col-md-3 offset-md-1 col-sm-12 mh-0'>
          <div class='signupProgress mb-5 hideWeb pb-5 pl-2 pt-5'>
            <div class='signupProgressIn'>
              <h4>Sign-up progress</h4>
              <div class='signProgStep'>
                <ul>
                  <li>
                    <a href='#' class='active'>
                      <span class='fill'>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Experience
                    </a>
                    <a href='#' class='active'>
                      <span>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Values
                    </a>
                    <a href='#'>
                      <span></span> About you
                    </a>
                    <a href='#'>
                      <span></span> Financial details
                    </a>
                    <a href='#'>
                      <span></span> Elevator pitch{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='col-sm-12'>
            <div class='login'>
              <div class='loginIn'>
                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='loginPara'>
                      <h3>Joining Egtos</h3>
                      <p class='mb-3'>
                        Thank you for confirming your email address.
                      </p>
                      <p>
                        It's now time to find out more about you. Muscien
                        iendant, nonsequ odicatu repremquam voluptatem
                        soloresequis magnimi ncidelent lbus, nitat qui doloribus
                        moluptae commost aliquae. Nem fugiae office temqui qui
                        doluptaqui as quis aut eaqui odi beaquam.
                      </p>
                    </div>

                    <div class='question_box mt-5'>
                      <h3 class='title'>Conflict of interest</h3>
                      <h5>
                        We want to ensure that we don't add your profile to an
                        Egtos team supporting your current employer (if you're
                        doing independent consulting on the side.) Please list
                        any companies that you wouldn't want Egtos to share your
                        profile with:
                      </h5>

                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={conflictCompany1}
                              onChange={value =>
                                handleChange(
                                  'conflictCompany1',
                                  value.target.value
                                )
                              }
                              placeholder='Company name 1'
                            />

                            {/* {required && !conflictCompany1 && (
                              <div>
                                <small>Company name 1 is required !</small>
                              </div>
                            )} */}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button
                              onClick={() =>
                                handleChangeLocal('addCompany', '2ndCompany')
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>

                      {(addCompany === '2ndCompany' ||
                        addCompany === '3rdCompany') && (
                        <div class='row'>
                          <div class='companyInput1'>
                            <div class='logingroup'>
                              <input
                                type='text'
                                value={conflictCompany2}
                                onChange={value =>
                                  handleChange(
                                    'conflictCompany2',
                                    value.target.value
                                  )
                                }
                                placeholder='Company name 2'
                              />
                              {/* {required && !conflictCompany2 && (
                                <div>
                                  <small>Company name 2 is required !</small>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div class=''>
                            <div class='loginPlus'>
                              <button
                                onClick={() =>
                                  handleChangeLocal('addCompany', '3rdCompany')
                                }
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan x='0' y='0'>
                                        +
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {addCompany === '3rdCompany' && (
                        <div class='row'>
                          <div class='companyInput1'>
                            <div class='logingroup'>
                              <input
                                type='text'
                                value={conflictCompany3}
                                onChange={value =>
                                  handleChange(
                                    'conflictCompany3',
                                    value.target.value
                                  )
                                }
                                placeholder='Company name 3'
                              />
                              {/* {required && !conflictCompany3 && (
                                <div>
                                  <small>Company name 3 is required !</small>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div class=''>
                            <div class='loginPlus'>
                              <button>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='30'
                                  height='30'
                                  viewBox='0 0 22 22'
                                >
                                  <g
                                    id='Group_560'
                                    data-name='Group 560'
                                    transform='translate(-16042 -761)'
                                  >
                                    <g
                                      id='Ellipse_4'
                                      data-name='Ellipse 4'
                                      transform='translate(16042 761)'
                                      fill='#fff'
                                      stroke='#707070'
                                      stroke-width='0.5'
                                    >
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='11'
                                        stroke='none'
                                      />
                                      <circle
                                        cx='11'
                                        cy='11'
                                        r='10.75'
                                        fill='none'
                                      />
                                    </g>
                                    <text
                                      id='_'
                                      data-name='+'
                                      transform='translate(16049 777)'
                                      fill='#969696'
                                      font-size='14'
                                      font-family='OpenSans-Light, Open Sans'
                                      font-weight='300'
                                    >
                                      <tspan x='0' y='0'>
                                        +
                                      </tspan>
                                    </text>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12'>
                    <div class='signupProgress'>
                      <div class='signupProgressIn'>
                        <h4>Sign-up progress</h4>
                        <div class='signProgStep'>
                          <ul>
                            <li>
                              <a href='#' class='active'>
                                <span class='fill'>
                                  <i class='fas fa-check'></i>
                                </span>{' '}
                                Experience
                              </a>
                              <a href='#' class='active'>
                                <span></span> Values
                              </a>
                              <a href='#'>
                                <span></span> About you
                              </a>
                              <a href='#'>
                                <span></span> Financial details
                              </a>
                              <a href='#'>
                                <span></span> Elevator pitch{' '}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-12'>
                    <div class='question_box'>
                      <h3 class='title'>
                        Let's build a great culture together
                      </h3>
                      <h5>Please complete the Egtos value score</h5>
                      <div className='hideWeb'>
                        <div class='question_box'>
                          <h5 className='text_blue'>Respect</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={respect}
                              onChange={value =>
                                handleChange('respect', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='text_blue'>Diversity</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={diversity}
                              onChange={value =>
                                handleChange('diversity', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='text_blue'>Excellence</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={excellence}
                              onChange={value =>
                                handleChange('excellence', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='text_blue'>Always learning</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={learning}
                              onChange={value =>
                                handleChange('learning', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='text_blue'>Humility</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={humility}
                              onChange={value =>
                                handleChange('humility', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class='signup_table custom_table displayMobileHide table-responsive'>
                        <table class='table table-borderless'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}></th>
                              <th style={{ width: '25%' }}>
                                I have a competent grasp of this value and have
                                demonstrated it in all my projects
                              </th>
                              <th style={{ width: '25%' }}>
                                I regularly demonstrate this value. It's
                                important to me.
                              </th>
                              <th style={{ width: '25%', textAlign: 'right' }}>
                                This value is very strong for me, I am
                                passionate about this value and demonstrate it
                                continually.
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class='table_title'>Respect</td>

                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={respect === '1'}
                                      onChange={() =>
                                        handleChange('respect', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={respect === '2'}
                                      onChange={() =>
                                        handleChange('respect', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={respect === '3'}
                                      onChange={() =>
                                        handleChange('respect', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>Diversity</td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={diversity === '1'}
                                      onChange={() =>
                                        handleChange('diversity', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={diversity === '2'}
                                      onChange={() =>
                                        handleChange('diversity', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={diversity === '3'}
                                      onChange={() =>
                                        handleChange('diversity', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>Excellence</td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={excellence === '1'}
                                      onChange={() =>
                                        handleChange('excellence', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={excellence === '2'}
                                      onChange={() =>
                                        handleChange('excellence', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={excellence === '3'}
                                      onChange={() =>
                                        handleChange('excellence', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>Always learning</td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={learning === '1'}
                                      onChange={() =>
                                        handleChange('learning', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={learning === '2'}
                                      onChange={() =>
                                        handleChange('learning', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={learning === '3'}
                                      onChange={() =>
                                        handleChange('learning', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>Humility</td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={humility === '1'}
                                      onChange={() =>
                                        handleChange('humility', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={humility === '2'}
                                      onChange={() =>
                                        handleChange('humility', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={humility === '3'}
                                      onChange={() =>
                                        handleChange('humility', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-12'>
                    <div class='question_box'>
                      <h3 class='title'>
                        Please help us build amazing global dynamic teams
                      </h3>
                      <h5>
                        Please complete the Egtos Blanced Experts Team Score
                        (BETS) quesionnaire
                      </h5>
                      <div className='hideWeb'>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Listening</h5>
                          <h5 className='mt-0'>
                            Stakeholder engagement and gravitas
                          </h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={listening}
                              onChange={value =>
                                handleChange('listening', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Communication</h5>
                          <h5 className='mt-0'>Communication and influence</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={communication}
                              onChange={value =>
                                handleChange(
                                  'communication',
                                  value.target.value
                                )
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Management</h5>
                          <h5 className='mt-0'>
                            Project management and delivery
                          </h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={management}
                              onChange={value =>
                                handleChange('management', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Analysis</h5>
                          <h5 className='mt-0'>
                            Analysis and strategy development
                          </h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={analysis}
                              onChange={value =>
                                handleChange('analysis', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Thinking</h5>
                          <h5 className='mt-0'>Vision and innovation</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={thinking}
                              onChange={value =>
                                handleChange('thinking', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class='signup_table displayMobileHide custom_table table-responsive'>
                        <table class='table table-borderless'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}></th>

                              <th style={{ width: '25%' }}>
                                Please complete the Egtos Blanced Experts Team
                                Score (BETS) quesionnaire
                              </th>

                              <th style={{ width: '25%' }}>
                                This capability is a key part of my professional
                                skillset and I enjoy using this capability
                                regularly.
                              </th>

                              <th style={{ width: '25%', textAlign: 'right' }}>
                                This capability is central to my professional
                                life. I enjoy my work because I am talented in
                                this area.
                              </th>

                              {/* <th style={{ width: "15%" }}>A core part of 'who I am' as well as
                                                                'what I do'- this is capability that inspires me to do great
                                                                work.</th>

                                                            <th style={{ width: "15%" }}>This capability defines me above all
                                                                others. I excel in this area. I am the go-to person when
                                                                this capability is required ! and I'm know to be an expert in
                                                                this.</th> */}
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class='table_title'>
                                Listening
                                <p>Stakeholder engagement and gravitas</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={listening === '1'}
                                      onChange={() =>
                                        handleChange('listening', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={listening === '2'}
                                      onChange={() =>
                                        handleChange('listening', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={listening === '3'}
                                      onChange={() =>
                                        handleChange('listening', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                  {/* <div className={"horizontalLine"} /> */}
                                </div>
                              </td>

                              {/* <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={listening === "4"}
                                                                            onChange={() => handleChange('listening', '4')}
                                                                            value="option4" />
                                                                    </div>
                                                                    <div className={"horizontalLine"} />
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={listening === "5"}
                                                                            onChange={() => handleChange('listening', '5')}
                                                                            value="option5" />
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <div class="line"></div> */}
                            </tr>

                            <tr>
                              <td class='table_title'>
                                Communication
                                <p>Communication and influence</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={communication === '1'}
                                      onChange={() =>
                                        handleChange('communication', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={communication === '2'}
                                      onChange={() =>
                                        handleChange('communication', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={communication === '3'}
                                      onChange={() =>
                                        handleChange('communication', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                  {/* <div className={"horizontalLine"} /> */}
                                </div>
                              </td>

                              {/* <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={communication === "4"}
                                                                            onChange={() => handleChange('communication', '4')}
                                                                            value="option4" />
                                                                    </div>
                                                                    <div className={"horizontalLine"} />
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={communication === "5"}
                                                                            onChange={() => handleChange('communication', '5')}
                                                                            value="option5" />
                                                                    </div>
                                                                </div>
                                                            </td> */}

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>
                                Management
                                <p>Project management and delivery</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={management === '1'}
                                      onChange={() =>
                                        handleChange('management', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={management === '2'}
                                      onChange={() =>
                                        handleChange('management', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={management === '3'}
                                      onChange={() =>
                                        handleChange('management', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                  {/* <div className={"horizontalLine"} /> */}
                                </div>
                              </td>

                              {/* <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={management === "4"}
                                                                            onChange={() => handleChange('management', '4')}
                                                                            value="option4" />
                                                                    </div>
                                                                    <div className={"horizontalLine"} />
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={management === "5"}
                                                                            onChange={() => handleChange('management', '5')}
                                                                            value="option5" />
                                                                    </div>
                                                                </div>
                                                            </td> */}

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>
                                Analysis
                                <p>Analysis and strategy development</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={analysis === '1'}
                                      onChange={() =>
                                        handleChange('analysis', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={analysis === '2'}
                                      onChange={() =>
                                        handleChange('analysis', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={analysis === '3'}
                                      onChange={() =>
                                        handleChange('analysis', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                  {/* <div className={"horizontalLine"} /> */}
                                </div>
                              </td>

                              {/* <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={analysis === "4"}
                                                                            onChange={() => handleChange('analysis', '4')}
                                                                            value="option4" />
                                                                    </div>
                                                                    <div className={"horizontalLine"} />
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={analysis === "5"}
                                                                            onChange={() => handleChange('analysis', '5')}
                                                                            value="option5" />
                                                                    </div>
                                                                </div>
                                                            </td> */}

                              <div class='line'></div>
                            </tr>

                            <tr>
                              <td class='table_title'>
                                Thinking
                                <p>Vision and innovation</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={thinking === '1'}
                                      onChange={() =>
                                        handleChange('thinking', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={thinking === '2'}
                                      onChange={() =>
                                        handleChange('thinking', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={thinking === '3'}
                                      onChange={() =>
                                        handleChange('thinking', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                  {/* <div className={"horizontalLine"} /> */}
                                </div>
                              </td>

                              {/* <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={thinking === "4"}
                                                                            onChange={() => handleChange('thinking', '4')}
                                                                            value="option4" />
                                                                    </div>
                                                                    <div className={"horizontalLine"} />
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="loginGenderRadio">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            checked={thinking === "5"}
                                                                            onChange={() => handleChange('thinking', '5')}
                                                                            value="option5" />
                                                                    </div>
                                                                </div>
                                                            </td> */}

                              <div class='line'></div>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-12'>
                    <div class='question_box'>
                      <h3 class='title'>
                        Let's build a great culture together
                      </h3>
                      <h5>Please complete the Egtos value score</h5>
                      <div className='hideWeb'>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Planning</h5>
                          <h5 className='mt-0'>
                            How do you like to plan your day?
                          </h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={planning}
                              onChange={value =>
                                handleChange('planning', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Collaborating</h5>
                          <h5 className='mt-0'>
                            How do you like to collaborate with the team?
                          </h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={collaborating}
                              onChange={value =>
                                handleChange(
                                  'collaborating',
                                  value.target.value
                                )
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Collaborating</h5>
                          <h5 className='mt-0'>
                            How do you like to collaborate with the team?
                          </h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={collaborating}
                              onChange={value =>
                                handleChange(
                                  'collaborating',
                                  value.target.value
                                )
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        <div class='question_box'>
                          <h5 className='mb-0 text_blue'>Inspiration</h5>
                          <h5 className='mt-0'>What inspires your thinking?</h5>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={inspiration}
                              onChange={value =>
                                handleChange('inspiration', value.target.value)
                              }
                            >
                              {selectList?.map((item, index) => (
                                <option key={index} value={item?.value}>
                                  {item?.title}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class='signup_table displayMobileHide custom_table table-responsive'>
                        <table class='table table-borderless'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}></th>
                              <th style={{ width: '25%' }}>
                                The client provided a basic level of
                                documentation and guidance.
                              </th>
                              <th style={{ width: '25%' }}></th>
                              <th style={{ width: '25%', textAlign: 'right' }}>
                                I enjoy focused work without interruption and
                                scheduled prepared for meetings.
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class='table_title'>
                                Planning
                                <p>How do you like to plan your day?</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={planning === '1'}
                                      onChange={() =>
                                        handleChange('planning', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={planning === '2'}
                                      onChange={() =>
                                        handleChange('planning', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={planning === '3'}
                                      onChange={() =>
                                        handleChange('planning', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class='signup_table displayMobileHide custom_table table-responsive'>
                        <table class='table table-borderless'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}></th>
                              <th style={{ width: '25%' }}>
                                TI like the openendedness of Miro or Jam Board
                                for generating idas on the hoof as a team.
                              </th>
                              <th style={{ width: '25%' }}></th>
                              <th style={{ width: '25%', textAlign: 'right' }}>
                                I enjoy the structure of tools like Power Point
                                and prefer to come preparted with well rounded
                                inputs.
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class='table_title'>
                                Collaborating
                                <p>
                                  How do you like to collaborate with the team?
                                </p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={collaborating === '1'}
                                      onChange={() =>
                                        handleChange('collaborating', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={collaborating === '2'}
                                      onChange={() =>
                                        handleChange('collaborating', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={collaborating === '3'}
                                      onChange={() =>
                                        handleChange('collaborating', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class='signup_table displayMobileHide custom_table table-responsive'>
                        <table class='table table-borderless'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}></th>
                              <th style={{ width: '25%' }}>
                                Face to face is best for me to build a natural
                                rapport with a client. It's all part of a
                                conversation after all.
                              </th>
                              <th style={{ width: '25%' }}></th>
                              <th style={{ width: '25%', textAlign: 'right' }}>
                                I like to impress with structured thinking and
                                slick well put together reports and
                                presentations.
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class='table_title'>
                                Collaborating
                                <p>
                                  How do you like to collaborate with the team?
                                </p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={collaborating2 === '1'}
                                      onChange={() =>
                                        handleChange('collaborating2', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={collaborating2 === '2'}
                                      onChange={() =>
                                        handleChange('collaborating2', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={collaborating2 === '3'}
                                      onChange={() =>
                                        handleChange('collaborating2', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class='signup_table displayMobileHide custom_table table-responsive'>
                        <table class='table table-borderless'>
                          <thead>
                            <tr>
                              <th style={{ width: '20%' }}></th>
                              <th style={{ width: '25%' }}>
                                Big picture ideas, articles, opinion pieces help
                                me imagine the art of the possible.
                              </th>
                              <th style={{ width: '25%' }}></th>
                              <th style={{ width: '25%', textAlign: 'right' }}>
                                I love deep diving in to the data.
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td class='table_title'>
                                Inspiration
                                <p>What inspires your thinking?</p>
                              </td>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={inspiration === '1'}
                                      onChange={() =>
                                        handleChange('inspiration', '1')
                                      }
                                      value='option1'
                                    />
                                  </div>
                                  <div className={'horizontalLine'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={inspiration === '2'}
                                      onChange={() =>
                                        handleChange('inspiration', '2')
                                      }
                                      value='option2'
                                    />
                                  </div>
                                  <div className={'horizontalLine2'} />
                                </div>
                              </td>
                              <td>
                                <div class='loginGenderRadio d-flex align-items-center'>
                                  <div className={'horizontalLine1'} />
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={inspiration === '3'}
                                      onChange={() =>
                                        handleChange('inspiration', '3')
                                      }
                                      value='option3'
                                    />
                                  </div>
                                </div>
                              </td>

                              <div class='line'></div>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='row'>
                  <div class='col-md-6'>
                    <div class='question_box'>
                      <div class='row'>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={book}
                              onChange={value =>
                                handleChange('book', value.target.value)
                              }
                              placeholder='What is your favorite book?'
                            />
                            {required && !book && (
                              <div>
                                <small>Favorite book is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={color}
                              onChange={value =>
                                handleChange('color', value.target.value)
                              }
                              placeholder='What is your favorite color?'
                            />
                            {required && !color && (
                              <div>
                                <small>Favorite color is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class='row marginInputMobile'>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={hobby}
                              onChange={value =>
                                handleChange('hobby', value.target.value)
                              }
                              placeholder='What is your favorite Hobby?'
                            />
                            {required && !hobby && (
                              <div>
                                <small>Favorite hobby is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={animal}
                              onChange={value =>
                                handleChange('animal', value.target.value)
                              }
                              placeholder='What is your favorite animal?'
                            />
                            {required && !animal && (
                              <div>
                                <small>Favorite animal is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class='row marginInputMobile'>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={sport}
                              onChange={value =>
                                handleChange('sport', value.target.value)
                              }
                              placeholder='What is your favorite Sport?'
                            />
                            {required && !sport && (
                              <div>
                                <small>Favorite sport is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={fruit}
                              onChange={value =>
                                handleChange('fruit', value.target.value)
                              }
                              placeholder='What is your favorite fruit?'
                            />
                            {required && !fruit && (
                              <div>
                                <small>Favorite fruit is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div class='row marginInputMobile'>
                        <div class='col-sm-6 inputMargin1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={actor}
                              onChange={value =>
                                handleChange('actor', value.target.value)
                              }
                              placeholder='What is your favorite actor or actress?'
                            />
                            {required && !actor && (
                              <div>
                                <small>
                                  Favorite actor or actress is required !
                                </small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='border_line'></div>
                <div class='row'>
                  <div class='col-lg-12'>
                    <div class='question_box'>
                      <h3 class='title'>
                        Please help us learn a little more about you
                      </h3>
                      <h5 className='mt-2'>
                        From List Below,please select three things you don't
                        enjoying doing but have to, sometimes
                      </h5>

                      <div class='col-md-8'>
                        <div class='question_box'>
                          <div class='row'>
                            <div class='col-sm-4 mlweb-15'>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={washingDishes}
                                      onClick={value =>
                                        handleChange(
                                          'washingDishes',
                                          !washingDishes
                                        )
                                      }
                                      value='option3'
                                    />
                                    <label>Washing the Dishes</label>
                                  </div>
                                </div>
                              </td>
                            </div>
                            <div class='col-sm-4'>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={stuckTraffic}
                                      onClick={() =>
                                        handleChange(
                                          'stuckTraffic',
                                          !stuckTraffic
                                        )
                                      }
                                      value='option3'
                                    />
                                    <label>Getting Stuck in Traffic</label>
                                  </div>
                                </div>
                              </td>
                            </div>

                            <div class='col-sm-4'>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={publicSpeaking}
                                      onClick={() =>
                                        handleChange(
                                          'publicSpeaking',
                                          !publicSpeaking
                                        )
                                      }
                                      value='option3'
                                    />
                                    <label>Speaking in Public</label>
                                  </div>
                                </div>
                              </td>
                            </div>
                          </div>

                          <div class='row marginInputMobile'>
                            <div class='col-sm-4 mlweb-15'>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={peopleLate}
                                      onClick={() =>
                                        handleChange('peopleLate', !peopleLate)
                                      }
                                      value='option3'
                                    />
                                    <label>When People are late</label>
                                  </div>
                                </div>
                              </td>
                            </div>
                            <div class='col-sm-6'>
                              <td>
                                <div class='loginGenderRadio'>
                                  <div class='form-check'>
                                    <input
                                      class='form-check-input'
                                      type='radio'
                                      checked={talktivePeople}
                                      onClick={() =>
                                        handleChange(
                                          'talktivePeople',
                                          !talktivePeople
                                        )
                                      }
                                      value='option3'
                                    />
                                    <label>
                                      Sitting Next to a very talktive Person
                                    </label>
                                  </div>
                                </div>
                              </td>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='logingroup mt-5'>
                  <button
                    onClick={() => _onClickButton()}
                    class='btn btn-outline-warning'
                  >
                    Save and move on
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
