import React from 'react'
import { HomeFooter, MainFooter, HomeHeader, Card } from '../../components'
import Scroll, { Link, Element } from 'react-scroll'
import image1 from '../../assets/svg/Group 1353.svg'
import image2 from '../../assets/svg/Group 1354.svg'
import image3 from '../../assets/svg/Group 1383.svg'
import image4 from '../../assets/svg/Group 1384.svg'
import image5 from '../../assets/svg/Group 1385.svg'
import image6 from '../../assets/svg/Group 1386.svg'
import image7 from '../../assets/svg/Group 1387.svg'
import image8 from '../../assets/svg/Group 1388.svg'
import image9 from '../../assets/svg/Group 1389.svg'
import image10 from '../../assets/svg/Group 1400.svg'
import image11 from '../../assets/svg/Group 1463.svg'
import image12 from '../../assets/svg/Group 5439.svg'
import image13 from '../../assets/svg/Group 5544.svg'
import image14 from '../../assets/svg/Group 5559.svg'
import image15 from '../../assets/svg/Group 5629.svg'
import image16 from '../../assets/svg/Group 5630.svg'
import image17 from '../../assets/svg/Group 5631.svg'
import image18 from '../../assets/svg/Group 5632.svg'
import image19 from '../../assets/svg/Group 5633.svg'
import image20 from '../../assets/svg/Group 5634.svg'
import image21 from '../../assets/svg/Group 5635.svg'
import image22 from '../../assets/svg/Group 5636.svg'
import './index.css'

export default function Explore ({}) {
  var scroll = Scroll.animateScroll
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      {/* <!-- banner section start --> */}
      <Element name='Element1' className='element'>
        <section class='explore'>
          <div class='container height100 d-flex align-items-center mt-5'>
            <div class='banner_content'>
              <h1 className={'font-80-home '}>Industries</h1>
              <p className={'text-white homeSubText mt-3'}>
                Expert teams for your industry, on demand
              </p>
              {/* <div className='readmoreBox'>
                <a href='' class='underlineNone'>
                  READ MORE<i class='ml-4 fas fa-arrow-right'></i>
                </a>
              </div> */}
              {/* <a href='' class='text_green btn_link displayMobileHide'>
                See what we can offer<i class='ml-1 fas fa-arrow-right'></i>
              </a> */}
              {/* <div>
                                <Link to="Element2" spy={true} smooth={true}>
                                    <i class="text-white slide-arrow fas fa-arrow-down"></i>
                                </Link>
                            </div> */}
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      {/* <!-- second section start --> */}
      <Element name='Element2'>
        <section class=''>
          <div class='container mb-5'>
            <h2 className={'text_blue mb-4 font-open-bold'}>Industries</h2>
            <p className={'text_blue subText'}>
              Disqui venis et et quiae libus ullam esto ipsae plabore reici del
              is eario volorerro imus voluptatus, conserf erovite ratibeatur aut
              asitium et eossita taspis aut eium experro videlentiae. Lore
              disqui venis et et quiae libus ullam esto ipsae plabore reici del
              is eario.
            </p>
            <a href='' class='text_green btn_link'>
              SEE WHAT WE CAN OFFER<i class='ml-1 fas fa-arrow-right'></i>
            </a>
          </div>
          <div class='container height100 d-flex align-items-center'>
            <div class='row'>
              <div class='col-md-4'>
                <Card image={image2} title={'Healthcare'} />
              </div>
              <div class='col-md-4'>
                <Card image={image3} title={'Industrials'} />
              </div>
              <div class='col-md-4'>
                <Card image={image5} title={'Utilities'} />
              </div>
              <div class='col-md-4'>
                <Card image={image6} title={'Information Technology'} />
              </div>
              <div class='col-md-4'>
                <Card image={image11} title={'Real Estate'} />
              </div>
              <div class='col-md-4'>
                <Card image={image10} title={'Materials'} />
              </div>
              <div class='col-md-4'>
                <Card image={image9} title={'Consumer Discretionary'} />
              </div>
              <div class='col-md-4'>
                <Card image={image1} title={'Communication Services'} />
              </div>
              <div class='col-md-4'>
                <Card image={image8} title={'Consumer Staples'} />
              </div>
              <div class='col-md-4'>
                <Card image={image7} title={'Energy'} />
              </div>
              <div class='col-md-4'>
                <Card image={image4} title={'Financial Services'} />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- second section end --> */}

        {/* <!-- offer section start --> */}
        <section class='offer_section sec_pd '>
          <div class='container border-top'>
            <div>
              <h2 className={'text_blue mb-3 font-open-bold mt-4'}>
                Our services
              </h2>
              <h5 className={'text_green font-weight-bold mb-5'}>
                How we help our clients in all industries accelerate growth with
                <br />
                truly global tams of experts.
              </h5>
            </div>
            <div class='row'>
              <div class='col-md-6'>
                <Card
                  image={image6}
                  title={'Strategic intent'}
                  noArrow={true}
                />
              </div>
              <div class='col-md-6'>
                <Card
                  image={image11}
                  title={'Strategy execution'}
                  noArrow={true}
                />
              </div>
              <div class='col-md-6'>
                <Card
                  image={image10}
                  title={'Operational innovation'}
                  noArrow={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* <!-- offer section end --> */}
      <Element>
        <section class='agile-section'>
          <div class='container'>
            <div class='row justify-content-center'>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image13} />
                <h4 className={'text_blue mt-2'}>Agile ways of working</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image17} />
                <h4 className={'text_blue mt-2'}>
                  Corporate development and strategy
                </h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image22} />
                <h4 className={'text_blue mt-2'}>Cultural transformation</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image12} />
                <h4 className={'text_blue mt-2'}>
                  Cybersecurity and data privacy
                </h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image16} />
                <h4 className={'text_blue mt-2'}>Digital and IT strategy</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image21} />
                <h4 className={'text_blue mt-2'}>
                  Diversity, Equity and Inclusion
                </h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image15} />
                <h4 className={'text_blue mt-2'}>Data science and analytics</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image19} />
                <h4 className={'text_blue mt-2'}>M&A transaction services</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image14} />
                <h4 className={'text_blue mt-2'}>People strategy</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image18} />
                <h4 className={'text_blue mt-2'}>Product development</h4>
              </div>
              <div class='col-md-3 col-xs-6 text-center mb-5'>
                <img src={image20} />
                <h4 className={'text_blue mt-2'}>Operational excellence</h4>
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* <!--section 4 end--> */}

      <div className='hideWeb'>
        <HomeFooter displayMobileHide />
      </div>
      <MainFooter />
    </div>
  )
}
