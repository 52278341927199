import React from 'react'
import Logo from '../../assets/img/logob.png'

export default function HomeFooter ({
  showCOntactUsMobile,
  displayMobileHide
}) {
  return (
    <div>
      {/* <!--Home footer--> */}
      <footer class='home_footer'>
        <div class='container phm-0'>
          <div class='row'>
            <div class={`col-md-6 ${displayMobileHide && 'displayMobileHide'}`}>
              <div class='company_address ml-2 mt-4 text-left'>
                <a href='#'>
                  {' '}
                  <img src={Logo} />
                </a>
                <div
                  class={`contact_us ${!showCOntactUsMobile &&
                    'displayMobileHide'}`}
                >
                  <h3 className='reduceVMargin'>Contact Us</h3>
                  <p className='reduceVMargin'>
                    For any generic questions please contact:
                  </p>
                  <p className='reduceVMargin'>info@egtos.com</p>
                  <p className='mt-2'>
                    For business related requests please contact:
                  </p>
                  <p className='reduceVMargin mb-3'>enquires@egtos.com</p>
                </div>
              </div>
            </div>
            <div class='col-md-2'></div>
            <div class='col-md-4 blue_bg'>
              <div class='subs_newsletter'>
                <form>
                  <div class='row align-items-end justify-content-center'>
                    <div class='row justify-content-center mhm-0' style={{ width: '80%' }}>
                      <h4 className='displayMobileHide'>
                        <b>Subscribe </b>to our newsletter
                      </h4>
                      <h4 className='hideWeb subscribeMobile'>
                        <b>Subscribe </b>
                      </h4>
                      <div class='logingroup footerInputs'>
                        <input
                          type='text'
                          formControlName='firstName'
                          placeholder='First Name'
                        />
                      </div>
                      <div class='logingroup footerInputs'>
                        <input
                          type='text'
                          formControlName='Surname'
                          placeholder='Surname'
                        />
                      </div>
                      <div class='logingroup footerInputs'>
                        <input
                          type='text'
                          formControlName='Company'
                          placeholder='Company'
                        />
                      </div>
                      <div class='logingroup footerInputs'>
                        <input
                          type='text'
                          formControlName='Email'
                          placeholder='Email'
                        />
                      </div>
                    </div>
                    <div className='subscribeArrow1'>
                      <i class='ml-1 font-mobile-small mobile-mr-4 text-right fas fa-arrow-right'></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
