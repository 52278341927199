import React, { useEffect, useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import LogoImage from '../../assets/img/LogoImage.png'
import LogoImage1 from '../../assets/img/LogoImage1.png'
import GoogleMapReact from 'google-map-react'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import { upsertprojectTeam } from '../../api/projects'
import { useSnackbar } from 'notistack'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import Admocs from '../../assets/img/Admocs.png'
import BAR from '../../assets/img/BAR.png'
import IBM from '../../assets/img/IBM.png'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useHistory } from 'react-router-dom'
import test from '../../assets/ProjectDevelopment.pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js` // import { Document, Page } from 'react-pdf/dist/esm/entry.parcel';


// const list = [
//   { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' },
//   { image: LogoImage1, name: 'Stephen Holmes', sub: 'Turkey' },
//   { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' },
//   { image: LogoImage1, name: 'Stephen Holmes', sub: 'Turkey' },
//   { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' }
// ]
const MAP_API_KEY = 'AIzaSyC-1dUdU_nJ8N4Zh3ijPzLF7MANu6sIkKQ'

const MuiAccordion = styled(props => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none'
  },
  marginBottom: 20
}))

const MuiAccordionSummary = styled(props => (
  <AccordionSummary expandIcon={false} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)'
}))

export default function ProjectOverviewInvoice({ }) {
  const history = useHistory()

  const list = history?.location?.state?.selectedteam

  const { enqueueSnackbar } = useSnackbar()
  const project = history?.location?.state?.project
  const [expanded, setExpanded] = React.useState('panel1')
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const handleChangeAccordion = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [state, setState] = useState({
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  })
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const selectedTeam = async (selectedteam) => {
    console.log(selectedteam, 'team');

    try {
      const res = await upsertprojectTeam({ _id: project._id, teams: selectedteam })
      if (res.data.result) {
        history.push('/expert/project')
      }

    } catch (ex) {
      enqueueSnackbar(`Project Team Failed  `, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })

    }


  }

  var scroll = Scroll.animateScroll
  return (
    <div>
      <MainHeader />

      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class='col-md-12'>
              <div class='content'>
                <div className='d-flex justify-content-between align-items-start'>
                  <div className='col-md-6'>
                    <h4 class='heading_font_black'>{project?.projectName}</h4>
                    <p className='text_blue'>
                      Your Team is ready!please confirm the team and accept the
                      invoice
                    </p>
                  </div>
                  <div className='col-md-3'>
                    <div class='content'>
                      <p className='text_blue font-weight-bold font-16'>
                        PROGRESS & DEADLINES
                      </p>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className=''>
                          <p>{'-'}</p>
                          <p className='text_green font-18 mt-1'>0%</p>
                        </div>
                        <div className=''>
                          <p className='text_blue'>Next</p>
                          <p className='text_blue_opacity'>
                            {project?.expectStartDate}
                          </p>
                        </div>
                        <div className=''>
                          <p className='text_blue'>Final</p>
                          <p className='text_blue_opacity'>
                            {project?.expectedEndDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class=' heading_font_black mt-4 mb-5'>The Team</h4>
                {list?.map((item, index) => (
                  <MuiAccordion
                    expanded={expanded === index}
                    onChange={handleChangeAccordion(index)}
                  >
                    <MuiAccordionSummary
                      aria-controls='panel1d-content'
                      id='panel1d-header'
                    >
                      {/* <div className='teamBox'> */}
                      <div className='row'>
                        <img
                          src={item.image || LogoImage1}
                          className='logoImage mr-2'
                          alt={''}
                        />
                        <div>
                          <h5 className='text_blue mb-2'>
                            {item?.firstName + ' ' + item?.lastName}
                          </h5>
                          <p className='text_blue_opacity reduceMargin font-16'>
                            {item?.livingCountry}
                          </p>
                          <p className='text_blue_opacity'>View full profile</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='mr-5'>
                          <p className='text_blue'>{'Role:'}</p>
                          <p className='text_blue_opacity font-18'>
                            {item.role}
                          </p>
                        </div>
                        <div className='mr-5'>
                          <p className='text_blue'>{'Client rating:'}</p>
                          <p className='text_blue_opacity font-18'>{'92%'}</p>
                        </div>
                        <div className='mr-5'>
                          <p className='text_blue'>{'Projects completed:'}</p>
                          <p className='text_blue_opacity font-18'>{'17'}</p>
                        </div>
                        <div className='mr-5'>
                          <p className='text_blue'>
                            {'Years of professional experience:'}
                          </p>
                          <p className='text_blue_opacity font-18'>{'20'}</p>
                        </div>
                      </div>
                      <i class='fas fa-redo-alt'></i>
                      {/* </div> */}
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <div className='overviewDiv'>
                        <div className='typeDiv1 pb-4 col-md-5 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>{'Education'}</p>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Highest Level'}
                              </p>
                              <p className='text_blue_opacity font-13 reduceMargin'>
                                {
                                  'PhD, Pharmaceutical Science (University of Australia)'
                                }
                              </p>
                            </div>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Industry Certifications'}
                              </p>
                              <p className='text_blue_opacity font-13 reduceMargin'>
                                {
                                  'BDCP – Biopharmaceutical Development Certified Professional PDCP – Pharmaceutical Development Certified Professional'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='typeDiv1 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4 '>
                            {'Country of citizenship:'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {item.livingCountry}
                          </p>
                        </div>
                        <div className='typeDiv1 col-md-4 col-sm-12'>
                          <p className='text_blue mt-4'>{'Technology'}</p>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Expert*'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {'R'}
                              </p>
                            </div>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Expert*'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {item.role}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='overviewDiv border-top'>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>{'Current level:'}</p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'Associate'}
                          </p>
                        </div>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4 '>
                            {'Teams average rating:'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'97%'}
                          </p>
                        </div>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>
                            {'Projects completed:'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'17'}
                          </p>
                        </div>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12'>
                          <p className='text_blue mt-4'>{'Client rating:'}</p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'92%'}
                          </p>
                        </div>
                      </div>
                      <div className='overviewDiv border-top'>
                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>
                            {'Years of professional experience::'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'20'}
                          </p>
                        </div>
                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                          <p className='text_blue mt-4 '>
                            {'Vertical experience (years):'}
                          </p>
                          <p className='text_blue_opacity font-16'>
                            {
                              'Clinical Trial Operations (12) Pharma-covigilance (8)'
                            }
                          </p>
                        </div>
                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>
                            {'Previous organisations'}
                          </p>
                          <div className='d-flex justify-content-between align-items-center mt-2'>
                            <img
                              src={Admocs}
                              alt={''}
                              width={'30%'}
                              style={{ height: 25 }}
                            />
                            <img
                              src={IBM}
                              alt={''}
                              width={'25%'}
                              style={{ height: 40 }}
                            />
                            <img
                              src={BAR}
                              alt={''}
                              width={'30%'}
                              style={{ height: 25 }}
                            />
                          </div>
                        </div>
                      </div>
                    </MuiAccordionDetails>
                  </MuiAccordion>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Element>

      <section class='sec-4 '>
        <div class='container bg-grey height800'>
          <Document
            file={test}
            onLoadError={e => {
              console.log('e', e)
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page className={'react-pdf__Page'} pageNumber={pageNumber} />
          </Document>
        </div>
        {/* 
          <div className='content p-3'>
            <h3 className='text_blue'>Invoice</h3>
            <p className='text_blue'>Thank you for using Egtos.</p>
            <p className='text_blue width50'>
              Muscien iendant, nonsequodicatu repremquam voluptatem soloresequis
              magnimi ncidelentlbus, nitat qui doloribus moluptae commost
              aliquae. Nem fugiaeoffice temqui qui doluptaqui as quis aut eaqui
              odi beaquam.
            </p>
          </div>
         */}
      </section>
      <section class='sec-4'>
        <div class='container'>
          <div class='d-flex'>
            <div class='logingroup width30 mr-3'>
              <button
                onClick={() => history.push({
                  pathname: '/project/team/options',
                  state: {
                    projectid: project._id,
                    clientid: project.clientID || localStorage.getItem('_id')
                  }
                })}
                class='btn btn-outline-warning btn_red'
              >
                Cancel
              </button>
            </div>
            <div class='logingroup width30'>
              <button
                onClick={() => selectedTeam(list)}
                class='btn btn-outline-warning'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
    </div>
  )
}
