import React, { useEffect, useState } from 'react'
import {
  businessFunctionlist,
  companyList,
  countryList,
  currencyList,
  ethnicityList,
  genderList,
  industryList,
  referencesourceList,
  sectorList,
  skillList
} from '../../api/auth'
import {
  MainFooter,
  Process1,
  Process2,
  Process3,
  Process4,
  Process5,
  Process6,
  SignupHeader
} from '../../components'
import { upsertExpert } from '../../api/expert'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

export default function SignupProcess () {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [state, setState] = useState({
    process: 'process1',
    countrylist: [],
    businesslist: [],
    companylist: [],
    currencylist: [],
    ethnicitylist: [],
    genderlist: [],
    industrylist: [],
    selectedSkills: [],
    referencesourcelist: [],
    sectorlist: [],
    skilllist: [],
    loading: false,
    sector1: '',
    sector1years: 0,
    sector2years: 0,
    sector2: '',
    industry1: '',
    industry1years: 0,
    industry2years: 0,
    industry2: '',
    businessFunction1years: 0,
    businessFunction2years: 0,
    respect: '1',
    diversity: '1',
    excellence: '1',
    learning: '1',
    humility: '1',
    listening: '1',
    communication: '1',
    management: '1',
    analysis: '1',
    thinking: '1',
    planning: '1',
    collaborating: '1',
    collaborating2: '1',
    inspiration: '1',
    washingDishes: false,
    stuckTraffic: false,
    publicSpeaking: false,
    peopleLate: false,
    talktivePeople: false,
    gender: 'Male',
    veteran: false,
    singleParent: false,
    specialChild: false,
    nonprofitCommitment: false,
    nonprofitCompany1: '',
    nonprofitCompany2: '',
    ethnicity: 'Cambodia',
    citizenCountry1: '',
    citizenCountry2: '',
    citizenCountry3: '',
    visaUSA: false,
    visaCanada: false,
    visaAUS: false,
    visaUK: false,
    visaEU: false,
    residenceCountry: '',
    livingCountry: '',
    knownLanguage1: '',
    knownLanguage2: '',
    knownLanguage3: '',
    knownLanguage4: '',
    knownLanguage5: '',

    expectedCurrency: 'USD',
    expectedAmount: 7,
    leadingEgtos: false,
    projectCertification: '',
    onsiteManager: false,
    onsitepercentageengagement: '25',

    clientinterview: false,
    focusgroup: false,
    survey: false,

    refemail1: '',
    refemail2: '',
    refemail3: '',
    refemail4: '',

    taxIdentifcationNumber: '',
    taxIdentifcationDocument: '',

    preferedPayment: 'ACH',
    advancedDegree:''
  })

  const {
    process,
    countrylist,
    businesslist,
    companylist,
    currencylist,
    ethnicitylist,
    genderlist,
    industrylist,
    referencesourcelist,
    sectorlist,
    currentCompany,
    noshareCompany1,
    noshareCompany2,
    noshareCompany3,
    advancedDegree,
    sector1,
    sector2,
    industry1,
    industry2,
    businessFunction1,
    businessFunction2,
    sector1years,
    sector2years,
    industry1years,
    industry2years,
    businessFunction1years,
    businessFunction2years,
    loading,
    conflictCompany1,
    conflictCompany2,
    conflictCompany3,
    respect,
    diversity,
    excellence,
    learning,
    humility,
    listening,
    communication,
    management,
    analysis,
    thinking,
    planning,
    collaborating,
    collaborating2,
    inspiration,
    book,
    color,
    hobby,
    animal,
    sport,
    fruit,
    actor,
    washingDishes,
    stuckTraffic,
    publicSpeaking,
    peopleLate,
    talktivePeople,
    gender,
    veteran,
    singleParent,
    specialChild,
    nonprofitCommitment,
    nonprofitCompany1,
    nonprofitCompany2,
    ethnicity,
    citizenCountry1,
    citizenCountry2,
    citizenCountry3,
    visaUSA,
    visaCanada,
    visaAUS,
    visaUK,
    visaEU,
    residenceCountry,
    livingCountry,
    knownLanguage1,
    knownLanguage2,
    knownLanguage3,
    knownLanguage4,
    knownLanguage5,
    expectedCurrency,
    expectedAmount,
    leadingEgtos,
    projectCertification,
    onsiteManager,
    onsitepercentageengagement,
    clientinterview,
    focusgroup,
    survey,
    refemail1,
    refemail2,
    refemail3,
    refemail4,
    taxIdentifcationNumber,
    taxIdentifcationDocument,
    preferedPayment,
    skilllist,
    selectedSkills
  } = state

  const handleChangeProcess = value => {
    setState(pre => ({ ...pre, process: value }))
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const handleChangeProcess1 = value => {
    // history.push("/project/create")
  }

  useEffect(() => {
    getDropDownLists()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const getDropDownLists = async () => {
    try {
      handleChange('loading', true)
      const country = await countryList()
      const business = await businessFunctionlist()
      const company = await companyList()
      const currency = await currencyList()
      const ethnicity = await ethnicityList()
      const gender = await genderList()
      const industry = await industryList()
      const referencesource = await referencesourceList()
      const sector = await sectorList()
      const skills = await skillList()
      handleChange('countrylist', country.data.result)
      handleChange('businesslist', business.data.result)
      handleChange('companylist', company.data.result)
      handleChange('currencylist', currency.data.result)
      handleChange('ethnicitylist', ethnicity.data.result)
      handleChange('genderlist', gender.data.result)
      handleChange('industrylist', industry.data.result)
      handleChange('referencesourcelist', referencesource.data.result)
      handleChange('sectorlist', sector.data.result)
      handleChange('skilllist', skills.data.result)
      handleChange('loading', false)
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error);
      // alert(error.message)
    }
  }

  const handleCreateExpert = async () => {
    try {
      // handleChangeProcess('process5')
      handleChange('loading', true)
      const _id = localStorage.getItem('_id')
      const payload = {
        _id,
        conflictCompany1,
        conflictCompany2,
        conflictCompany3,
        respect,
        diversity,
        excellence,
        learning,
        humility,
        listening,
        communication,
        management,
        analysis,
        thinking,
        planning,
        collaborating,
        collaborating2,
        inspiration,
        book,
        color,
        hobby,
        animal,
        sport,
        fruit,
        actor,
        washingDishes,
        stuckTraffic,
        publicSpeaking,
        peopleLate,
        talktivePeople,
        gender,
        veteran,
        singleParent,
        specialChild,
        nonprofitCommitment,
        nonprofitCompany1,
        nonprofitCompany2,
        ethnicity,
        citizenCountry1,
        citizenCountry2,
        citizenCountry3,
        visaUSA,
        visaCanada,
        visaAUS,
        visaUK,
        visaEU,
        residenceCountry,
        livingCountry,
        knownLanguage1,
        knownLanguage2,
        knownLanguage3,
        knownLanguage4,
        knownLanguage5,
        expectedCurrency,
        expectedAmount,
        leadingEgtos,
        projectCertification,
        onsiteManager,
        onsitepercentageengagement,
        clientinterview,
        focusgroup,
        survey,
        refemail1,
        refemail2,
        refemail3,
        refemail4,
        taxIdentifcationNumber,
        sector1,
        sector1years,
        sector2,
        sector2years,
        industry1,
        industry1years,
        industry2,
        industry2years,
        businessFunction1,
        businessFunction1years,
        businessFunction2,
        businessFunction2years,
        // taxIdentifcationDocument,
        preferedPayment,
        profileskills: selectedSkills,
        advancedDegree
      }
      const res = await upsertExpert(payload)
      if (res.data.result) {
        handleChange('loading', false)
        // const formData = new FormData()
        // formData.append('userID', _id)
        // formData.append('files', files[0].blobFile)
        // formData.append('userfiletype', 'expertresume')
        // const resType = await uploadfile(formData)
        handleChangeProcess('process5')
        // localStorage.removeItem('_id')
      } else {
        handleChange('loading', false)
        enqueueSnackbar(`Error: ${res.data.name}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }
    } catch (error) {
      handleChange('loading', false)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }

  return (
    <div>
      <SignupHeader />
      {process === 'process1' && (
        <Process1
          handleChange={handleChange}
          sector1years={sector1years}
          sector2years={sector2years}
          industry1years={industry1years}
          industry2years={industry2years}
          businessFunction1years={businessFunction1years}
          businessFunction2years={businessFunction2years}
          companylist={companylist}
          businesslist={businesslist}
          sectorlist={sectorlist}
          industrylist={industrylist}
          skilllist={skilllist}
          selectedSkills={selectedSkills}
          currentCompany={currentCompany}
          noshareCompany1={noshareCompany1}
          noshareCompany2={noshareCompany2}
          noshareCompany3={noshareCompany3}
          advancedDegree={advancedDegree}
          sector1={sector1}
          sector2={sector2}
          industry1={industry1}
          industry2={industry2}
          businessFunction1={businessFunction1}
          businessFunction2={businessFunction2}
          onClickButton={handleChangeProcess}
        />
      )}
      {process === 'process2' && (
        <Process2
          handleChange={handleChange}
          respect={respect}
          diversity={diversity}
          excellence={excellence}
          learning={learning}
          humility={humility}
          listening={listening}
          communication={communication}
          management={management}
          analysis={analysis}
          thinking={thinking}
          planning={planning}
          collaborating={collaborating}
          collaborating2={collaborating2}
          inspiration={inspiration}
          washingDishes={washingDishes}
          stuckTraffic={stuckTraffic}
          publicSpeaking={publicSpeaking}
          peopleLate={peopleLate}
          talktivePeople={talktivePeople}
          onClickButton={handleChangeProcess}
          conflictCompany1={conflictCompany1}
          conflictCompany2={conflictCompany2}
          conflictCompany3={conflictCompany3}
          book={book}
          color={color}
          hobby={hobby}
          animal={animal}
          sport={sport}
          fruit={fruit}
          actor={actor}
        />
      )}
      {process === 'process3' && (
        <Process3
          countrylist={countrylist}
          onClickButton={handleChangeProcess}
          handleChange={handleChange}
          gender={gender}
          veteran={veteran}
          singleParent={singleParent}
          specialChild={specialChild}
          nonprofitCommitment={nonprofitCommitment}
          nonprofitCompany1={nonprofitCompany1}
          nonprofitCompany2={nonprofitCompany2}
          ethnicity={ethnicity}
          citizenCountry1={citizenCountry1}
          citizenCountry2={citizenCountry2}
          citizenCountry3={citizenCountry3}
          visaUSA={visaUSA}
          visaCanada={visaCanada}
          visaAUS={visaAUS}
          visaUK={visaUK}
          visaEU={visaEU}
          residenceCountry={residenceCountry}
          livingCountry={livingCountry}
          knownLanguage1={knownLanguage1}
          knownLanguage2={knownLanguage2}
          knownLanguage3={knownLanguage3}
          knownLanguage4={knownLanguage4}
          knownLanguage5={knownLanguage5}
        />
      )}
      {process === 'process4' && (
        <Process4
          currencylist={currencylist}
          onClickButton={handleCreateExpert}
          loading={loading}
          projectCertification={projectCertification}
          onsitepercentageengagement={onsitepercentageengagement}
          onsiteManager={onsiteManager}
          survey={survey}
          focusgroup={focusgroup}
          clientinterview={clientinterview}
          preferedPayment={preferedPayment}
          leadingEgtos={leadingEgtos}
          handleChange={handleChange}
          expectedCurrency={expectedCurrency}
          expectedAmount={expectedAmount}
          refemail1={refemail1}
          refemail2={refemail2}
          refemail3={refemail3}
          refemail4={refemail4}
          taxIdentifcationNumber={taxIdentifcationNumber}
        />
      )}
      {process === 'process5' && (
        <Process5 onClickButton={handleChangeProcess} />
      )}
      {process === 'process6' && (
        <Process6 onClickButton={handleChangeProcess1} handleChangeProcess={handleChangeProcess} />
      )}

      <MainFooter />
    </div>
  )
}
