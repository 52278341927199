import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  SearchOutlined,
  ArrowForward,
  KeyboardArrowDown
} from '@mui/icons-material'
import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@mui/material'
import word from '../../assets/svg/word.svg'
import excel from '../../assets/svg/excel.svg'
import point from '../../assets/svg/point.svg'
import onenote from '../../assets/svg/onenote.svg'
import team from '../../assets/svg/team.svg'
import outlook from '../../assets/svg/outlook.svg'
import Sharepoint from '../../assets/svg/Sharepoint.svg'
import yammer from '../../assets/svg/yammer.svg'
import onedrive from '../../assets/svg/onedrive.svg'
import skype from '../../assets/svg/skype.svg'
import acrobat from '../../assets/svg/acrobat.svg'
import publisher from '../../assets/svg/publisher.svg'
import python from '../../assets/svg/python.svg'
import sql from '../../assets/svg/sql.svg'
import greyBox from '../../assets/svg/greyBox.svg'
import { useSnackbar } from 'notistack'
import { upsertprojectapps } from '../../api/projects'


const list = [
  { image: word, title: 'word' },
  { image: excel, title: 'excel' },
  { image: point, title: 'point' },
  { image: onenote, title: 'onenote' },
  { image: team, title: 'team' },
  { image: outlook, title: 'outlook' },
  { image: Sharepoint, title: 'Sharepoint' },
  { image: yammer, title: 'yammer' },
  { image: onedrive, title: 'onedrive' },
  { image: skype, title: 'skype' },
  { image: acrobat, title: 'acrobat' },
  { image: publisher, title: 'publisher' },
  { image: python, title: 'python' },
  { image: sql, title: 'sql' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' },
  { image: greyBox, title: '???' }
]

export default function Apps({ item }) {
  const [secondary, setSecondary] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()

  const [state, setState] = useState({
    appName: '',
    reason: '',

  })
  const {
    appName,
    reason,

  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }



  const handleApp = async () => {
    try {
      handleChange('loading', true)
      const payload = {
        _id: id,
        appName,
        reason,
          
        isrequested: true,
    
        status: "pending",
     
        type: "App"

      }
      const res = await upsertprojectapps(payload)
      if (res.data.result) {
        handleChange('loading', false)
        enqueueSnackbar('App Added Successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        
      } else {
        handleChange('loading', false)
        enqueueSnackbar(`Error: ${res.data.result.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }




  console.warn('item?.apps?', item?.apps);
  return (
    <>
      <section class='sec-4'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-12 mb-5'>
              <div class='content'>
                <div class='d-flex align-items-center justify-content-between'>
                  <div className='d-flex'></div>
                  <div class='searchInput'>
                    <input
                      type='text'
                      // onChange={(value) => handleChange('voucher', value.target.value)}
                      formcontrolname='text'
                      placeholder='Search'
                    />
                    <SearchOutlined />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='row'>
            {item.map((app,index) => (
              <div className='col-md-1 text-center'>
                <img src={word} alt={''} />
                <p className='mt-1 mb-5'>{app.appName}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className='sec-4 bg-grey'>
        <div class='container'>
          <div class='col-lg-6'>
            <h5 className={'text_green'}>Request a new app?</h5>
            <p className={'text_blue font-16'}>
              Need a new app that we don’t have integrated on to Egtos please
              let us know and we can look into adding it!
            </p>
            <div class='question_box mt-5'>
              <h5 className={'text_blue'}>Name of the app</h5>
              <div class='companyInput ml-0'>
                <div class='logingroup'>
                  <input
                    type='text'
                    onChange={(value) => handleChange('appName', value.target.value)}
                    placeholder='App Name'
                  />
                </div>
              </div>
            </div>
            <div class='question_box mt-5'>
              <h5 className={'text_blue mt-3'}>
                Please explain the reason why this app is needed for the
                project?
              </h5>
              <div class='companyInput ml-0'>
                <div class='logingroup'>
                  <textarea
                    type='text'
                    aria-multiline
                    onChange={(value) => handleChange('reason', value.target.value)}
                    placeholder='Reason...'
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => handleApp()}
            class={`btn btn-primary mt-5 rounded blue-btn`}
          >
            {'Request App'}
          </button>
        </div>
      </section>
    </>
  )
}
