import React, { useState } from 'react'
import { MainFooter, SignupHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import { resendConfirmationCode, verifyEmail } from '../../api/auth'
import { Loader } from 'rsuite'
import { useSnackbar } from 'notistack';

export default function EmailVerify() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const [state, setState] = useState({
        "code": "",
        "usertype": "expert",
        "loading": false
    })
    const { loading, code, usertype } = state;

    const handleChange = (key, value) => {
        setState(pre => ({ ...pre, [key]: value }))
    }


    const handleVerify = async () => {
        try {
            // history.push('/signup/email-confirm')
            handleChange('loading', true)
            const emailAddress = localStorage.getItem("email")
            const type = localStorage.getItem("type")
            const payload = {
                emailAddress, code, usertype: type
            }
            const res = await verifyEmail(payload)
            if (res.data.result) {
                handleChange('loading', false)
                localStorage.setItem("_id", res.data.result._id)
                // localStorage.removeItem("email")
                // localStorage.removeItem("type")
                enqueueSnackbar('Email Has Been Verified!', {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
                history.push('/signup/email-confirm')
            } else {
                handleChange('loading', false)
                enqueueSnackbar(`Error: ${res.data.name}`, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            }
        } catch (error) {
            handleChange('loading', false)
            // console.log('err', error);
            enqueueSnackbar(`Error: ${error.message}`, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }
    }

    const handleResendCode = async () => {
        try {
            handleChange('loading', true)
            const emailAddress = localStorage.getItem("email")
            const payload = {
                emailAddress
            }
            const res = await resendConfirmationCode(payload)
            if (res.data.result) {
                handleChange('loading', false)
                enqueueSnackbar('Code Has Been Sent!', {
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            } else {
                handleChange('loading', false)
                enqueueSnackbar(`Error: ${res.data.name}`, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                })
            }
        } catch (error) {
            handleChange('loading', false)
            // console.log('err', error);
            enqueueSnackbar(`Error: ${error.message}`, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            })
        }
    }

    const disabled = !code || loading
    return (
        <div>
            <SignupHeader />
            <section class="forgotOuter">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-8 col-sm-12">
                            <div class="login  col-lg-8 col-md-10 col-sm-12">
                                <div class="loginIn">
                                    <div class="loginPara">
                                        <h3>Email Verification</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="logingroup col-lg-8 col-md-10 col-sm-12">
                                <input type="text" onChange={(value) => handleChange('code', value.target.value)} placeholder="Enter Verification Code" />
                                <p onClick={() => handleResendCode()} className={"text-center mt-4 cursor-pointer"}>Didn't receive? resend code</p>
                            </div>
                            <div class="logingroup ml-minus mt-4 col-lg-8 col-md-10 col-sm-12">
                                <button
                                    onClick={() => handleVerify()}
                                    // disabled={disabled}
                                    // class={`btn ${disabled ? "btn-login-outline-warning" : "btn-login-outline-warning-active"}`}
                                    class={`btn ${"btn-outline-warning"}`}
                                >{loading ? <Loader /> : "Verify"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MainFooter />
        </div>
    )
}