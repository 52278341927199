import React, { useEffect, useState } from 'react'
import {
  businessFunctionlist,
  companyList,
  countryList,
  currencyList,
  ethnicityList,
  genderList,
  industryList,
  referencesourceList,
  sectorList,
  projectList
} from '../../api/auth'
import {
  MainFooter,
  ClientProcess1,
  ClientProcess2,
  SignupHeader
} from '../../components'
import { upsertClient, sendClientContract } from '../../api/client'
import { upsertProject } from '../../api/projects'
import { uploadProjectfile } from '../../api/auth'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'

export default function SignupClientProcess() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [stateProjectRoles, setStateProjectRoles] = useState({
    projectRoles: []
  })
  const [state, setState] = useState({
    videoWatched: false,
    taxnumber: '',
    companyDetails: [],
    voucher: '',
    companypayableEmail: '',
    projectName: '',
    projectClassification: 'Strategy Development',
    projectRFP: [],
    project: [],
    projectSupportdocs: [],
    budget: '',
    formalGoverance: false,
    onsiteManager: false,
    onsitepercentageengagement: '',
    isProjectManagerAssigned: false,
    expectStartDate1: '',
    expectStartDate2: '',
    expectStartDate3: '',
    expectedEndDate1: '',
    expectedEndDate2: '',
    expectedEndDate3: '',
    primarilyLanguageRequired: false,
    primarilyLanguage1: '',
    primarilyLanguage2: '',
    egtosTeamExpectation: '',
    sectorlist: [],
    industrylist: [],
    businesslist: [],
    sector: '',
    industry: '',
    businessfunction: '',
    projectRolesSelected: ['Agile SME (Project Leader)'],
    prescreening: false,
    files: null,
    fileRFP: null,
    filesupporting: null,
    process: 'process1'
  })

  const {
    process,
    taxnumber,
    companyDetails,
    voucher,
    companypayableEmail,
    projectName,
    projectClassification,
    projectRFP,
    projectSupportdocs,
    budget,
    formalGoverance,
    onsiteManager,
    onsitepercentageengagement,
    isProjectManagerAssigned,
    expectStartDate1,
    expectStartDate2,
    expectStartDate3,
    expectedEndDate1,
    expectedEndDate2,
    expectedEndDate3,
    primarilyLanguageRequired,
    primarilyLanguage1,
    primarilyLanguage2,
    egtosTeamExpectation,
    prescreening,
    files,
    fileRFP,
    filesupporting,
    project,
    projectRolesSelected,
    businesslist,
    industrylist,
    sectorlist,
    sector,
    industry,
    businessfunction
  } = state
  const { projectRoles } = stateProjectRoles

  const handleChangeProcess = value => {
    setState(pre => ({ ...pre, process: value }))
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    getDropDownLists()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleChangeProjectRoles = (key, value) => {
    setStateProjectRoles(pre => ({ ...pre, [key]: value }))
  }

  const getDropDownLists = async () => {
    try {
      handleChange('loading', true)
      const country = await countryList()
      const business = await businessFunctionlist()
      const project = await projectList()
      const company = await companyList()
      const currency = await currencyList()
      const ethnicity = await ethnicityList()
      const gender = await genderList()
      const industry = await industryList()
      const referencesource = await referencesourceList()
      const sector = await sectorList()
      handleChange('countrylist', country.data.result)
      handleChange('businesslist', business.data.result)
      handleChange('companylist', company.data.result)
      handleChange('currencylist', currency.data.result)
      handleChange('ethnicitylist', ethnicity.data.result)
      handleChange('genderlist', gender.data.result)
      handleChange('industrylist', industry.data.result)
      handleChange('referencesourcelist', referencesource.data.result)
      handleChange('sectorlist', sector.data.result)
      handleChange('project', project.data.result)
      handleChange('loading', false)
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error);
      // alert(error.message)
    }
  }

  const handleCreateClient = async () => {
    try {
      // history.push('/login')
      handleChange('loading', true)
      const rolesObject = {}
      projectRolesSelected?.forEach(element => {
        rolesObject[element] = 'Revamp'
      })
      const _id = localStorage.getItem('_id') || '6225e51f8662c89d15b93b1a'
      const payload = {
        _id: _id,
        videoWatched: true,
        taxnumber,
        companyDetails,
        voucher,
        companypayableEmail,
        projectName,

        projectType: projectClassification,
        projectRoles: [rolesObject],
        projectRFP,
        projectSupportdocs,
        budget,
        formalGoverance,
        onsiteManager,
        onsitepercentageengagement,
        isProjectManagerAssigned,
        expectStartDate: expectStartDate1,
        expectedEndDate: expectedEndDate1,
        primarilyLanguageRequired,
        primarilyLanguage1,
        primarilyLanguage2,
        egtosTeamExpectation,
        prescreening,
        sector,
        industry,
        businessFunction: businessfunction,
        projectClassification
      }
      const res = await upsertClient(payload)

      console.log('formData', files)
      if (res.data.result) {
        const projectpayload = payload;
        projectpayload.clientID = res.data.result._id
        delete projectpayload._id;
        const resproject = await upsertProject(payload)
        localStorage.setItem('projectid', resproject.data.result._id)
        if (resproject.data.result) {
          handleChange('loading', false)
          if (files) {
            const formData = new FormData()
            formData.append('ID', res.data.result._id)
            formData.append('ProjectID', resproject.data.result._id)
            formData.append('files', files[0].blobFile)
            formData.append('filetype', 'files')
            const resType = await uploadProjectfile(formData)
          }
          if (fileRFP) {
            handleChange('loading', true)
            const formData1 = new FormData()
            formData1.append('ID', res.data.result._id)
            formData1.append('ProjectID', resproject.data.result._id)
            formData1.append('files', fileRFP[0].blobFile)
            formData1.append('filetype', 'projectRFP')
            const resType2 = await uploadProjectfile(formData1)
            handleChange('loading', false)
          }
          if (filesupporting) {
            const formData2 = new FormData()
            formData2.append('ID', res.data.result._id)
            formData2.append('ProjectID', resproject.data.result._id)
            formData2.append('files', filesupporting[0].blobFile)
            formData2.append('filetype', 'projectSupportdocs')
            const resType1 = await uploadProjectfile(formData2)
          }
        }
        await sendClientContract({ _id: _id });
        handleChange('loading', false)
        history.push({
          pathname: '/project/team/options',
          state: {
            projectid: resproject.data.result._id,
            clientid: res.data.result._id || localStorage.getItem('_id')
          }
        });
        // localStorage.removeItem('_id')
      } else {
        handleChange('loading', false)
        enqueueSnackbar(`Error: ${res.data.name}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }
    } catch (error) {
      handleChange('loading', false)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }

  return (
    <div>
      <SignupHeader />
      {process === 'process1' && (
        <ClientProcess1
          handleChange={handleChange}
          taxnumber={taxnumber}
          voucher={voucher}
          onClickButton={handleChangeProcess}
        />
      )}
      {process === 'process2' && (
        <ClientProcess2
          onClickButton={handleCreateClient}
          handleChange={handleChange}
          expectStartDate1={expectStartDate1}
          expectedEndDate1={expectedEndDate1}
          companypayableEmail={companypayableEmail}
          projectName={projectName}
          projectClassification={projectClassification}
          projectRFP={projectRFP}
          projectSupportdocs={projectSupportdocs}
          budget={budget}
          formalGoverance={formalGoverance}
          onsiteManager={onsiteManager}
          onsitepercentageengagement={onsitepercentageengagement}
          isProjectManagerAssigned={isProjectManagerAssigned}
          primarilyLanguageRequired={primarilyLanguageRequired}
          primarilyLanguage1={primarilyLanguage1}
          primarilyLanguage2={primarilyLanguage2}
          egtosTeamExpectation={egtosTeamExpectation}
          prescreening={prescreening}
          project={project}
          sectorlist={sectorlist}
          sector={sector}
          industry={industry}
          businessfunction={businessfunction}
          businesslist={businesslist}
          industrylist={industrylist}
          projectRoles={projectRoles}
          projectRolesSelected={projectRolesSelected}
          fileRFP={fileRFP}
          handleChangeProjectRoles={handleChangeProjectRoles}
        />
      )}

      <MainFooter />
    </div>
  )
}
