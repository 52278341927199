/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Button,
  Typography,
  Box
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function Process1 ({
  onClickButton,
  companylist,
  businesslist,
  sectorlist,
  industrylist,
  sector1years,
  sector2years,
  handleChange,
  businessFunction2years,
  businessFunction1years,
  industry1years,
  industry2years,
  currentCompany,
  noshareCompany1,
  noshareCompany2,
  noshareCompany3,
  sector1,
  sector2,
  industry1,
  industry2,
  businessFunction1,
  businessFunction2,
  skilllist,
  selectedSkills,
  advancedDegree
}) {
  const dropdownRef = useRef(null)
  const [state, setState] = useState({
    required: false,
    showSkills: false,
    addCompany: '',
    searchInput: ''
  })
  const { required, addCompany, showSkills, searchInput } = state
  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleClose = () => {
    handleChangeLocal('showSkills', false)
  }
  const disabled =
    // !currentCompany ||
    // !noshareCompany1 ||
    // !noshareCompany2 ||
    // !noshareCompany3 ||
    !sector1 ||
    !sector2 ||
    !industry1 ||
    !industry2 ||
    !businessFunction1 ||
    !businessFunction2

  const _onClickButton = () => {
    if (disabled) {
      handleChangeLocal('required', true)
      return
    }
    handleChangeLocal('required', false)
    onClickButton('process2')
  }

  const filterIndustrylist = index => {
    if (index === 0) {
      const list = industrylist.filter(e => e.sector === sector1)
      return list
    } else {
      const list = industrylist.filter(e => e.sector === sector2)
      return list
    }
  }
  return (
    <section class='loginOuter ptm-0'>
      <div class='mb-5'>
        <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12 mh-0'>
          <div class='signupProgress hideWeb pb-5 pl-2 pt-5'>
            <div class='signupProgressIn'>
              <h4>Sign-up progress</h4>
              <div class='signProgStep'>
                <ul>
                  <li>
                    <a href='#' class='active'>
                      <span>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Experience
                    </a>
                    <a href='#'>
                      <span></span> Values
                    </a>
                    <a href='#'>
                      <span></span> About you
                    </a>
                    <a href='#'>
                      <span></span> Financial details
                    </a>
                    <a href='#'>
                      <span></span> Elevator pitch{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-6 col-md-8 col-sm-12'>
            <div class='login'>
              <div class='loginIn'>
                <div class='loginPara'>
                  <h3>Becoming an Egtos expert</h3>
                  <h4>Are you currently a Full-time employee of a company ?</h4>
                </div>

                <div class='question_box'>
                  <h5>If yes, which company do you currently work for?</h5>
                  <div class='select_wrap'>
                    <select
                      class='form-select'
                      ref={dropdownRef}
                      value={currentCompany}
                      onChange={value =>
                        handleChange('currentCompany', value.target.value)
                      }
                    >
                      <option value=''>
                        If yes, which company do you currently work for ?
                      </option>
                      {companylist?.map((company, index) => (
                        <option key={index} value={company?.companyName}>
                          {company?.companyName}
                        </option>
                      ))}
                    </select>

                    <button
                      type='button'
                      onClick={() => dropdownRef.current.focus()}
                    >
                      <i class='fas fa-arrow-down'></i>
                    </button>
                  </div>
                  {/* {required && !currentCompany && (
                    <div>
                      <small>Current Company is required !</small>
                    </div>
                  )} */}
                </div>

                {!currentCompany && (
                  <div class='question_box'>
                    <h5>
                      If no, please list up-to-three companies you wouldn't want
                      Egtos to share your profile with
                    </h5>
                    <div class='row'>
                      <div class='companyInput ml-3'>
                        <div class='logingroup'>
                          <input
                            type='text'
                            value={noshareCompany1}
                            onChange={value =>
                              handleChange(
                                'noshareCompany1',
                                value.target.value
                              )
                            }
                            placeholder='Company name 1'
                          />
                        </div>
                      </div>
                      <div class='displayMobileHide'>
                        <div class='loginPlus'>
                          <button
                            onClick={() =>
                              handleChangeLocal('addCompany', '2ndCompany')
                            }
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='30'
                              height='30'
                              viewBox='0 0 22 22'
                            >
                              <g
                                id='Group_560'
                                data-name='Group 560'
                                transform='translate(-16042 -761)'
                              >
                                <g
                                  id='Ellipse_4'
                                  data-name='Ellipse 4'
                                  transform='translate(16042 761)'
                                  fill='#fff'
                                  stroke='#707070'
                                  stroke-width='0.5'
                                >
                                  <circle
                                    cx='11'
                                    cy='11'
                                    r='11'
                                    stroke='none'
                                  />
                                  <circle
                                    cx='11'
                                    cy='11'
                                    r='10.75'
                                    fill='none'
                                  />
                                </g>
                                <text
                                  id='_'
                                  data-name='+'
                                  transform='translate(16049 777)'
                                  fill='#969696'
                                  font-size='14'
                                  font-family='OpenSans-Light, Open Sans'
                                  font-weight='300'
                                >
                                  <tspan x='0' y='0'>
                                    +
                                  </tspan>
                                </text>
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                      {/* {required && !noshareCompany1 && (
                      <div>
                        <small>Company1 is required !</small>
                      </div>
                    )} */}
                    </div>

                    {(addCompany === '2ndCompany' ||
                      addCompany === '3rdCompany') && (
                      <div class='row'>
                        <div class='companyInput ml-3'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={noshareCompany2}
                              onChange={value =>
                                handleChange(
                                  'noshareCompany2',
                                  value.target.value
                                )
                              }
                              placeholder='Company name 2'
                            />
                          </div>
                        </div>
                        <div class='displayMobileHide'>
                          <div class='loginPlus'>
                            <button
                              onClick={() =>
                                handleChangeLocal('addCompany', '3rdCompany')
                              }
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                        {/* {required && !noshareCompany2 && (
                        <div>
                          <small>Company2 is required !</small>
                        </div>
                      )} */}
                      </div>
                    )}

                    {addCompany === '3rdCompany' && (
                      <div class='row'>
                        <div class='companyInput ml-3'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={noshareCompany3}
                              onChange={value =>
                                handleChange(
                                  'noshareCompany3',
                                  value.target.value
                                )
                              }
                              placeholder='Company name 3'
                            />
                          </div>
                        </div>
                        <div class='displayMobileHide'>
                          <div class='loginPlus'>
                            <button>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {required && !noshareCompany3 && (
                    <div>
                      <small>Company3 is required !</small>
                    </div>
                  )} */}
                  </div>
                )}

                <div class='question_box'>
                  <h5>Add Skills</h5>
                  <div class=''>
                    <button
                      type='button'
                      class='btn btn-outline-secondary'
                      onClick={() => handleChangeLocal('showSkills', true)}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        viewBox='0 0 22 22'
                      >
                        <g
                          id='Group_560'
                          data-name='Group 560'
                          transform='translate(-16042 -761)'
                        >
                          <text
                            id='_'
                            data-name='+'
                            transform='translate(16049 777)'
                            fill='#969696'
                            font-size='14'
                            font-family='OpenSans-Light, Open Sans'
                            font-weight='300'
                          >
                            <tspan x='0' y='0'>
                              +
                            </tspan>
                          </text>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>

                <div class='question_box'>
                  <h5>Advanced Degree if any</h5>

                  <div class='row'>
                    <div class='companyInput ml-3'>
                      <div class='logingroup'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange('advancedDegree', value.target.value)
                          }
                          placeholder='Advanced Degree'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class='question_box'>
                  <h5>
                    From the list provided below, please select the top two
                    sectors you've spent most of your professional career in{' '}
                  </h5>

                  <div class='row'>
                    <div class='col-lg-9 col-md-9 col-sm-12'>
                      <label for=''>Sector 1</label>
                      <div class='select_wrap'>
                        <select
                          class='form-select'
                          value={sector1}
                          onChange={value =>
                            handleChange('sector1', value.target.value)
                          }
                        >
                          <option value=''>Sector</option>
                          {sectorlist?.map((sector, index) => (
                            <option key={index} value={sector?.sector}>
                              {sector?.sector}
                            </option>
                          ))}
                        </select>

                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !sector1 && (
                        <div>
                          <small>Sector1 is required !</small>
                        </div>
                      )}
                    </div>
                    <div class='col-lg-3 col-md-3 col-sm-12'>
                      <div class='sectorYear'>
                        <label for=''>Years</label>
                        <div class='row'>
                          <div class='col-md-7 sm-4'>
                            <div class='logingroup'>
                              <input
                                value={sector1years}
                                onChange={value =>
                                  handleChange(
                                    'sector1years',
                                    value.target.value
                                  )
                                }
                                type='text'
                                min='0'
                              />
                            </div>
                          </div>
                          <div class='col-md-5 sm-6 mtn-5'>
                            <div
                              class='btn-group'
                              role='group'
                              aria-label='Basic example'
                            >
                              <button
                                onClick={() =>
                                  handleChange(
                                    'sector1years',
                                    sector1years === 0 ? 0 : sector1years - 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-minus'></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleChange(
                                    'sector1years',
                                    Number(sector1years) + 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {required && !sector1years && (
                          <div>
                            <small>Sector1 year is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-lg-9 col-md-9 col-sm-12'>
                      <label for=''>Sector 2</label>
                      <div class='select_wrap'>
                        <select
                          class='form-select'
                          formControlName='sector2'
                          value={sector2}
                          onChange={value =>
                            handleChange('sector2', value.target.value)
                          }
                        >
                          <option value=''>Sector</option>
                          {sectorlist?.map((sector, index) => (
                            <option key={index} value={sector?.sector}>
                              {sector?.sector}
                            </option>
                          ))}
                        </select>

                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !sector2 && (
                        <div>
                          <small>Sector2 is required !</small>
                        </div>
                      )}
                    </div>
                    <div class='col-lg-3 col-md-3 col-sm-12'>
                      <div class='sectorYear'>
                        <label for=''>Years</label>
                        <div class='row'>
                          <div class='col-md-7 col-sm-6 sm-4'>
                            <div class='logingroup'>
                              <input
                                value={sector2years}
                                onChange={value =>
                                  handleChange(
                                    'sector2years',
                                    value.target.value
                                  )
                                }
                                type='text'
                                min='0'
                              />
                            </div>
                          </div>
                          <div class='col-md-5 col-sm-6 sm-6 mtn-5'>
                            <div
                              class='btn-group'
                              role='group'
                              aria-label='Basic example'
                            >
                              <button
                                onClick={() =>
                                  handleChange(
                                    'sector2years',
                                    sector2years === 0 ? 0 : sector2years - 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-minus'></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleChange(
                                    'sector2years',
                                    Number(sector2years) + 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {required && !sector2years && (
                          <div>
                            <small>Sector2 year is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div class='question_box'>
                  <h5>
                    Based on the two top sectors you listed above, please select
                    the two corresponding industries per sector from the list
                    below:{' '}
                  </h5>

                  <div class='row'>
                    <div class='col-lg-9 col-md-9 col-sm-12'>
                      <label for=''>Industry 1</label>
                      <div class='select_wrap'>
                        <select
                          class='form-select'
                          formControlName='industry1'
                          value={industry1}
                          onChange={value =>
                            handleChange('industry1', value.target.value)
                          }
                        >
                          <option value=''>Industry</option>
                          {filterIndustrylist(0)?.map((industry, index) => (
                            <option key={index} value={industry?.industry}>
                              {industry?.industry}
                            </option>
                          ))}
                        </select>

                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !industry1 && (
                        <div>
                          <small>Industry1 is required !</small>
                        </div>
                      )}
                    </div>
                    <div class='col-lg-3 col-md-3 col-sm-12'>
                      <div class='sectorYear'>
                        <label for=''>Years</label>
                        <div class='row'>
                          <div class='col-md-7 sm-4'>
                            <div class='logingroup'>
                              <input
                                value={industry1years}
                                onChange={value =>
                                  handleChange(
                                    'industry1years',
                                    value.target.value
                                  )
                                }
                                type='text'
                                min='0'
                              />
                            </div>
                          </div>
                          <div class='col-md-5 sm-6 mtn-5'>
                            <div
                              class='btn-group'
                              role='group'
                              aria-label='Basic example'
                            >
                              <button
                                onClick={() =>
                                  handleChange(
                                    'industry1years',
                                    industry1years === 0
                                      ? 0
                                      : industry1years - 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-minus'></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleChange(
                                    'industry1years',
                                    industry1years + 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {required && !industry1years && (
                          <div>
                            <small>Industry1 year is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-lg-9 col-md-9 col-sm-12'>
                      <label for=''>Industry 2</label>
                      <div class='select_wrap'>
                        <select
                          class='form-select'
                          formControlName='industry2'
                          value={industry2}
                          onChange={value =>
                            handleChange('industry2', value.target.value)
                          }
                        >
                          <option value=''>Industry</option>
                          {filterIndustrylist(1)?.map((industry, index) => (
                            <option key={index} value={industry?.industry}>
                              {industry?.industry}
                            </option>
                          ))}
                        </select>

                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !industry2 && (
                        <div>
                          <small>Industry2 is required !</small>
                        </div>
                      )}
                    </div>
                    <div class='col-lg-3 col-md-3 col-sm-12'>
                      <div class='sectorYear'>
                        <label for=''>Years</label>
                        <div class='row'>
                          <div class='col-md-7 sm-4'>
                            <div class='logingroup'>
                              <input
                                value={industry2years}
                                onChange={value =>
                                  handleChange(
                                    'industry2years',
                                    value.target.value
                                  )
                                }
                                type='text'
                                min='0'
                              />
                            </div>
                          </div>
                          <div class='col-md-5 sm-6 mtn-5'>
                            <div
                              class='btn-group'
                              role='group'
                              aria-label='Basic example'
                            >
                              <button
                                onClick={() =>
                                  handleChange(
                                    'industry2years',
                                    industry2years === 0
                                      ? 0
                                      : industry2years - 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-minus'></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleChange(
                                    'industry2years',
                                    Number(industry2years) + 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {required && !industry2years && (
                          <div>
                            <small>Industry2 year is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div class='question_box'>
                  <h5>
                    From the list provided below, please selet the two top
                    business functions where you spent most of your time during
                    the course of your career:{' '}
                  </h5>

                  <div class='row'>
                    <div class='col-lg-9 col-md-9 col-sm-12'>
                      <label for=''>Business function 1</label>
                      <div class='select_wrap'>
                        <select
                          class='form-select'
                          value={businessFunction1}
                          onChange={value =>
                            handleChange(
                              'businessFunction1',
                              value.target.value
                            )
                          }
                        >
                          <option value=''>Function</option>
                          {businesslist?.map((business, index) => (
                            <option
                              key={index}
                              value={business?.businessFunction}
                            >
                              {business?.businessFunction}
                            </option>
                          ))}
                        </select>

                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !businessFunction1 && (
                        <div>
                          <small>Business function1 is required !</small>
                        </div>
                      )}
                    </div>
                    <div class='col-lg-3 col-md-3 col-sm-12'>
                      <div class='sectorYear'>
                        <label for=''>Years</label>
                        <div class='row'>
                          <div class='col-md-7 sm-4'>
                            <div class='logingroup'>
                              <input
                                value={businessFunction1years}
                                onChange={value =>
                                  handleChange(
                                    'businessFunction1years',
                                    value.target.value
                                  )
                                }
                                type='text'
                                min='0'
                              />
                            </div>
                          </div>
                          <div class='col-md-5 sm-6 mtn-5'>
                            <div
                              class='btn-group'
                              role='group'
                              aria-label='Basic example'
                            >
                              <button
                                onClick={() =>
                                  handleChange(
                                    'businessFunction1years',
                                    businessFunction1years === 0
                                      ? 0
                                      : businessFunction1years - 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-minus'></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleChange(
                                    'businessFunction1years',
                                    Number(businessFunction1years) + 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {required && !businessFunction1years && (
                          <div>
                            <small>Business function1 year is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-lg-9 col-md-9 col-sm-12'>
                      <label for=''>Business function 2</label>
                      <div class='select_wrap'>
                        <select
                          class='form-select'
                          value={businessFunction2}
                          onChange={value =>
                            handleChange(
                              'businessFunction2',
                              value.target.value
                            )
                          }
                        >
                          <option value=''>Function</option>
                          {businesslist?.map((business, index) => (
                            <option
                              key={index}
                              value={business?.businessFunction}
                            >
                              {business?.businessFunction}
                            </option>
                          ))}
                        </select>

                        <button type='button'>
                          <i class='fas fa-arrow-down'></i>
                        </button>
                      </div>
                      {required && !businessFunction2 && (
                        <div>
                          <small>Business function2 is required !</small>
                        </div>
                      )}
                    </div>
                    <div class='col-lg-3 col-md-3 col-sm-12'>
                      <div class='sectorYear'>
                        <label for=''>Years</label>
                        <div class='row'>
                          <div class='col-md-7 sm-4'>
                            <div class='logingroup'>
                              <input
                                value={businessFunction2years}
                                onChange={value =>
                                  handleChange(
                                    'businessFunction2years',
                                    value.target.value
                                  )
                                }
                                type='text'
                                min='0'
                              />
                            </div>
                          </div>
                          <div class='col-md-5 sm-6 mtn-5'>
                            <div
                              class='btn-group'
                              role='group'
                              aria-label='Basic example'
                            >
                              <button
                                onClick={() =>
                                  handleChange(
                                    'businessFunction2years',
                                    businessFunction2years === 0
                                      ? 0
                                      : businessFunction2years - 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-minus'></i>
                              </button>
                              <button
                                onClick={() =>
                                  handleChange(
                                    'businessFunction2years',
                                    Number(businessFunction2years) + 1
                                  )
                                }
                                type='button'
                                class='btn btn-primary'
                              >
                                <i class='fas fa-plus'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {required && !businessFunction2years && (
                          <div>
                            <small>Business function2 year is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='logingroup mt-5'>
                  <button
                    onClick={() => _onClickButton()}
                    class='btn btn-outline-warning'
                  >
                    Save and move on
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12'>
            <div class='signupProgress'>
              <div class='signupProgressIn'>
                <h4>Sign-up progress</h4>
                <div class='signProgStep'>
                  <ul>
                    <li>
                      <a href='#' class='active'>
                        <span></span> Experience
                      </a>
                      <a href='#'>
                        <span></span> Values
                      </a>
                      <a href='#'>
                        <span></span> About you
                      </a>
                      <a href='#'>
                        <span></span> Financial details
                      </a>
                      <a href='#'>
                        <span></span> Elevator pitch{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        maxWidth={'md'}
        style={{ minwidth: '100%' }}
        classes={{ paper: 'skillDialogue' }}
        aria-labelledby='customized-dialog-title'
        open={showSkills}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
        >
          <div className='d-flex'>
            <p className='font-24 font-weight-bold'>Add Skills</p>
            <div style={{ width: '50%' }} class='logingroup ml-4'>
              <input
                value={searchInput}
                onChange={value => {
                  handleChangeLocal('searchInput', value.target.value)
                }}
                type='text'
                min='0'
              />
            </div>
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box>
            <p className='font-24 mb-4'>Category One</p>
            {skilllist
              ?.filter(chatIdKey => {
                if (searchInput === '') {
                  //if query is empty
                  return chatIdKey
                } else if (
                  chatIdKey?.skill?.includes(searchInput.toLowerCase())
                ) {
                  //returns filtered array
                  return chatIdKey
                }
              })
              .map((skill, index) => {
                if (index < 12) {
                  return (
                    <Chip
                      key={index}
                      onClick={() => {
                        if (!selectedSkills?.includes(skill?.skill)) {
                          handleChange('selectedSkills', [
                            ...selectedSkills,
                            skill?.skill
                          ])
                        } else {
                          selectedSkills?.forEach((element, index) => {
                            if (element === skill?.skill) {
                              selectedSkills?.splice(index, 1)
                            }
                          })
                          handleChange('selectedSkills', [...selectedSkills])
                        }
                      }}
                      color={'primary'}
                      // onDelete={() => {
                      //   selectedSkills?.forEach((element, index) => {
                      //     if (element === skill?.skill) {
                      //       selectedSkills?.splice(index, 1)
                      //     }
                      //   })
                      //   handleChange('selectedSkills', [...selectedSkills])
                      // }}
                      variant={
                        selectedSkills?.includes(skill?.skill)
                          ? 'filled'
                          : 'outlined'
                      }
                      label={skill?.skill}
                      className='mr-2 mb-2 skillsChip'
                    />
                  )
                }
              })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </section>
  )
}
