import React, { useEffect } from 'react'
import {
  MainFooter,
  MainHeader,
  Brief,
  Messages,
  Files,
  Apps,
  Notifications,
  Team,
  Overview
} from '../../components'
import IBM from '../../assets/svg/IBM.svg'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import {
  getProject,
  getprojectapps,
  getprojectfiles,
  getprojectmessages,
  getprojectnotifications,
  getSingleProject,
  upsertexpertfeedback
} from '../../api/projects'
import { useParams } from 'react-router-dom'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function MyProjectHome({ }) {
  const { id } = useParams()
  const [value, setValue] = React.useState(0)
  const [state, setState] = React.useState({
    loading: false,
    projectList: [],
    project:{},
    filesList: [],
    messagesList: [],
    notificationsList: [],
    appsList: []
  })

  const {
    loading,
    projectList,
    project,
    filesList,
    messagesList,
    notificationsList,
    appsList
  } = state

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeState = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const _getProject = async () => {
    try {
      handleChangeState('loading', true)
      const body = { '_id': id }
      const resProject = await getSingleProject(body)
      // const resfiles = await getprojectfiles(body)
      // const resnotifications = await getprojectnotifications(body)
      // const resmessages = await getprojectmessages(body)
      // const resapps = await getprojectapps(body)
      handleChangeState('project', resProject.data?.result?.[0])
      // handleChangeState('filesList', resfiles.data.result)
      // handleChangeState('messagesList', resmessages.data.result)
      // handleChangeState('notificationsList', resnotifications.data.result)
      // handleChangeState('appsList', resapps.data.result)
      handleChangeState('loading', false)
    } catch (error) {
      handleChangeState('loading', false)
      // console.log('err', error)
      // alert(error.message)
    }
  }

  useEffect(() => {
    _getProject()
  }, [])

  var scroll = Scroll.animateScroll
  return (
    <div>
      <MainHeader />
      {/* <!--section 4 start--> */}
      <Element name='Element3'>
        <section class='sec-4 bg-grey'>
          <div class='container'>
            <div class='row'>
              <div class='col-md-6'>
                <div class='content'>
                  <h1 class='heading_font heading_font_black'>
                    {project.projectName}
                  </h1>
                </div>
              </div>

              <div class='col-md-6 text-right'>
                <div class='content'>
                  <img src={IBM} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class='sec-4 bg-grey padding-bottom'>
          <div class='container'>
            <Tabs
              value={value}
              indicatorColor='white'
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                label='Brief'
                {...a11yProps(0)}
              />
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                icon={
                  <div className={value === 1 ? 'activebadge' : 'badge'}>
                    {notificationsList?.length &&
                      notificationsList[0]?.notifications?.length}
                  </div>
                }
                iconPosition='end'
                label='Notifications'
                {...a11yProps(1)}
              />
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                label='Files'
                {...a11yProps(2)}
              />
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                label='Apps'
                {...a11yProps(3)}
              />
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                label='Messages'
                icon={
                  <div className={value === 4 ? 'activebadge' : 'badge'}>
                    {messagesList?.length && messagesList[0].messages?.length}
                  </div>
                }
                iconPosition='end'
                {...a11yProps(4)}
              />
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                label='Team'
                {...a11yProps(5)}
              />
              <Tab
                className='antTabsRoot'
                classes={{ selected: 'selected', root: 'unselected' }}
                label='Overview'
                {...a11yProps(6)}
              />
            </Tabs>
          </div>
        </section>
      </Element>
      <TabPanel value={value} index={0}>
        <Brief item={project} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Notifications
          item={project?.notificationsList}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Files item={project?.files} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Apps item={project?.apps} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Messages item={project?.messages} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Team item={project?.teams} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Overview item={project}/>
      </TabPanel>

      <MainFooter />
    </div>
  )
}
