import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../../assets/img/logo.png"
import menu_icon from "../../assets/img/menu_icon.png"
import { Drawer } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

export default function SignupHeader() {
    const [state, setState] = useState({
        visible: false
    })
    const showDrawer = () => {
        setState(pre => ({
            ...pre,
            visible: true,
        }));
    };

    const onClose = () => {
        setState(pre => ({
            ...pre,
            visible: false,
        }));
    };
    const { visible } = state;
    return (
        <div>
            <header class="login_nav">
                <div class="greenTopbg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="greenTop">
                                    <ul className='login_btn'>
                                        <li><Link to={"/become-expert"} class="create_account_btn">Create Account</Link></li>
                                        <li class="login_btn"><Link to={"/login"} class="login_btn">Login <i class="border-round far fa-user"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="custom_login_nav join_nav home_nav_bg">
                    <div class="container">
                        <Drawer
                            placement={'left'}
                            size={'xs'}
                            onClose={onClose}
                            open={visible}
                            key={'left'}
                        >
                            <Drawer.Header>
                                <Drawer.Title>Egtos</Drawer.Title>
                                <Drawer.Actions>
                                </Drawer.Actions>
                            </Drawer.Header>
                            <Drawer.Body>

                                <p><Link to={"/insight"}>Insights</Link></p>
                                <p><Link to={"/explore"}>Careers</Link></p>
                                <p><Link to={"/shipping"}>About Us</Link></p>
                            </Drawer.Body>
                        </Drawer>
                        <nav class="navbar navbar-expand-lg navbar-light widthfull">
                            <Link class="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
                            <button onClick={showDrawer} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <i style={{ color: '#fff' }} class="menu-icon fal fa-bars"></i>
                            </button>
                            <div class="collapse navbar-collapse align-items-center justify-content-end" id="navbarNav">
                                <ul class="navbar-nav d-flex align-items-center">
                                    <li class="nav-item ">
                                        <a class="nav-link active" href="#">Join</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" href="#">NavItem2</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" href="#">NavItem3</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">NavItem4</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">NavItem5</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">NavItem6</a>
                                    </li>
                                    <li class="ml-3">
                                        <a class="ham_button"><img src={menu_icon} alt="" /></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="clearfix"></div>
                        </nav>

                    </div>
                </div>
            </header>
            {/* <!--header end here--> */}
        </div>
    )
}