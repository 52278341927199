import React from 'react'
import { MainFooter, MainHeader, Brief, Messages, Files, Apps, Notifications, Team, Deliverables } from '../../components'
import IBM from '../../assets/svg/IBM.svg'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Scroll, {
    Element,
} from "react-scroll";
import './index.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function ExpertProject({ }) {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getProject = async () => {
        try {
            handleChange('loading', true)
            // const country = await countryList()

            // handleChange('countrylist', country.data.result)
            handleChange('loading', false)
        } catch (error) {
            handleChange('loading', false)
            // console.log('err', error);
            // alert(error.message)
        }
    }

    var scroll = Scroll.animateScroll;
    return (
        <div>
            <MainHeader />
            {/* <!--section 4 start--> */}
            <Element name="Element3">
                <section class="sec-4 bg-grey">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="content">
                                    <h1 class="heading_font heading_font_black">Project title 1</h1>
                                </div>
                            </div>

                            <div class="col-md-6 text-right">
                                <div class="content">
                                    <img src={IBM} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sec-4 bg-grey padding-bottom">
                    <div class="container">
                        <Tabs value={value}
                            indicatorColor="white"
                            onChange={handleChange} aria-label="basic tabs example">
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} label="Brief" {...a11yProps(0)} />
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} icon={<div className='badge'>1</div>} iconPosition="end" label="Notifications" {...a11yProps(1)} />
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} label="Files" {...a11yProps(2)} />
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} label="Apps" {...a11yProps(3)} />
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} icon={<div className='badge'>2</div>} iconPosition="end" label="Messages" {...a11yProps(4)} />
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} label="Team" {...a11yProps(5)} />
                            <Tab className='antTabsRoot' classes={{ selected: "selected" }} label="Deliverables" {...a11yProps(6)} />
                        </Tabs>
                    </div>
                </section>
            </Element>
            <TabPanel value={value} index={0}>
                <Brief />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Notifications />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Files />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Apps />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Messages />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <Team />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Deliverables />
            </TabPanel>




            <MainFooter />
        </div >
    )
}