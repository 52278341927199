import React, { createRef, useState } from 'react'
import { MainFooter, HomeHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import { signupUser, uploadfile } from '../../api/auth'
import { Loader, Uploader } from 'rsuite'
import { useSnackbar } from 'notistack'
import { isEmail, isPassword } from '../../utils/utilities'
import PasswordStrengthBar from 'react-password-strength-bar'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function Signup () {
  const history = useHistory()
  const usertype = localStorage.getItem('type')
  const { enqueueSnackbar } = useSnackbar()
  let openSelect = createRef()
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    referenceSource: '',
    phoneNumber: '',
    linkedinPublicUrl: '',
    password: '',
    confirmPassword: '',
    loading: false,
    invalidConfirmPassword: false,
    invalidEmail: false,
    invalidPassword: false,
    invalidPhone: false,
    files: null,
    required: false
  })
  const {
    loading,
    required,
    firstName,
    lastName,
    emailAddress,
    referenceSource,
    phoneNumber,
    linkedinPublicUrl,
    password,
    confirmPassword,
    invalidConfirmPassword,
    invalidEmail,
    invalidPassword,
    invalidPhone,
    files
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const disabled =
    !firstName ||
    !lastName ||
    !emailAddress ||
    !referenceSource ||
    !phoneNumber ||
    // !linkedinPublicUrl ||
    !password ||
    !confirmPassword ||
    (usertype !== 'client' && !files) ||
    password !== confirmPassword
  const handleSignup = async () => {
    try {
      if (disabled) {
        handleChange('required', true)
        return
      }
      // history.push('/signup/email-verification')
      handleChange('required', false)
      handleChange('loading', true)
      // const usertype = localStorage.getItem('type')
      const payload = {
        firstName,
        lastName,
        emailAddress,
        referenceSource,
        phoneNumber,
        linkedinPublicUrl,
        password,
        usertype
      }
      const res = await signupUser(payload)
      console.log('res',res);
      if (res.data.statusCode === 200) {
        handleChange('loading', false)
        enqueueSnackbar(`Signup Successfully!`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        // const body = {
        //   files,
        //   userID: res.data.result._id,
        //   userfiletype: 'expertresume'
        // }
        if (usertype !== 'client') {
          const formData = new FormData()
          formData.append('userID', res.data.result._id)
          formData.append('files', files[0].blobFile)
          formData.append('userfiletype', 'expertresume')
          const resType = await uploadfile(formData)
        }
        localStorage.setItem('_id', res.data.result._id)
        localStorage.setItem('email', emailAddress)
        history.push('/signup/email-verification')
      } else {
        handleChange('loading', false)
        console.log('ressss',res);
        enqueueSnackbar(`Error: ${res.data.name}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }
    } catch (error) {
      handleChange('loading', false)
      console.log('errssssssss', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    }
  }

  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      <section class='top_banner signBG'>
        <div class='container height100 d-flex align-items-center mobileMT-0'>
          <div class='banner_content'>
            <h1 className='text-left font-80-home'>
              {usertype === 'client'
                ? 'We value your business'
                : 'Let’s build something great together'}
            </h1>
            <p className={'width80 homeSubText1 mtm-3'}>
              {usertype === 'client'
                ? 'egtos was developed to help organizations scale, fast. We have streamlined the procurement process to as little as 3 days with carefully selected global teams of experts ready to hit the ground running on day one'
                : 'We heard you and developed a platform to enable true work-life-balance without compromising on income. egtos is truly future of work and we are excited to embark on this adventure together.'}
              {/* Our success formula is simple: you + egtos IP = client success.<br /><br /> Our clients appreciate the comprehensive nature of our process and we thank you for taking the necessary time to complete the process. */}
            </p>
          </div>
        </div>
      </section>
      <section class='loginOuter'>
        <div class='container'>
          <div class='row'>
            <div class='col-lg-8 col-md-12 col-sm-12'>
              <div class='login'>
                <div class='loginPara'>
                  <h3 className='lineHight'>
                    {usertype === 'client'
                      ? 'Becoming egtos client'
                      : 'Becoming a member of the egtos community'}
                  </h3>
                  <p>
                    {usertype === 'client'
                      ? `To ensure secure and efficient verification process, only
                    corporate email address and valid mobile/cell phone numbers
                    are accepted in our system`
                      : 'Welcome and thank you for the opportunity to partner and help egtos clients benefit from the value of teams of experts on demand. Our success formula is simple: you + egtos IP = client success.'}
                  </p>
                  <p className={'mt-3 mb-5'}>
                    {usertype === 'client'
                      ? `We thank you in advance for your cooperation and future
                    partnership.`
                      : 'Our clients appreciate the comprehensive nature of our process and we thank you for taking the necessary time to complete the process.'}
                  </p>
                </div>
                <div class='loginIn'>
                  <div class='row mobilemb'>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange('firstName', value.target.value)
                          }
                          placeholder='First Name'
                        />
                        {required && !firstName && (
                          <div>
                            <small> First Name is required!</small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class='col-sm-6'>
                      <div class='logingroup inputMargin'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange('lastName', value.target.value)
                          }
                          placeholder='Last Name'
                        />
                        {required && !lastName && (
                          <div>
                            <small> Last Name is required!</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='row '>
                    <div class='col-sm-6'>
                      <div class='logingroup'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange('emailAddress', value.target.value)
                          }
                          placeholder='Email Address'
                        />
                        {emailAddress && isEmail(emailAddress) && (
                          <div>
                            <small>
                              {' '}
                              Email must be a valid email address !
                            </small>
                          </div>
                        )}
                        {required && !emailAddress && (
                          <div>
                            <small>Email Address is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class='col-sm-6'>
                      <div class='logingroup'>
                        <PhoneInput
                          country={'us'}
                          inputStyle={{ width: '100%' }}
                          value={phoneNumber}
                          onChange={value => handleChange('phoneNumber', value)}
                        />
                        {/* <input type="number" placeholder="Phone Number" /> */}
                        {invalidPhone && (
                          <div>
                            <small>Phone Number is not valid !</small>
                          </div>
                        )}
                        {required && !phoneNumber && (
                          <div>
                            <small>Phone Number is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='row'>
                    <div class='col-sm-6'>
                      <div class='logingroup'>
                        <input
                          type='password'
                          onChange={value =>
                            handleChange('password', value.target.value)
                          }
                          placeholder='Password'
                        />
                        {password && isPassword(password) && (
                          <div>
                            {/* <small >passWord is required</small> */}
                            <small>
                              Must be at least 8 characters long and include
                              upper and lowercase letters and at least one
                              number
                            </small>
                          </div>
                        )}
                        {required && !password && (
                          <div>
                            <small>password is required !</small>
                          </div>
                        )}
                        <PasswordStrengthBar
                          style={{ marginTop: 10 }}
                          password={password}
                        />
                      </div>
                    </div>
                    <div class='col-sm-6'>
                      <div class='logingroup'>
                        <input
                          type='password'
                          onChange={value =>
                            handleChange('confirmPassword', value.target.value)
                          }
                          placeholder='Confirm Password'
                        />
                        {confirmPassword && password !== confirmPassword && (
                          <div>
                            <small>Passwords must match !</small>
                          </div>
                        )}
                        {confirmPassword && isPassword(confirmPassword) && (
                          <div>
                            <small>
                              Must be at least 8 characters long and include
                              upper and lowercase letters and at least one
                              number !
                            </small>
                          </div>
                        )}
                        {required && !confirmPassword && (
                          <div>
                            <small>Confirm password is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {usertype === 'client' ? null : (
                    <>
                      <div class='logingroup'>
                        <div class='logingroupUpload'>
                          <div class='upload-btn-wrapper'>
                            <Uploader
                              listType='picture-text'
                              accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                              action='//jsonplaceholder.typicode.com/posts/'
                              onChange={value => handleChange('files', value)}
                              renderFileInfo={(file, fileElement) => {
                                return (
                                  <div>
                                    <span>File Name: {file.name}</span>
                                    <p style={{ marginLeft: 13 }}>
                                      File type: {file.blobFile.type}
                                    </p>
                                  </div>
                                )
                              }}
                            >
                              <button class='btn'>
                                Upload your resume / CV
                              </button>
                            </Uploader>
                            {/* <input type="file" name="myfile" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" /> */}
                          </div>

                          <span>File formates: Word</span>
                        </div>
                        {required && !files && (
                          <div className='mt-1'>
                            <small>Resume is required !</small>
                          </div>
                        )}
                      </div>

                      <div class='logingroup'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange(
                              'linkedinPublicUrl',
                              value.target.value
                            )
                          }
                          placeholder='Linkedin public profile link'
                        />
                        {required && !linkedinPublicUrl && (
                          <div>
                            <small>Linkedin public profile is required !</small>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div class='logingroup'>
                    <div class='row'>
                      <div class='col-lg-6 col-md-6 col-sm-12'>
                        <div class='logingroupIn'>
                          <div class='select_wrap'>
                            <select
                              ref={openSelect}
                              id={'Select1'}
                              class='form-select'
                              onChange={value =>
                                handleChange(
                                  'referenceSource',
                                  value.target.value
                                )
                              }
                            >
                              <option value=''>
                                How did you hear about Egtos?
                              </option>
                              <option value={'Email from egtos'}>
                                Email from egtos
                              </option>
                              <option value={'Google'}>Google</option>
                              <option value={'In Person Event'}>
                                In Person Event
                              </option>
                              <option value={'In the News'}>In the News</option>
                              <option value={'LinkedIn'}>LinkedIn</option>
                              <option value={'Referral'}>Referral</option>
                              <option value={'Twitter'}>Twitter</option>
                              <option value={'Webinar'}>Webinar</option>
                            </select>
                            <button
                              onClick={() =>
                                document.getElementById('Select1').click()
                              }
                              type='button'
                            >
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                        </div>
                        {required && !referenceSource && (
                          <div style={{ marginTop: -15 }}>
                            <small>Reference Source is required !</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class='logingroup m-0 col-lg-8 col-md-10 col-sm-12 ph-0'>
                    <button
                      onClick={() => handleSignup()}
                      // disabled={disabled}
                      // class={`btn ${disabled ? "btn-outline-warning" : "btn-outline-warning"}`}
                      class={`btn ${'btn-outline-warning'}`}
                    >
                      {loading ? <Loader /> : 'Verify email address'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
    </div>
  )
}
