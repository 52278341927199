import React from 'react'
import { MainFooter, HomeHeader, HomeFooter } from '../../components'
import PortImage from '../../assets/img/port_img.png'
import insight3 from '../../assets/img/insight3.png'
import insight4 from '../../assets/img/insight4.png'
import insight5 from '../../assets/img/insight5.png'
import green_energey from '../../assets/img/green_energey.png'
import Scroll, { Link, Element } from 'react-scroll'
import './index.css'

export default function Shipping ({}) {
  var scroll = Scroll.animateScroll
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      {/* <!-- banner section start --> */}
      <Element name='Element1' className='element'>
        <section class='top_banner shipping'>
          <div class='container height100 d-flex align-items-center mt-5'>
            <div class='banner_content'>
              <h1 className='font-80-home'>
                Shipping{' '}
                <span className='displayMobileHide font-80-home'>Impact</span>
              </h1>
              <p className={'text-white shippingsubText displayMobileHide'}>
                Conserf erovite ratibeatur aut asitium et eossita taspis aut
                eium experro videlentiae. Sum fuga. Conserf erovite ratibeatur
                aut asitium et eossita taspis aut eium experro videlentiae. Sum
                fuga.
              </p>
              <p className={'text-white subText displayMobileHide'}>
                Ratibeatur aut asitium et eossita taspis aut eium experro
                videlentiae. Sum fuga.
              </p>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      {/* <!-- offer section end --> */}

      {/* <!--section 4 start--> */}
      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container d-flex'>
            <div className='col-md-2 displayMobileHide'>
              <div className='social-share'>
                <div className='round-social'>
                  <i class='text_grey fab fa-facebook-f'></i>
                </div>
                <div className='round-social'>
                  <i class='text_grey fab fa-twitter'></i>
                </div>
                <div className='round-social'>
                  <i class='text_grey fab fa-linkedin-in'></i>
                </div>
                <div className='round-social'>
                  <i class='text_grey fab fa-pinterest-p'></i>
                </div>
                <div className={'vline'} />
                <div className='shareRotate'>share</div>
              </div>
            </div>
            <div className='col-md-8'>
              <h1 class='heading_font heading_font_black font-60'>
                Title goes here
              </h1>
              <div className='row justify-content-between'>
                <div class='loginGenderRadio ml-3 mb-4'>
                  <div class='form-check'>
                    <input
                      class='form-check-input'
                      type='radio'
                      //   checked={respect === '1'}
                      //   onChange={() => handleChange('respect', '1')}
                      value='option1'
                    />
                    <p className='font-14 text_blue_opacity ml-2'>Sed mollis</p>
                  </div>
                </div>
                <p className='font-14 text_blue_opacity margint-2'>
                  13th November 2021
                </p>
              </div>
              <div className='social-share-mobile hideWeb'>
                <div className='shareMobile'>share</div>
                <div className={'hline'} />
                <div className='round-social'>
                  <i class='text_grey fab fa-facebook-f'></i>
                </div>
                <div className='round-social'>
                  <i class='text_grey fab fa-twitter'></i>
                </div>
                <div className='round-social'>
                  <i class='text_grey fab fa-linkedin-in'></i>
                </div>
                <div className='round-social'>
                  <i class='text_grey fab fa-pinterest-p'></i>
                </div>
              </div>
              <div class='row'>
                <div class='col-md-12 d-flex align-items-center justify-content-end justify-content-sm-center'>
                  <div class='content'>
                    <p className='text_blue'>
                      <b>
                        Sed mollis nulla at justo volutpat, nec malesuada turpis
                        aliquet.
                      </b>{' '}
                      Nam non tellus elementum, gravida orci sed, tempor lectus.
                      Vestibulum a malesuada risus, vitae tincidunt nisi.
                      Quisque id orci consectetur, blandit felis sit amet,
                      posuere mauris. Praesent aliquam, eros non elementum
                      hendrerit, ex magna consequat libero, vel molestie massa
                      lorem in nulla. In sem lacus, volutpat et nisl vitae,
                      sodales vulputate tortor. Pellentesque eget laoreet erat.
                      Vestibulum ante ipsum primis in faucibus orci luctus et
                      ultrices posuere cubilia curae; Ut sit amet lectus
                      posuere, rhoncus mauris et, scelerisque felis. Fusce et
                      lectus nisl. Aliquam malesuada ullamcorper est non tempor.
                      Nunc ac neque at urna tempus ultricies. Sed mauris nisi,
                      sagittis sed vulputate in, suscipit vitae metus. Etiam eu
                      dui id tortor mollis ultrices sed vitae tellus. Maecenas
                      auctor lobortis mi, et finibus leo efficitur id. Praesent
                      mauris sapien, feugiat eget felis vel, porttitor iaculis
                      est.
                    </p>
                  </div>
                </div>
              </div>
              <div class='shipping_second_section'></div>
              <div class='d-flex align-items-center justify-content-end justify-content-sm-center'>
                <div class='content'>
                  <p className='text_blue'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Aenean consequat ullamcorper ante eget placerat. Suspendisse
                    sed nibh volutpat ex rhoncus dapibus. Pellentesque magna
                    sem, elementum non vehicula sit amet, porttitor at felis.
                    Nunc nec sapien neque. Sed pellentesque, quam tempus ornare
                    consectetur, purus ex volutpat eros, sit amet pellentesque
                    diam ex a ipsum. Mauris arcu dui, convallis sit amet ligula
                    vel, laoreet condimentum mauris. Praesent mollis a tortor ut
                    imperdiet. Nullam dictum felis et ipsum venenatis, quis
                    ultrices tellus blandit. Aenean a finibus magna, sed.
                  </p>
                  <p className={'text_blue mt-4'}>
                    Sed mollis nulla at justo volutpat, nec malesuada turpis
                    aliquet. Nam non tellus elementum, gravida orci sed, tempor
                    lectus. Vestibulum a malesuada risus, vitae tincidunt nisi.
                    Quisque id orci consectetur, blandit felis sit amet, posuere
                    mauris. Praesent aliquam, eros non elementum hendrerit, ex
                    magna consequat libero, vel molestie massa lorem in nulla.
                    In sem lacus, volutpat et nisl vitae, sodales vulputate
                    tortor. Pellentesque eget laoreet erat. Vestibulum ante
                    ipsum primis in faucibus orci luctus et ultrices posuere
                    cubilia curae; Ut sit amet lectus posuere, rhoncus mauris
                    et, scelerisque felis. Fusce et lectus nisl. Aliquam
                    malesuada ullamcorper est non tempor. Nunc ac neque at urna
                    tempus ultricies. Sed mauris nisi, sagittis sed vulputate
                    in, suscipit vitae metus. Etiam eu dui id tortor mollis
                    ultrices sed vitae tellus. Maecenas auctor lobortis mi, et
                    finibus leo efficitur id. Praesent mauris sapien, feugiat
                    eget felis vel, porttitor iaculis est.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <Element name={'Element4'}>
        <section class='services_sec trending'>
          <div class=' align-items-end'>
            <h1 class='service_sec_heading firstSection4SecHead heading_font displayMobileHide'>
              Trending insights
            </h1>
            <div class='sec4'>
              <div class='firstSection4Sec contentDiv'>
                <div class='content contentDivMobile border-right-none'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Insight one</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>

              <div class='middle4Sec contentDiv'>
                <div class='content pl-2 d-flex align-items-end border-right-none'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Insight two</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>

              <div class='middle4Sec contentDiv'>
                <div class='content pl-2 d-flex border-right-none align-items-end'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Insight three</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>

              <div class='lastSection4Sec contentDiv'>
                <div class='content d-flex align-items-end pl-2 lastSection4SecContent border-none'>
                  <div className='mobileRowBetween'>
                    <h3 class='title mobileView'>Insight four</h3>
                    <p>
                      Lore disqui venis et et quiae libus ullam esto ipsae
                      plabore reici del is eario volorerro imus voluptatus.
                    </p>
                    <a href='' class='btn_link text_green displayMobileHide'>
                      Discover <i class='ml-1 fas fa-arrow-right'></i>{' '}
                    </a>
                    <i class='hideWeb font-40 text-white mr-1 fal fa-arrow-right'></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="services_sec trending">
                    <div class="container align-items-end">
                        <h1 class="service_sec_heading z_index_3 heading_font font-40 displayMobileHide">Trending insights</h1>

                        <div class="row bottom_text align-items-end">
                            <div class="col-md-3 showMobile contentDiv">
                                <div class="content contentDivMobile">
                                    <h4 class="hideWeb service_sec_heading">Trending insights</h4>
                                    <div>
                                        <h3 class="title">Insight one</h3>
                                        <p>Lore disqui venis et et quiae libus ullam esto ipsae plabore reici del is eario volorerro imus voluptatus.</p>
                                        <a href="" class="btn_link text_green">Discover <i class="ml-1 fas fa-arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 showMobile contentDiv">
                                <div class="content d-flex align-items-end">
                                    <div>
                                        <h3 class="title">Insight two</h3>
                                        <p>Lore disqui venis et et quiae libus ullam esto ipsae plabore reici del is eario volorerro imus voluptatus.</p>
                                        <a href="" class="btn_link text_green">Discover <i class="ml-1 fas fa-arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 displayMobileHide contentDiv">
                                <div class="content d-flex align-items-end">
                                    <div>
                                        <h3 class="title">Insight three</h3>
                                        <p>Lore disqui venis et et quiae libus ullam esto ipsae plabore reici del is eario volorerro imus voluptatus.</p>
                                        <a href="" class="btn_link text_green">Discover <i class="ml-1 fas fa-arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 displayMobileHide contentDiv">
                                <div class="content d-flex align-items-end border-none">
                                    <div>
                                        <h3 class="title">Insight four</h3>
                                        <p>Lore disqui venis et et quiae libus ullam esto ipsae plabore reici del is eario volorerro imus voluptatus.</p>
                                        <a href="" class="btn_link text_green">Discover <i class="ml-1 fas fa-arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section> */}
      </Element>
      <section class='recent_post_sec'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6 showMobile'>
              <div class='gray_box rowMobile border-right'>
                <h3 className='font-mobile-small'>Most recent post (H3)</h3>
                <i class='ml-1 font-mobile-small mobile-mr-4 fas fa-arrow-left'></i>
              </div>
            </div>

            <div class='col-md-6 showMobile d-flex justify-content-end'>
              <div class='gray_box rowMobile1 text-right align-items-center arrow-center-sm'>
                <h3 className='font-mobile-small'>Next Page (H3)</h3>
                <i class='ml-1 font-mobile-small mobile-mr-4 fas fa-arrow-right'></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='hideWeb'>
        <HomeFooter />
      </div>
      <MainFooter />
    </div>
  )
}
