import React, { useState } from 'react'
import { Loader, Uploader } from 'rsuite'
import { isEmail } from '../../utils/utilities'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import moment from 'moment'
export default function Process2({
  onClickButton,
  handleChange,
  loading,
  projectClassification,
  projectRFP,
  projectSupportdocs,
  formalGoverance,
  onsiteManager,
  onsitepercentageengagement,
  isProjectManagerAssigned,
  primarilyLanguageRequired,
  prescreening,
  primarilyLanguage1,
  primarilyLanguage2,
  egtosTeamExpectation,
  companypayableEmail,
  projectName,
  budget,
  expectStartDate1,
  expectedEndDate1,
  fileRFP,
  project,
  projectRoles,
  projectRolesSelected,
  businesslist,
  industrylist,
  sectorlist,
  sector,
  industry,
  businessfunction,
  createproject,
  handleChangeProjectRoles,
  update
}) {
  const [state, setState] = useState({
    required: false,
    openCalendar: false,
    openCalendar1: false
  })
  const { required, openCalendar, openCalendar1 } = state
  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  console.log('expectStartDate1', expectStartDate1)

  const disabled =
    !primarilyLanguage1 ||
    !primarilyLanguage2 ||
    !egtosTeamExpectation ||
    !companypayableEmail ||
    (!update && !fileRFP) ||
    !projectName ||
    !budget

  const _onClickButton = () => {
    
    if (disabled) {
      handleChangeLocal('required', true)
      return
    }
    handleChangeLocal('required', false)
    console.log('_onClickButton');
    onClickButton('process3')
  }
  const filterIndustrylist = index => {
    if (index === 0) {
      const list = industrylist?.filter(e => e.sector === sector)
      return list
    } else {
      const list = industrylist?.filter(e => e.sector === sector)
      return list
    }
  }

  return (
    <section class='loginOuter'>
      <div class='container'>
        <div class='row'>
          <div class='col-sm-12'>
            <div class='login'>
              <div class='loginIn'>
                <div class='row'>
                  <div class='col-lg-12 col-md-8'>
                    <div class='loginPara'>
                      <h3>
                        {createproject ? 'Create Project' : 'Sign Up Egtos'}
                      </h3>
                      <p class='mb-3'>You are now ready to create a project</p>
                    </div>

                    <div class='question_box mt-5'>
                      <h3 class='title'>
                        Let’s make sure your accounts payable department has
                        what they need from us
                      </h3>
                      <h5>
                        Please enter your company accounts payable email
                        address:
                      </h5>

                      <div class='row  col-lg-6 col-md-8'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              disabled={update}
                              value={companypayableEmail}
                              onChange={value =>
                                handleChange(
                                  'companypayableEmail',
                                  value.target.value
                                )
                              }
                              placeholder='Company email 1'
                            />
                            {companypayableEmail &&
                              isEmail(companypayableEmail) && (
                                <div>
                                  <small>
                                    {' '}
                                    Email must be a valid email address !
                                  </small>
                                </div>
                              )}
                            {required && !companypayableEmail && (
                              <div>
                                <small>Company email 1 is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6'>
                    <div class='question_box'>
                      <h3 class='title'>What is the name of your project?</h3>
                      <div class='companyInput'>
                        <div class='logingroup'>
                          <input
                            type='text'
                            disabled={update}
                            value={projectName}
                            onChange={value =>
                              handleChange('projectName', value.target.value)
                            }
                            placeholder='Project name'
                          />

                          {required && !projectName && (
                            <div>
                              <small>Project name is required !</small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6'>
                    <div class='question_box'>
                      <h3 class='title'>Project classification</h3>
                      <h5>How would you classify your project?</h5>
                      <div class='loginGenderRadio'>
                        <div class='row'>
                          {/* {project?.map((item, index) => ( */}
                          <div class='col-lg-12 col-md-12 col-xs-12'>
                            <div class='select_wrap'>
                              <select
                                class='form-select'
                                disabled={update}
                                value={projectClassification}
                                onChange={value => {
                                  handleChange(
                                    'projectClassification',
                                    JSON.parse(value.target.value)?.projecttype
                                  )
                                  handleChangeProjectRoles(
                                    'projectRoles',
                                    JSON.parse(value.target.value)?.projectrole
                                  )
                                  handleChange('projectRolesSelected', [
                                    'Agile SME (Project Leader)'
                                  ])
                                }}
                              >
                                <option value=''>Project Classification</option>
                                {project &&
                                  project?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={JSON.stringify(item)}
                                    >
                                      {item?.projecttype}
                                    </option>
                                  ))}
                              </select>

                              <button type='button'>
                                <i class='fas fa-arrow-down'></i>
                              </button>
                            </div>
                            {/* <div class='form-check mobilemb'>
                                <input
                                  class='form-check-input'
                                  type='radio'
                                  checked={
                                    projectClassification === item?.projecttype
                                  }
                                  onChange={() => {
                                    handleChange(
                                      'projectClassification',
                                      item?.projecttype
                                    )
                                    handleChange(
                                      'projectRoles',
                                      item?.projectrole
                                    )
                                  }}
                                />
                                <label
                                  class='form-check-label'
                                  for='inlineRadio1'
                                >
                                  {item?.projecttype}
                                </label>
                              </div> */}
                          </div>
                          {/* ))} */}
                        </div>
                      </div>
                      <div class='loginGenderRadio'>
                        <div class='row'>
                          {!update && projectRoles?.length > 0 && (
                            <div class='col-lg-6 mb-2 col-md-4 col-xs-6'>
                              <div class='form-check mobilemb'>
                                <input
                                  class='form-check-input'
                                  type='radio'
                                  checked={
                                    projectRoles === projectRolesSelected
                                  }
                                  onClick={() => {
                                    if (projectRolesSelected != projectRoles) {
                                      handleChange(
                                        'projectRolesSelected',
                                        projectRoles
                                      )
                                    } else {
                                      handleChange('projectRolesSelected', [
                                        'Agile SME (Project Leader)'
                                      ])
                                    }
                                  }}
                                />
                                <label
                                  class='form-check-label'
                                  for='inlineRadio1'
                                >
                                  Select All
                                </label>
                              </div>
                            </div>
                          )}
                          {!update &&
                            projectRoles?.map((role, index) => (
                              <div
                                key={index}
                                class='col-lg-6 mb-2 col-md-4 col-xs-6'
                              >
                                <div class='form-check mobilemb'>
                                  <input
                                    class='form-check-input'
                                    type='radio'
                                    checked={projectRolesSelected.includes(
                                      role
                                    )}
                                    onClick={() => {
                                      // debugger
                                      if (
                                        projectRolesSelected.includes(role) &&
                                        role !== 'Agile SME (Project Leader)'
                                      ) {
                                        const deleteArray = projectRolesSelected.filter(
                                          e => e !== role
                                        )
                                        // for (
                                        //   let o = 0;
                                        //   o < projectRolesSelected.length;
                                        //   o++
                                        // ) {
                                        //   const element = projectRolesSelected[o]
                                        //   if (element === role) {
                                        //     projectRolesSelected?.splice(o, 0)
                                        //   }
                                        // }

                                        console.log(
                                          'projectRolesSelected',
                                          deleteArray
                                        )
                                        handleChange(
                                          'projectRolesSelected',
                                          deleteArray
                                        )
                                        console.log(
                                          'projectRoles',
                                          projectRoles
                                        )
                                      } else {
                                        const list = [
                                          ...projectRolesSelected,
                                          role
                                        ]
                                        handleChange(
                                          'projectRolesSelected',
                                          list
                                        )
                                      }
                                    }}
                                  />
                                  <label
                                    class='form-check-label'
                                    for='inlineRadio1'
                                  >
                                    {role}
                                  </label>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-12'>
                        <div class='question_box'>
                          <h3 class='title'>Sector</h3>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              value={sector}
                              disabled={update}
                              onChange={value =>
                                handleChange('sector', value.target.value)
                              }
                            >
                              <option value=''>Sector</option>
                              {sectorlist?.map((sector, index) => (
                                <option key={index} value={sector?.sector}>
                                  {sector?.sector}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                          {required && !sector && (
                            <div>
                              <small>Sector is required !</small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-12'>
                        <div class='question_box'>
                          <h3 class='title'>Industry</h3>
                          <div class='select_wrap'>
                            <select
                              class='form-select'
                              disabled={update}
                              formControlName='industry'
                              value={industry}
                              onChange={value =>
                                handleChange('industry', value.target.value)
                              }
                            >
                              <option value=''>Industry</option>
                              {filterIndustrylist(0)?.map((industry, index) => (
                                <option key={index} value={industry?.industry}>
                                  {industry?.industry}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                          {required && !industry && (
                            <div>
                              <small>Industry is required !</small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='row'>
                      <div class='col-lg-12'>
                        <div class='question_box'>
                          <h3 class='title'>Business function</h3>
                          <div class='select_wrap'>
                            <select
                              value={businessfunction}
                              class='form-select'
                              disabled={update}
                              onChange={value =>
                                handleChange(
                                  'businessfunction',
                                  value.target.value
                                )
                              }
                            >
                              <option value=''>Function</option>
                              {businesslist?.map((business, index) => (
                                <option
                                  key={index}
                                  value={business?.businessFunction}
                                >
                                  {business?.businessFunction}
                                </option>
                              ))}
                            </select>

                            <button type='button'>
                              <i class='fas fa-arrow-down'></i>
                            </button>
                          </div>
                          {required && !businessfunction && (
                            <div>
                              <small>Business function is required !</small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div class='logingroup'>
                      <input
                        type='text'
                        onChange={value =>
                          handleChange('book', 'Other' + value.target.value)
                        }
                        placeholder=''
                      />
                    </div> */}
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        If you previously issued an RFP or have an RFP for this
                        project, please upload the RFP as well
                      </h5>
                      <h5 className={'mt-2'}>
                        Please upload your project brief
                      </h5>
                      <div class='logingroup mt-3'>
                        <div class='logingroupUpload'>
                          <div class='upload-btn-wrapper'>
                            <Uploader
                              listType='picture-text'
                              disabled={update}
                              accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                              action='//jsonplaceholder.typicode.com/posts/'
                              onChange={value => handleChange('fileRFP', value)}
                              renderFileInfo={(file, fileElement) => {
                                return (
                                  <div>
                                    <span>File Name: {file.name}</span>
                                    <p style={{ marginLeft: 13 }}>
                                      File type: {file.blobFile.type}
                                    </p>
                                  </div>
                                )
                              }}
                            >
                              <button disabled={update} class='btn'>
                                Upload your RFP
                              </button>
                            </Uploader>
                          </div>
                        </div>
                        {required && !update && !fileRFP && (
                          <div>
                            <small>RFT is required !</small>
                          </div>
                        )}
                      </div>
                      <div class='logingroup mt-1'>
                        <div class='logingroupUpload'>
                          <div class='upload-btn-wrapper'>
                            <Uploader
                              disabled={update}
                              listType='picture-text'
                              accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                              action='//jsonplaceholder.typicode.com/posts/'
                              onChange={value =>
                                handleChange('filesupporting', value)
                              }
                              renderFileInfo={(file, fileElement) => {
                                return (
                                  <div>
                                    <span>File Name: {file.name}</span>
                                    <p style={{ marginLeft: 13 }}>
                                      File type: {file.blobFile.type}
                                    </p>
                                  </div>
                                )
                              }}
                            >
                              <button disabled={update} class='btn'>
                                Upload supporting materials
                              </button>
                            </Uploader>
                          </div>
                        </div>
                        <div className={'mt-2'}>
                          File formats: Word, PowerPoint, Excel and PDF
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        What is your maximum budget for this project?
                      </h5>
                      <div class='companyInput mt-3'>
                        <div class='logingroup'>
                          <input
                            type='number'
                            value={budget}
                            onChange={value =>
                              handleChange('budget', value.target.value)
                            }
                            placeholder='$0.00'
                          />
                          {required && !budget && (
                            <div>
                              <small>Budget is required !</small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        Has a formal governance structure with key
                        representations been formed for this project?
                      </h5>
                      <div class='toggle-button-cover mt-3'>
                        <div class='button-cover'>
                          <div class='buttonn b2' id='button-13'>
                            <input
                              type='checkbox'
                              checked={!formalGoverance}
                              onChange={value =>
                                handleChange(
                                  'formalGoverance',
                                  !formalGoverance
                                )
                              }
                              class='checkbox'
                            />
                            <div class='knobs'>
                              <span></span>
                            </div>
                            <div class='layer'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        Would you like the Egtos engagement manager to be
                        onsite?
                      </h5>
                      <div class='toggle-button-cover mt-3'>
                        <div class='button-cover'>
                          <div class='buttonn b2' id='button-13'>
                            <input
                              type='checkbox'
                              checked={!onsiteManager}
                              onChange={value =>
                                handleChange('onsiteManager', !onsiteManager)
                              }
                              class='checkbox'
                            />
                            <div class='knobs'>
                              <span></span>
                            </div>
                            <div class='layer'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5>
                        If yes, please select one onsite option below you would
                        be willing to do as the engagement manager:
                      </h5>

                      <div class='loginGenderRadio'>
                        <div class='row'>
                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '25'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='25'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio1'
                              >
                                25%
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '50'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='50'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio2'
                              >
                                50%
                              </label>
                            </div>
                          </div>

                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '70'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='70'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio3'
                              >
                                70%
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '100'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='100'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio4'
                              >
                                100%
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        Do you currently have resources such as a project
                        manager allocated to support this project?
                      </h5>
                      <div class='toggle-button-cover mt-3'>
                        <div class='button-cover'>
                          <div class='buttonn b2' id='button-13'>
                            <input
                              type='checkbox'
                              checked={!isProjectManagerAssigned}
                              onChange={value =>
                                handleChange(
                                  'isProjectManagerAssigned',
                                  !isProjectManagerAssigned
                                )
                              }
                              class='checkbox'
                            />
                            <div class='knobs'>
                              <span></span>
                            </div>
                            <div class='layer'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        What is the expected start date you had in mind for this
                        project?
                      </h5>
                      <div class='companyInput ml-0'>
                        {/* <div class='logingroup row'> */}
                        {/* <input
                            type='text'
                            type={'number'}
                            maxLength={2}
                            style={{ width: 50, marginLeft: 15 }}
                            onChange={value =>
                              handleChange(
                                'expectStartDate1',
                                value.target.value
                              )
                            }
                            placeholder='DD'
                          />
                          <input
                            type='text'
                            type={'number'}
                            maxLength={2}
                            style={{ width: 50, marginLeft: 10 }}
                            onChange={value =>
                              handleChange(
                                'expectStartDate2',
                                value.target.value
                              )
                            }
                            placeholder='MM'
                          />
                          <input
                            type='text'
                            type={'number'}
                            maxLength={2}
                            style={{ width: 50, marginLeft: 10 }}
                            onChange={value =>
                              handleChange(
                                'expectStartDate3',
                                value.target.value
                              )
                            }
                            placeholder='YY'
                          /> */}
                        <DatePicker
                          // disableFuture
                          // disablePast
                          clearable
                          label={
                            expectStartDate1
                              ? moment(expectStartDate1,"DD-MM-YYYY").format('LL')
                              : 'Expected start date'
                          }
                          openTo='year'
                          views={['day', 'month', 'year']}
                          value={moment(expectStartDate1,"DD-MM-YYYY").format('LL')}
                          onChange={newValue => {
                            handleChange('expectStartDate1', newValue)
                          }}
                          renderInput={params => (
                            <TextField style={{ height: 30 }} {...params} />
                          )}
                        />
                        {/* <div class='logingroup'>
                          <button
                            className='btn'
                            onClick={() =>
                              handleChangeLocal('openCalendar', true)
                            }
                          >
                            {expectStartDate1
                              ? moment(expectStartDate1).format('LL')
                              : 'Expected start date'}
                          </button>
                        </div>
                        {openCalendar && (
                          <Calendar
                            minDate={new Date()}
                            onChange={newValue => {
                              handleChange('expectStartDate1', newValue)
                              handleChangeLocal('openCalendar', false)
                            }}
                            value={expectStartDate1}
                          />
                        )} */}
                        {/* <div class="logingroupUpload">
                                                        <span class="upload-btn-wrapper">
                                                            <button class="btn">Open Calender</button>
                                                        </span>
                                                    </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        What is the expected end date you had in mind for this
                        project?
                      </h5>
                      <div class='companyInput ml-0'>
                        {/* <div class='logingroup row'>
                          <input
                            type='text'
                            type={'number'}
                            maxLength={2}
                            style={{ width: 50, marginLeft: 15 }}
                            aria-multiline
                            onChange={value =>
                              handleChange(
                                'expectedEndDate1',
                                value.target.value
                              )
                            }
                            placeholder='DD'
                          />
                          <input
                            type='text'
                            type={'number'}
                            maxLength={2}
                            style={{ width: 50, marginLeft: 10 }}
                            aria-multiline
                            onChange={value =>
                              handleChange(
                                'expectedEndDate2',
                                value.target.value
                              )
                            }
                            placeholder='MM'
                          />
                          <input
                            type='text'
                            type={'number'}
                            maxLength={2}
                            style={{ width: 50, marginLeft: 10 }}
                            aria-multiline
                            onChange={value =>
                              handleChange(
                                'expectedEndDate3',
                                value.target.value
                              )
                            }
                            placeholder='YY'
                          /> */}
                        {/* <div class="logingroupUpload">
                                                        <span class="upload-btn-wrapper">
                                                            <button class="btn">Open Calender</button>
                                                        </span>
                                                    </div> */}
                        {/* </div> */}
                        {/* <div class='logingroup'>
                          <button
                            className='btn'
                            onClick={() =>
                              handleChangeLocal('openCalendar1', true)
                            }
                          >
                            {expectedEndDate1
                              ? moment(expectedEndDate1).format('LL')
                              : 'Expected end date'}
                          </button>
                        </div>
                        {openCalendar1 && (
                          <Calendar
                            minDate={new Date()}
                            onChange={newValue => {
                              handleChange('expectedEndDate1', newValue)
                              handleChangeLocal('openCalendar1', false)
                            }}
                            value={expectedEndDate1}
                          />
                        )} */}
                        <DatePicker
                          // disableFuture
                          disablePast
                          clearable
                          label={
                            expectedEndDate1
                              ? moment(expectedEndDate1,"DD-MM-YYYY").format('LL')
                              : 'Expected end date'
                          }
                          openTo='year'
                          views={['year', 'month', 'day']}
                          value={moment(expectedEndDate1,"DD-MM-YYYY").format('LL') }
                          onChange={newValue => {
                            handleChange('expectedEndDate1', newValue)
                          }}
                          renderInput={params => (
                            <TextField style={{ height: 30 }} {...params} />
                          )}
                        />
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        In addition to our primarily engagement delivery
                        language (English), are there any other language(s)
                        necessary to help address specific geographic
                        requirements during the course of this engagement?
                      </h5>
                      <div class='toggle-button-cover mt-3'>
                        <div class='button-cover'>
                          <div class='buttonn b2' id='button-13'>
                            <input
                              type='checkbox'
                              checked={!primarilyLanguageRequired}
                              onChange={value =>
                                handleChange(
                                  'primarilyLanguageRequired',
                                  !primarilyLanguageRequired
                                )
                              }
                              class='checkbox'
                            />
                            <div class='knobs'>
                              <span></span>
                            </div>
                            <div class='layer'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        If yes, please list up to two languages in addition to
                        our primary delivery language below
                      </h5>
                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={primarilyLanguage1}
                              onChange={value =>
                                handleChange(
                                  'primarilyLanguage1',
                                  value.target.value
                                )
                              }
                              placeholder='Language 1'
                            />
                            {required && !primarilyLanguage1 && (
                              <div>
                                <small>Language 1 is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              value={primarilyLanguage2}
                              onChange={value =>
                                handleChange(
                                  'primarilyLanguage2',
                                  value.target.value
                                )
                              }
                              placeholder='Language 2'
                            />
                            {required && !primarilyLanguage2 && (
                              <div>
                                <small>Language 2 is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                        <div class=''>
                          <div class='loginPlus'>
                            <button>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 22 22'
                              >
                                <g
                                  id='Group_560'
                                  data-name='Group 560'
                                  transform='translate(-16042 -761)'
                                >
                                  <g
                                    id='Ellipse_4'
                                    data-name='Ellipse 4'
                                    transform='translate(16042 761)'
                                    fill='#fff'
                                    stroke='#707070'
                                    stroke-width='0.5'
                                  >
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='11'
                                      stroke='none'
                                    />
                                    <circle
                                      cx='11'
                                      cy='11'
                                      r='10.75'
                                      fill='none'
                                    />
                                  </g>
                                  <text
                                    id='_'
                                    data-name='+'
                                    transform='translate(16049 777)'
                                    fill='#969696'
                                    font-size='14'
                                    font-family='OpenSans-Light, Open Sans'
                                    font-weight='300'
                                  >
                                    <tspan x='0' y='0'>
                                      +
                                    </tspan>
                                  </text>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='question_box mt-5'>
                      <h5 className={'mt-5'}>
                        In one statement, can you please share with us what it
                        would take the Egtos team to successfully deliver your
                        project (please be specific, for example, deep
                        understanding in clinical trial operations, experienced
                        implementing agile operating model, deep understanding
                        of core banking, private equity operation is a must,
                        etc.)
                      </h5>
                      <div class='companyInput'>
                        <div class='logingroup'>
                          <textarea
                            type='text'
                            aria-multiline
                            value={egtosTeamExpectation}
                            onChange={value =>
                              handleChange(
                                'egtosTeamExpectation',
                                value.target.value
                              )
                            }
                            placeholder=''
                          />
                          {required && !egtosTeamExpectation && (
                            <div>
                              <small>
                                Egtos Team Expectation is required !
                              </small>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6'>
                    <div class='question_box'>
                      <h3 class='title'>Pre-screening</h3>
                      <h5>
                        We pre-screen, qualify and background-check our experts
                        to save you time, but we are happy to have you interview
                        them if you are willing to
                      </h5>
                      <div class='toggle-button-cover mt-3'>
                        <div class='button-cover'>
                          <div class='buttonn b2' id='button-13'>
                            <input
                              type='checkbox'
                              checked={!prescreening}
                              onChange={value =>
                                handleChange('prescreening', !prescreening)
                              }
                              class='checkbox'
                            />
                            <div class='knobs'>
                              <span></span>
                            </div>
                            <div class='layer'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h4>Now let’s see the world-class team Egtos built for you</h4>

                <div class='logingroup mt-5'>
                  <button
                    onClick={() => _onClickButton()}
                    class='btn btn-outline-warning'
                  >
                    {loading ? (
                      <Loader />
                    ) : createproject ? (
                      update ? (
                        'Update your project'
                      ) : (
                        'Create your project'
                      )
                    ) : (
                      'Create your team'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
