import React from 'react'
import LogoImage from '../../assets/img/LogoImage.png'
import LogoImage1 from '../../assets/img/LogoImage1.png'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';

import Admocs from '../../assets/img/Admocs.png'
import BAR from '../../assets/img/BAR.png'
import IBM from '../../assets/img/IBM.png'
import { Redo } from '@mui/icons-material'

// const list = [
//     { image: LogoImage, name: 'Anil Taneja', sub: "Mexico | Project Leader" },
//     { image: LogoImage1, name: 'Stephen Holmes', sub: "Turkey" },
//     { image: LogoImage, name: 'Anil Taneja', sub: "Mexico | Project Leader" },
//     { image: LogoImage1, name: 'Stephen Holmes', sub: "Turkey" },
//     { image: LogoImage, name: 'Anil Taneja', sub: "Mexico | Project Leader" },
// ]



const MuiAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    },
    marginBottom: 20
}));

const MuiAccordionSummary = styled((props) => (
    <AccordionSummary
        expandIcon={false}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        justifyContent: "space-between",
        alignItems: "center"
    },
}));

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    padding: 0,
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
}));



export default function Team({ item }) {
    const list = item;
    console.log(item);
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <section class="sec-4">
                <div class="container">
                    {
                        list.map((item, index) => (
                            // <MuiAccordion expanded={expanded === index} onChange={handleChangeAccordion(index)}>
                            //     <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            //         {/* <div className='teamBox'> */}
                            //         <div className='row'>
                            //             <img src={item.image} className='logoImage mr-2' alt={''} />
                            //             <div>
                            //                 <h5 className='text_blue mb-2'>{item.name}</h5>
                            //                 <p className='text_blue_opacity reduceMargin font-16'>{item.sub}</p>
                            //                 <p className='text_blue_opacity'>View full profile</p>
                            //             </div>
                            //         </div>
                            //         <div className='d-flex'>
                            //             <div className='mr-5'>
                            //                 <p className='text_blue'>{"Current level:"}</p>
                            //                 <p className='text_blue_opacity font-18'>{"Associate"}</p>
                            //             </div>
                            //             <div className='mr-5'>
                            //                 <p className='text_blue'>{"Client rating:"}</p>
                            //                 <p className='text_blue_opacity font-18'>{"92%"}</p>
                            //             </div>
                            //             <div className='mr-5'>
                            //                 <p className='text_blue'>{"Projects completed:"}</p>
                            //                 <p className='text_blue_opacity font-18'>{"17"}</p>
                            //             </div>
                            //             <div className='mr-5'>
                            //                 <p className='text_blue'>{"Years of professional experience:"}</p>
                            //                 <p className='text_blue_opacity font-18'>{"20"}</p>
                            //             </div>
                            //         </div>
                            //         <i class="fas fa-redo-alt"></i>
                            //         {/* </div> */}
                            //     </MuiAccordionSummary>
                            //     <MuiAccordionDetails>
                            //         <div className='overviewDiv'>
                            //             <div className='typeDiv1 pb-4 col-md-5 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4'>{"Education"}</p>
                            //                 <div className='d-flex justify-content-between'>
                            //                     <div>
                            //                         <p className='text_blue_opacity font-14 mb-1'>{"Highest Level"}</p>
                            //                         <p className='text_blue_opacity font-13 reduceMargin'>{"PhD, Pharmaceutical Science (University of Australia)"}</p>
                            //                     </div>
                            //                     <div>
                            //                         <p className='text_blue_opacity font-14 mb-1'>{"Industry Certifications"}</p>
                            //                         <p className='text_blue_opacity font-13 reduceMargin'>{"BDCP – Biopharmaceutical Development Certified Professional PDCP – Pharmaceutical Development Certified Professional"}</p>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //             <div className='typeDiv1 col-md-3 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4 '>{"Country of citizenship:"}</p>
                            //                 <p className='text_blue_opacity font-24 reduceMargin'>{"Mexico"}</p>
                            //             </div>
                            //             <div className='typeDiv1 col-md-4 col-sm-12'>
                            //                 <p className='text_blue mt-4'>{"Technology"}</p>
                            //                 <div className='d-flex justify-content-between'>
                            //                     <div>
                            //                         <p className='text_blue_opacity font-14 mb-1'>{"Expert*"}</p>
                            //                         <p className='text_blue_opacity font-24 reduceMargin'>{"R"}</p>
                            //                     </div>
                            //                     <div>
                            //                         <p className='text_blue_opacity font-14 mb-1'>{"Expert*"}</p>
                            //                         <p className='text_blue_opacity font-24 reduceMargin'>{"SASS"}</p>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //         <div className='overviewDiv border-top'>
                            //             <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4'>{"Current level:"}</p>
                            //                 <p className='text_blue_opacity font-24 reduceMargin'>{"Associate"}</p>
                            //             </div>
                            //             <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4 '>{"Teams average rating:"}</p>
                            //                 <p className='text_blue_opacity font-24 reduceMargin'>{"97%"}</p>
                            //             </div>
                            //             <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4'>{"Projects completed:"}</p>
                            //                 <p className='text_blue_opacity font-24 reduceMargin'>{"17"}</p>
                            //             </div>
                            //             <div className='typeDiv pb-4 col-md-3 col-sm-12'>
                            //                 <p className='text_blue mt-4'>{"Client rating:"}</p>
                            //                 <p className='text_blue_opacity font-24 reduceMargin'>{"92%"}</p>
                            //             </div>
                            //         </div>
                            //         <div className='overviewDiv border-top'>
                            //             <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4'>{"Years of professional experience::"}</p>
                            //                 <p className='text_blue_opacity font-24 reduceMargin'>{"20"}</p>
                            //             </div>
                            //             <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4 '>{"Vertical experience (years):"}</p>
                            //                 <p className='text_blue_opacity font-16'>{"Clinical Trial Operations (12) Pharma-covigilance (8)"}</p>
                            //             </div>
                            //             <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                            //                 <p className='text_blue mt-4'>{"Previous organisations"}</p>
                            //                 <div className='d-flex justify-content-between align-items-center mt-2'>
                            //                     <img src={Admocs} alt={""} width={"30%"} style={{ height: 25 }} />
                            //                     <img src={IBM} alt={""} width={"25%"} style={{ height: 40 }} />
                            //                     <img src={BAR} alt={""} width={"30%"} style={{ height: 25 }} />
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </MuiAccordionDetails>
                            // </MuiAccordion>
                            <MuiAccordion expanded={expanded === index} onChange={handleChangeAccordion(index)}>
                                <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    {/* <div className='teamBox'> */}
                                    <div className='row'>
                                        <img src={item.image || LogoImage1} className='logoImage mr-2' alt={''} />
                                        <div>
                                            <h5 className='text_blue mb-2'>{item.firstName + ' ' + item.lastName}</h5>
                                            <p className='text_blue_opacity reduceMargin font-16'>    {item.livingCountry}</p>
                                            <p className='text_blue_opacity'>View full profile</p>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='mr-5'>
                                            <p className='text_blue'>{"Role:"}</p>
                                            <p className='text_blue_opacity font-18'>{item.role}</p>
                                        </div>
                                        <div className='mr-5'>
                                            <p className='text_blue'>{"Client rating:"}</p>
                                            <p className='text_blue_opacity font-18'>{"92%"}</p>
                                        </div>
                                        <div className='mr-5'>
                                            <p className='text_blue'>{"Projects completed:"}</p>
                                            <p className='text_blue_opacity font-18'>{"17"}</p>
                                        </div>
                                        <div className='mr-5'>
                                            <p className='text_blue'>{"Years of professional experience:"}</p>
                                            <p className='text_blue_opacity font-18'>{"20"}</p>
                                        </div>
                                    </div>
                                    <i class="fas fa-redo-alt"></i>
                                    {/* </div> */}
                                </MuiAccordionSummary>
                                <MuiAccordionDetails>
                                    <div className='overviewDiv'>
                                        <div className='typeDiv1 pb-4 col-md-5 col-sm-12 border-right'>
                                            <p className='text_blue mt-4'>{"Education"}</p>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <p className='text_blue_opacity font-14 mb-1'>{"Highest Level"}</p>
                                                    <p className='text_blue_opacity font-13 reduceMargin'>{"PhD, Pharmaceutical Science (University of Australia)"}</p>
                                                </div>
                                                <div>
                                                    <p className='text_blue_opacity font-14 mb-1'>{"Industry Certifications"}</p>
                                                    <p className='text_blue_opacity font-13 reduceMargin'>{"BDCP – Biopharmaceutical Development Certified Professional PDCP – Pharmaceutical Development Certified Professional"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='typeDiv1 col-md-3 col-sm-12 border-right'>
                                            <p className='text_blue mt-4 '>{"Country of citizenship:"}</p>
                                            <p className='text_blue_opacity font-24 reduceMargin'>{item.livingCountry}</p>
                                        </div>
                                        <div className='typeDiv1 col-md-4 col-sm-12'>
                                            <p className='text_blue mt-4'>{"Technology"}</p>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <p className='text_blue_opacity font-14 mb-1'>{"Expert*"}</p>
                                                    <p className='text_blue_opacity font-24 reduceMargin'>{"R"}</p>
                                                </div>
                                                <div>
                                                    <p className='text_blue_opacity font-14 mb-1'>{"Expert*"}</p>
                                                    <p className='text_blue_opacity font-24 reduceMargin'>{item.role}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='overviewDiv border-top'>
                                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                                            <p className='text_blue mt-4'>{"Current level:"}</p>
                                            <p className='text_blue_opacity font-24 reduceMargin'>{"Associate"}</p>
                                        </div>
                                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                                            <p className='text_blue mt-4 '>{"Teams average rating:"}</p>
                                            <p className='text_blue_opacity font-24 reduceMargin'>{"97%"}</p>
                                        </div>
                                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                                            <p className='text_blue mt-4'>{"Projects completed:"}</p>
                                            <p className='text_blue_opacity font-24 reduceMargin'>{"17"}</p>
                                        </div>
                                        <div className='typeDiv pb-4 col-md-3 col-sm-12'>
                                            <p className='text_blue mt-4'>{"Client rating:"}</p>
                                            <p className='text_blue_opacity font-24 reduceMargin'>{"92%"}</p>
                                        </div>
                                    </div>
                                    <div className='overviewDiv border-top'>
                                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                                            <p className='text_blue mt-4'>{"Years of professional experience::"}</p>
                                            <p className='text_blue_opacity font-24 reduceMargin'>{"20"}</p>
                                        </div>
                                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                                            <p className='text_blue mt-4 '>{"Vertical experience (years):"}</p>
                                            <p className='text_blue_opacity font-16'>{"Clinical Trial Operations (12) Pharma-covigilance (8)"}</p>
                                        </div>
                                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                                            <p className='text_blue mt-4'>{"Previous organisations"}</p>
                                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                                <img src={Admocs} alt={""} width={"30%"} style={{ height: 25 }} />
                                                <img src={IBM} alt={""} width={"25%"} style={{ height: 40 }} />
                                                <img src={BAR} alt={""} width={"30%"} style={{ height: 25 }} />
                                            </div>
                                        </div>
                                    </div>
                                </MuiAccordionDetails>
                            </MuiAccordion>

                        ))
                    }
                </div>
            </section>

        </>
    )
}
