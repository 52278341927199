import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import AppContextProvider from './context/AppContext'
import {
  Home,
  Signup,
  ConfirmEmail,
  SignupProcess,
  Login,
  ForgotPassword,
  BacomeExpert,
  EmailVerify,
  SignupClientProcess,
  ResetPass,
  Explore,
  Insight,
  Shipping,
  MyProjectHome,
  CreateProject,
  TeamOptions,
  ChoosenTeam,
  Dashboard,
  ClientDashboard,
  ProjectOverview,
  ProjectOverviewInvoice,
  AboutUs,
  ProjectExpertOverview,
  ExpertProject,
  ExpertFeedback,
  ExpertReference,
  WhoChooseUs,
  RequestADemo,
  ExecutiveDashboard,
  UserManagement,
  Matrix,
  Career,
  EmailVerification,
  SignContract,
  ViewProject,
  ClientContract
} from './containers'

import { SnackbarProvider } from 'notistack'
function App() {
  return (
    <AppContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <Switch>
              <Route path='/login'>
                <Login />
              </Route>

              <Route path='/project/team/choosen'>
                <ChoosenTeam />
              </Route>
              <Route path='/project/team/options'>
                <TeamOptions />
              </Route>
              <Route path='/project/create'>
                <CreateProject />
              </Route>
              <Route path='/project/edit/:id'>
                <CreateProject />
              </Route>
              <Route path='/about-us'>
                <AboutUs />
              </Route>
              <Route path='/career'>
                <Career />
              </Route>
              <Route path='/request-a-demo'>
                <RequestADemo />
              </Route>
              <Route path='/why-choose-us'>
                <WhoChooseUs />
              </Route>
              <Route path='/client/project/overview/invoice'>
                <ProjectOverviewInvoice />
              </Route>
              <Route path='/client/project/overview'>
                <ProjectOverview />
              </Route>
              <Route path='/client/contract/:id'>
                <ClientContract />
              </Route>
              <Route path='/expert/project/overview'>
                <ProjectExpertOverview />
              </Route>
              <Route path='/expert/project'>
                <ExpertProject />
              </Route>
             
              <Route path='/expert/feedback/reference/:id'>
                <ExpertReference />
              </Route>
              <Route path='/expert/feedback/:id'>
                <ExpertFeedback />
              </Route>
              <Route path='/client/feedback'>
                <ExpertFeedback />
              </Route>

              <Route path='/project/:id'>
                <MyProjectHome />
              </Route>
              <Route path='/executive/user/matrix'>
                <Matrix />
              </Route>
              <Route path='/executive/user'>
                <UserManagement />
              </Route>
              <Route path='/dashboard'>
                <Dashboard />
              </Route>

              <Route path='/executive/dashboard'>
                <ExecutiveDashboard />
              </Route>
              <Route path='/client/dashboard'>
                <ClientDashboard />
              </Route>
              <Route exact path='/signup/email-verification'>
                <EmailVerify />
              </Route>
              <Route exact path='/reset-password'>
                <ResetPass />
              </Route>
              <Route exact path='/become-expert'>
                <BacomeExpert />
              </Route>
              <Route exact path='/forgot-password'>
                <ForgotPassword />
              </Route>
              <Route exact path='/signup/progress'>
                <SignupProcess />
              </Route>
              <Route exact path='/signup/client/progress'>
                <SignupClientProcess />
              </Route>
              <Route exact path='/signup/email-confirm'>
                <ConfirmEmail />
              </Route>
              <Route exact path='/signup'>
                <Signup />
              </Route>
              <Route exact path='/shipping'>
                <Shipping />
              </Route>
              <Route exact path='/insight'>
                <Insight />
              </Route>
              <Route exact path='/explore'>
                <Explore />
              </Route>
              <Route exact path='/'>
                <Home />
              </Route>
              <Route exact path='/email'>
                <EmailVerification />
              </Route>
              <Route exact path='/sign-contract'>
                <SignContract />
              </Route>
              <Route exact path='/view-project'>
                <ViewProject />
              </Route>
            </Switch>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </AppContextProvider>
  )
}

export default App
