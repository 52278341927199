import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/logo.png'
import profile from '../../assets/svg/profile.svg'
import { useHistory } from 'react-router-dom'
import menu_icon from '../../assets/img/menu_icon.png'
import { Drawer } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { useSnackbar } from 'notistack'

export default function MainHeader () {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [state, setState] = useState({
    visible: false,
    dropdownOpen: false
  })
  const { dropdownOpen, visible } = state
  const showDrawer = () => {
    setState(pre => ({
      ...pre,
      visible: true
    }))
  }

  const onClose = () => {
    setState(pre => ({
      ...pre,
      visible: false
    }))
  }

  const onlogout = () => {
    localStorage.removeItem('userData')
    enqueueSnackbar(`Logout!`, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    })
    history.push('/login')
  }
  let userData = localStorage.getItem('userData')
  userData = JSON.parse(userData)
  return (
    <div>
      <header class='login_nav'>
        <div class='greenTopbg'>
          <div class='container'>
            <div class='row'>
              <div class='col-12'>
                <div class='greenTop'>
                  {userData ? (
                    <ul>
                      <li className='d-flex justify-content-end align-items-center'>
                        <div className='mr-2'>
                          <div class='login_btn text-white font-weight-700'>
                            {userData?.firstName + ' ' + userData?.lastName}
                          </div>
                          <span
                            onClick={() => onlogout()}
                            className='login_btn-logout'
                          >
                            {'Logout'}
                          </span>
                        </div>
                        <img
                          src={profile}
                          className='border rounded'
                          width={30}
                          alt=''
                        />
                      </li>
                    </ul>
                  ) : (
                    <ul className='login_btn'>
                      <li>
                        <Link
                          to={'/become-expert'}
                          class='create_account_btn underlineNone hoverWhite'
                        >
                          Create Account
                        </Link>
                      </li>
                      <li class='login_btn'>
                        <Link
                          to={'/login'}
                          class='login_btn underlineNone hoverWhite'
                        >
                          Login{' '}
                          <i class='border-round borderhoverWhite far fa-user'></i>
                        </Link>
                      </li>
                    </ul>
                  )}
                  {/* <ul>
                                        <li><Link to={"/become-expert"} class="create_account_btn">Create Account</Link></li>
                                        <li><Link to={"/login"} class="login_btn">Login <i class="far fa-user"></i></Link></li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='custom_login_nav join_nav home_nav_bg'>
          <div class='container'>
            <Drawer
              placement={'left'}
              size={'xs'}
              className='drawer'
              onClose={onClose}
              open={visible}
              key={'left'}
            >
              <div class='greenTopMobile'>
                <img
                  src={profile}
                  className='border rounded'
                  width={30}
                  alt=''
                />
                <Link
                  to={'/'}
                  class='text_yellow d-flex ml-2 mt-0 underlineNone hoverWhite'
                >
                  <div class='login_btn text-white font-weight-700'>
                    {userData?.firstName + ' ' + userData?.lastName}
                  </div>
                  <span onClick={() => onlogout()} className='login_btn-logout'>
                    {'Logout'}
                  </span>
                </Link>
              </div>
              <Drawer.Header className='drawer-header'>
                <div>
                  <Drawer.Title>
                    <img src={Logo} width={100} />
                  </Drawer.Title>
                  <Drawer.Actions></Drawer.Actions>
                </div>
              </Drawer.Header>
              <Drawer.Body className='drawer-body'>
                <p className='text-white'>
                  <Link className='text-white' to={'/why-choose-us'}>
                    Why choose us?
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/explore'}>
                    Industries
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/insight'}>
                    Insights
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/explore'}>
                    Careers
                  </Link>
                </p>
                <p>
                  <Link className='text-white' to={'/about-us'}>
                    About Us
                  </Link>
                </p>
                <p className='text-white'>
                  <Link className='text-white' to={'/request-a-demo'}>
                    Request a Demo
                  </Link>
                </p>
              </Drawer.Body>
            </Drawer>
            <nav class='navbar navbar-expand-lg navbar-light widthfull'>
              <Link class='navbar-brand' to='/'>
                <img src={Logo} alt='' />
              </Link>
              <button
                onClick={showDrawer}
                class='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navbarNav'
                aria-controls='navbarNav'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <i style={{ color: '#fff' }} class='menu-icon fal fa-bars'></i>
              </button>
              <div
                class='collapse navbar-collapse align-items-center justify-content-end'
                id='navbarNav'
              >
                <ul class='navbar-nav d-flex align-items-center'>
                  <li class='nav-item '>
                    <Link
                      to={
                        userData?.usertype === 'client'
                          ? '/client/dashboard'
                          : '/dashboard'
                      }
                      class='nav-link active'
                    >
                      Dashboard
                    </Link>
                  </li>

                  <li class='nav-item'>
                    <a class='nav-link' href='#'>
                      Profile
                    </a>
                  </li>

                  <li class='nav-item'>
                    <a class='nav-link' href='#'>
                      Partner progression
                    </a>
                  </li>
                  <li class='nav-item'>
                    <Link to={'/project'} class='nav-link'>
                      Potential projects
                    </Link>
                  </li>
                  <li class='nav-item'>
                    <a class='nav-link' href='#'>
                      NavItem5
                    </a>
                  </li>
                  <li class='nav-item'>
                    <a class='nav-link' href='#'>
                      NavItem6
                    </a>
                  </li>
                  <li class='ml-3'>
                    <i
                      style={{ color: dropdownOpen ? '#000' : '#fff' }}
                      class='menu-icon fal fa-bars'
                    ></i>
                  </li>
                </ul>
              </div>
              <div class='clearfix'></div>
            </nav>
          </div>
        </div>
      </header>
      {/* <!--header end here--> */}
    </div>
  )
}
