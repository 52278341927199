import { API } from './'

export const signupUser = (payload) => {
  return API.post(
    'signupuser',
    payload,
  )
};

export const uploadfile = (payload) => {
  return API.post(
    'uploadfile',
    payload,
  )
};

export const uploadProjectfile = (payload) => {
  return API.post(
    'uploadprojectfile',
    payload,
  )
};

export const loginUser = (payload) => {
  return API.post(
    'login',
    payload,
  )
};

export const verifyEmail = (payload) => {
  return API.post(
    'confirmuseremail',
    payload,
  )
};

export const resendConfirmationCode = (payload) => {
  return API.post(
    'resendconfirmationcode',
    payload,
  )
};

export const forgotpasswordCode = (payload) => {
  return API.post(
    'forgotpasswordcode',
    payload,
  )
};

export const forgotpassword = (payload) => {
  return API.post(
    'forgotpassword',
    payload,
  )
};

export const countryList = (payload) => {
  return API.get(
    'search/countrylist',
    payload,
  )
};

export const businessFunctionlist = (payload) => {
  return API.get(
    'search/businessfunctionlist',
    payload,
  )
};

export const companyList = (payload) => {
  return API.get(
    'search/companylist',
    payload,
  )
};

export const currencyList = (payload) => {
  return API.get(
    'search/currencylist',
    payload,
  )
};

export const ethnicityList = (payload) => {
  return API.get(
    'search/ethnicitylist',
    payload,
  )
};

export const genderList = (payload) => {
  return API.get(
    'search/genderlist',
    payload,
  )
};

export const industryList = (payload) => {
  return API.get(
    'search/industrylist',
    payload,
  )
};

export const referencesourceList = (payload) => {
  return API.get(
    'search/referencesourcelist',
    payload,
  )
};

export const sectorList = (payload) => {
  return API.get(
    'search/sectorlist',
    payload,
  )
};

export const projectList = (payload) => {
  return API.get(
    'search/projecttypes',
    payload,
  )
};

export const skillList = (payload) => {
  return API.get(
    'search/skillslist',
    payload,
  )
};
