export const defaultConfig = {
 //  API_URL: 'https://dev.egtos.com:9001/api/', // dev url
    //  API_URL: 'https://3.128.121.88:9001/api/', // dev ip url
    // API_URL: 'https://egtos.com:9003/api/', // qa url
    API_URL: 'http://3.128.121.88:9000/api/'
};

export const App = {
    config: defaultConfig,
};

window.env = window.env || defaultConfig;
App.config = { ...window.env };

export const API_URL = () => App.config.API_URL;
