import React, { useEffect, useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import { useHistory, useParams } from 'react-router-dom'
import { upsertexpertrefcheck, getexpertrefercheck } from '../../api/projects'
import { useSnackbar } from 'notistack'

export default function ExpertReference({ }) {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const [state, setState] = useState({
    yearsknown: '',
    respect: '1',
    diversity: '1',
    excellence: '1',
    learning: '1',
    humility: '1',
    listening: '1',
    communication: '1',
    management: '1',
    inspiration: '1',
    collaborating: '1',
    collaborating2: '1',
    thinking: '1',
    analysis: '1',
    planning: '1',
    overallexperience: '',
    refdata: {}
  })
  const {
    yearsknown,
    respect,
    diversity,
    excellence,
    learning,
    humility,
    listening,
    communication,
    management,
    inspiration,
    collaborating,
    collaborating2,
    thinking,
    planning,
    analysis,
    overallexperience,
    refdata
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    getReference()
  }, [])



  const getReference = async () => {

    const payload = {
      _id: id,

    }
    try {
      const res = await getexpertrefercheck(payload)
      handleChange('refdata', res.data.result[0])
      console.log(res.data.result[0]);

    } catch (ex) {
      enqueueSnackbar(`Reference Fetch Failed  `, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })

    }

  }

  const referenceSubmit = async () => {

    const payload = {
      _id: id,
      yearsknown: yearsknown,
      respect: respect,
      diversity: diversity,
      excellence: excellence,
      learning: learning,
      humility: humility,
      listening: listening,
      communication: communication,
      management: management,
      inspiration: inspiration,
      collaborating: collaborating,
      collaborating2: collaborating2,
      thinking: thinking,
      planning: planning,
      analysis: analysis,
      overallexperience: overallexperience,
      status: "Completed"



    }

    console.log(payload);

    const res = await upsertexpertrefcheck(payload)


    if (res.data.result) {
      enqueueSnackbar('ThankYou for the FeedBack', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }

  }

  return (
    <div>
      <MainHeader />

      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class='col-md-6'>
              <div class='content'>
                <div className='d-flex justify-content-between align-items-start'>
                  <div className=''>
                    <h4 class='heading_font_black'>
                      Reference Check Questionnaire
                    </h4>
                    <p className='text_blue'>
                      Approximately how long did {refdata?.expertname} work for you
                      (for example, 1 year, 1.5 years):
                    </p>
                    <div class='logingroup mt-2'>
                      <input
                        type='text'
                        value={yearsknown}
                        onChange={value =>
                          handleChange('yearsknown', value.target.value)
                        }
                        placeholder=''
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <section class='sec-4'>
        <div class='container'>
          <div class='border_line'></div>

          <div class='row'>
            <div class='col-lg-12'>
              <div class='question_box'>
                <h3 class='title'>Let's build a great culture together</h3>
                <h5>Please complete the Egtos value score</h5>
                <div class='signup_table custom_table table-responsive'>
                  <table class='table table-borderless'>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}></th>
                        <th style={{ width: '25%' }}>
                          I have a competent grasp of this value and have
                          demonstrated it in all my projects
                        </th>
                        <th style={{ width: '25%' }}>
                          I regularly demonstrate this value. It's important to
                          me.
                        </th>
                        <th style={{ width: '25%' }}>
                          This value is very strong for me, I am passionate
                          about this value and demonstrate it continually.
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class='table_title'>Respect</td>

                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={respect === '1'}
                                onChange={() => handleChange('respect', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={respect === '2'}
                                onChange={() => handleChange('respect', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={respect === '3'}
                                onChange={() => handleChange('respect', '3')}
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>Diversity</td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={diversity === '1'}
                                onChange={() => handleChange('diversity', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={diversity === '2'}
                                onChange={() => handleChange('diversity', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={diversity === '3'}
                                onChange={() => handleChange('diversity', '3')}
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>Excellence</td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={excellence === '1'}
                                onChange={() => handleChange('excellence', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={excellence === '2'}
                                onChange={() => handleChange('excellence', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={excellence === '3'}
                                onChange={() => handleChange('respect', '3')}
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>Always learning</td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={learning === '1'}
                                onChange={() => handleChange('learning', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={learning === '2'}
                                onChange={() => handleChange('learning', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={learning === '3'}
                                onChange={() => handleChange('learning', '3')}
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>Humility</td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={humility === '1'}
                                onChange={() => handleChange('humility', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={humility === '2'}
                                onChange={() => handleChange('humility', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={humility === '3'}
                                onChange={() => handleChange('humility', '3')}
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class='border_line'></div>

          <div class='row'>
            <div class='col-lg-12'>
              <div class='question_box'>
                <h3 class='title'>
                  Please help us build amazing global dynamic teams
                </h3>
                <h5>
                  Please complete the Egtos Blanced Experts Team Score (BETS)
                  quesionnaire
                </h5>

                <div class='signup_table custom_table table-responsive'>
                  <table class='table table-borderless'>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}></th>

                        <th style={{ width: '25%' }}>
                          Please complete the Egtos Blanced Experts Team Score
                          (BETS) quesionnaire
                        </th>

                        <th style={{ width: '25%' }}>
                          This capability is a key part of my professional
                          skillset and I enjoy using this capability regularly.
                        </th>

                        <th style={{ width: '25%' }}>
                          This capability is central to my professional life. I
                          enjoy my work because I am talented in this area.
                        </th>

                        {/* <th style={{ width: "15%" }}>A core part of 'who I am' as well as
                                'what I do'- this is capability that inspires me to do great
                                work.</th>

                            <th style={{ width: "15%" }}>This capability defines me above all
                                others. I excel in this area. I am the go-to person when
                                this capability is required ! and I'm know to be an expert in
                                this.</th> */}
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class='table_title'>
                          Listening
                          <p>Stakeholder engagement and gravitas</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={listening === '1'}
                                onChange={() => handleChange('listening', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={listening === '2'}
                                onChange={() => handleChange('listening', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={listening === '3'}
                                onChange={() => handleChange('listening', '3')}
                                value='option3'
                              />
                            </div>
                            {/* <div className={"horizontalLine"} /> */}
                          </div>
                        </td>

                        {/* <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={listening === "4"}
                                            // onChange={() => handleChange('listening', '4')}
                                            value="option4" />
                                    </div>
                                    <div className={"horizontalLine"} />
                                </div>
                            </td>

                            <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={listening === "5"}
                                            // onChange={() => handleChange('listening', '5')}
                                            value="option5" />
                                    </div>
                                </div>
                            </td>

                            <div class="line"></div> */}
                      </tr>

                      <tr>
                        <td class='table_title'>
                          Communication
                          <p>Communication and influence</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={communication === '1'}
                                onChange={() =>
                                  handleChange('communication', '1')
                                }
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={communication === '2'}
                                onChange={() =>
                                  handleChange('communication', '2')
                                }
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={communication === '3'}
                                onChange={() =>
                                  handleChange('communication', '3')
                                }
                                value='option3'
                              />
                            </div>
                            {/* <div className={"horizontalLine"} /> */}
                          </div>
                        </td>

                        {/* <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={communication === "4"}
                                            // onChange={() => handleChange('communication', '4')}
                                            value="option4" />
                                    </div>
                                    <div className={"horizontalLine"} />
                                </div>
                            </td>

                            <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={communication === "5"}
                                            // onChange={() => handleChange('communication', '5')}
                                            value="option5" />
                                    </div>
                                </div>
                            </td> */}

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>
                          Management
                          <p>Project management and delivery</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={management === '1'}
                                onChange={() => handleChange('management', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={management === '2'}
                                onChange={() => handleChange('management', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={management === '3'}
                                onChange={() => handleChange('management', '3')}
                                value='option3'
                              />
                            </div>
                            {/* <div className={"horizontalLine"} /> */}
                          </div>
                        </td>

                        {/* <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={management === "4"}
                                            // onChange={() => handleChange('management', '4')}
                                            value="option4" />
                                    </div>
                                    <div className={"horizontalLine"} />
                                </div>
                            </td>

                            <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={management === "5"}
                                            // onChange={() => handleChange('management', '5')}
                                            value="option5" />
                                    </div>
                                </div>
                            </td> */}

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>
                          Analysis
                          <p>Analysis and strategy development</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={analysis === '1'}
                                onChange={() => handleChange('analysis', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={analysis === '2'}
                                onChange={() => handleChange('analysis', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={analysis === '3'}
                                onChange={() => handleChange('analysis', '3')}
                                value='option3'
                              />
                            </div>
                            {/* <div className={"horizontalLine"} /> */}
                          </div>
                        </td>

                        {/* <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={analysis === "4"}
                                            // onChange={() => handleChange('analysis', '4')}
                                            value="option4" />
                                    </div>
                                    <div className={"horizontalLine"} />
                                </div>
                            </td>

                            <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={analysis === "5"}
                                            // onChange={() => handleChange('analysis', '5')}
                                            value="option5" />
                                    </div>
                                </div>
                            </td> */}

                        <div class='line'></div>
                      </tr>

                      <tr>
                        <td class='table_title'>
                          Thinking
                          <p>Vision and innovation</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={thinking === '1'}
                                onChange={() => handleChange('thinking', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={thinking === '2'}
                                onChange={() => handleChange('thinking', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={thinking === '3'}
                                onChange={() => handleChange('thinking', '3')}
                                value='option3'
                              />
                            </div>
                            {/* <div className={"horizontalLine"} /> */}
                          </div>
                        </td>

                        {/* <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={thinking === "4"}
                                            // onChange={() => handleChange('thinking', '4')}
                                            value="option4" />
                                    </div>
                                    <div className={"horizontalLine"} />
                                </div>
                            </td>

                            <td>
                                <div class="loginGenderRadio">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio"
                                            // checked={thinking === "5"}
                                            // onChange={() => handleChange('thinking', '5')}
                                            value="option5" />
                                    </div>
                                </div>
                            </td> */}

                        <div class='line'></div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class='border_line'></div>

          <div class='row'>
            <div class='col-lg-12'>
              <div class='question_box'>
                <h3 class='title'>Let's build a great culture together</h3>
                <h5>Please complete the Egtos value score</h5>

                <div class='signup_table custom_table table-responsive'>
                  <table class='table table-borderless'>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}></th>
                        <th style={{ width: '25%' }}>
                          The client provided a basic level of documentation and
                          guidance.
                        </th>
                        <th style={{ width: '25%' }}></th>
                        <th style={{ width: '25%' }}>
                          I enjoy focused work without interruption and
                          scheduled prepared for meetings.
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class='table_title'>
                          Planning
                          <p>How do you like to plan your day?</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={planning === '1'}
                                onChange={() => handleChange('planning', '1')}
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={planning === '2'}
                                onChange={() => handleChange('planning', '2')}
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={planning === '3'}
                                onChange={() => handleChange('planning', '3')}
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class='signup_table custom_table table-responsive'>
                  <table class='table table-borderless'>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}></th>
                        <th style={{ width: '25%' }}>
                          TI like the openendedness of Miro or Jam Board for
                          generating idas on the hoof as a team.
                        </th>
                        <th style={{ width: '25%' }}></th>
                        <th style={{ width: '25%' }}>
                          I enjoy the structure of tools like Power Point and
                          prefer to come preparted with well rounded inputs.
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class='table_title'>
                          Collaborating
                          <p>How do you like to collaborate with the team?</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={collaborating === '1'}
                                onChange={() =>
                                  handleChange('collaborating', '1')
                                }
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={collaborating === '2'}
                                onChange={() =>
                                  handleChange('collaborating', '2')
                                }
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={collaborating === '3'}
                                onChange={() =>
                                  handleChange('collaborating', '3')
                                }
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class='signup_table custom_table table-responsive'>
                  <table class='table table-borderless'>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}></th>
                        <th style={{ width: '25%' }}>
                          Face to face is best for me to build a natural rapport
                          with a client. It's all part of a conversation after
                          all.
                        </th>
                        <th style={{ width: '25%' }}></th>
                        <th style={{ width: '25%' }}>
                          I like to impress with structured thinking and slick
                          well put together reports and presentations.
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class='table_title'>
                          Collaborating
                          <p>How do you like to collaborate with the team?</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={collaborating2 === '1'}
                                onChange={() =>
                                  handleChange('collaborating2', '1')
                                }
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={collaborating2 === '2'}
                                onChange={() =>
                                  handleChange('collaborating2', '2')
                                }
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={collaborating2 === '3'}
                                onChange={() =>
                                  handleChange('collaborating2', '3')
                                }
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class='signup_table custom_table table-responsive'>
                  <table class='table table-borderless'>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}></th>
                        <th style={{ width: '25%' }}>
                          Big picture ideas, articles, opinion pieces help me
                          imagine the art of the possible.
                        </th>
                        <th style={{ width: '25%' }}></th>
                        <th style={{ width: '25%' }}>
                          I love deep diving in to the data.
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td class='table_title'>
                          Inspiration
                          <p>What inspires your thinking?</p>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={inspiration === '1'}
                                onChange={() =>
                                  handleChange('inspiration', '1')
                                }
                                value='option1'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={inspiration === '2'}
                                onChange={() =>
                                  handleChange('inspiration', '2')
                                }
                                value='option2'
                              />
                            </div>
                            <div className={'horizontalLine'} />
                          </div>
                        </td>
                        <td>
                          <div class='loginGenderRadio'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={inspiration === '3'}
                                onChange={() =>
                                  handleChange('inspiration', '3')
                                }
                                value='option3'
                              />
                            </div>
                          </div>
                        </td>

                        <div class='line'></div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-6'>
            <div class='question_box mt-5'>
              <h5 className={'mt-5 text_green'}>Overall</h5>
              <p className='text_blue mb-4 mt-1'>
                How would you describe your overall experience with{' '}
                {refdata?.expertname}
              </p>
              <div class='companyInput ml-0'>
                <div class='logingroup'>
                  <textarea
                    type='text'
                    aria-multiline
                    value={overallexperience}
                    onChange={value =>
                      handleChange('overallexperience', value.target.value)
                    }
                    placeholder=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='sec-4'>
        <div class='container'>
          <div class='d-flex'>
            <div class='logingroup width30'>
              <button
                onClick={() => referenceSubmit()}
                class='btn btn-outline-warning'
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </section>

      <MainFooter />
    </div>
  )
}
