import React, { useEffect, useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import { useHistory } from 'react-router-dom'
import Admocs from '../../assets/img/Admocs.png'
import IBM from '../../assets/img/IBM.png'
import Astra from '../../assets/img/Astra.png'
import BAR from '../../assets/img/BAR.png'
import warning from '../../assets/svg/warning.svg'
import Alarm from '../../assets/svg/Alarm.svg'
import showmore from '../../assets/svg/showmore.svg'
import userImage from '../../assets/img/userImage.png'
import Attached from '../../assets/svg/Attached.svg'
import Mail from '../../assets/svg/Mail.svg'
import { getClient } from '../../api/client'
import { getclientprojects } from '../../api/projects'
import './index.css'
export default function ClientDashboard() {
  const history = useHistory()
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const list = [
    {
      image: Admocs,
      title: 'Project name 1',
      warning: true,
      alarm: true,
      messageRead: true
    },
    {
      image: Admocs,
      title: 'Project name 2',
      warning: true,
      alarm: true,
      attached: true,
      messageRead: true
    },
    { image: Admocs, title: 'New Project', create: true }
  ]
  // let userData = localStorage.getItem('userData')
  // userData = JSON.parse(userData)

  const [state, setState] = useState({
    projects: [],
    client: {}
  })

  const { projects, client } = state
  const _id = localStorage.getItem('_id') || '6225e51f8662c89d15b93b1a'
  useEffect(() => {
    _getClient()
  }, [])


  const _getClient = async () => {
    try {
      handleChange('loading', true)
      const payload = {
        _id: _id

      }
      const clientData = await getClient(payload);
      handleChange('client', clientData?.data?.result?.[0])
      const projectsData = await getclientprojects(payload);

      handleChange('projects', projectsData?.data?.result)
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error)
      // alert(error.message)
    }
  }




  return (
    <div>
      <MainHeader />
      {/* <section class='sec-4 bg-grey'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6'>
              <div class='content'>
                <h4 class='heading_font heading_font_black'>
                  You’ve been invited to a new project…
                </h4>
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <button
                  onClick={() => history.push('/client/project/overview')}
                  className='mt-3 btn blue_outline_btn inviteBtn'
                >
                  View project invite <i class='ml-1 fas fa-arrow-right'></i>
                </button>
              </div>
            </div>

            <div class='col-md-6 text-right'>
              <div class='content'>
                <img src={IBM} />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class='sec-4'>
        <div class='container'>
          <div class='col-md-6'>
            <div class='content'>
              <div className='d-flex justify-content-between align-items-start'>
                <h4 class='heading_font_black'>
                  Welcome back {client?.firstName + ' ' + client?.lastName},
                  here's an
                  <br />
                  overview of your projects...
                </h4>
                {/* <i onClick={() => history.push("/project/create")} class="mt-2 font-18 fas fa-plus text_blue"></i> */}
              </div>
            </div>
            <i class='ml-1 fas fa-arrow-right'></i>
          </div>
          <div class='col-md-12 d-flex mt-5 ph-0'>
            <div class='col-md-6 teamProject '>
              <div className='blue-head'>
                <b class='text-white'>CLIENT PARTNER:</b>
              </div>

              <div className='white-body d-flex  align-items-center border'>
                <img className='mr-4' src={userImage} alt='' />
                <div>
                  <p className='text_blue font-24'>Ade Abara</p>
                  <p className='text_blue_opacity'>
                    Ethiopia (Local time: 17:31)
                  </p>
                  <button className='mt-3 btn blue_outline_btn'>Contact</button>
                </div>
              </div>
            </div>
            <div class='col-md-6 teamProject '>
              <div className='blue-head'>
                <b class='text-white'>ACCOUNT MANAGER:</b>
              </div>

              <div className='white-body d-flex  align-items-center border'>
                <img className='mr-4' src={userImage} alt='' />
                <div>
                  <p className='text_blue font-24'>Ade Abara</p>
                  <p className='text_blue_opacity'>
                    Ethiopia (Local time: 17:31)
                  </p>
                  <button className='mt-3 btn blue_outline_btn'>Contact</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='sec-4'>
        <div class='container'>
          <h4 class='heading_font_black'>By the numbers:</h4>
          <div className='overviewDiv border-top border-bottom mt-5 '>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Total Investment To-date:'}</p>
              <p className='text_blue_opacity font-14'>{'???'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>{'4'}</p>
            </div>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Paid Invoice:'}</p>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='text_blue_opacity font-14'>{'Complete:'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'600'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p className='text_blue_opacity font-14'>{'Processing*'}</p>
                  <p className='text_blue_opacity font-24 reduceMargin'>
                    {'1'}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12'>
              <p className='text_blue'>{'Outstanding Invoice:'}</p>
              <p className='text_blue_opacity font-14'>{'Days late'}</p>
              <p className='text_blue_opacity font-24 reduceMargin'>
                {'4 Days'}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class='projectSection'>
        <div class='container'>
          <div class='row justify-content-between'>
            <div class='col-md-8'>
              <div class='content'>
      
                 {projects.map(item => (
                  <div className='d-flex radius-5 projectList border align-items-center justify-content-between mb-3'>
                    <div className='d-flex align-items-center'>
                      {!item.create && (
                        <img
                          src={Admocs}
                          alt=''
                          height={35}
                          className='projectImage border-right ml-2 pr-2'
                        />
                      )}
                      <p className=' text_blue ml-2'>{item?.projectName}</p>
                    </div>
                    <div className='d-flex align-items-center'>
                      {item.notifications?.length && (
                        <img src={warning} alt='' className='mr-2' />
                      )}
                      {item.files?.length && (
                        <img src={Attached} alt='' className='mr-2' />
                      )}
                      {item.apps?.length && (
                        <img src={Alarm} alt='' className='mr-2' />
                      )}
                      {item.messages?.length && (
                        <img src={Mail} alt='' className='mr-2' />
                      )}
                      <div
                        className='grey-box'
                        onClick={() =>
                          history.push(
                            item.create
                              ? '/project/create'
                              : `/project/${item._id}`
                          )
                        }
                      >
                        {item.create ? (
                          <i className='fas fa-plus' />
                        ) : (
                          <i className='fas fa-arrow-right' />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-md-3'>
              <div class='content'>
                <p className=' font-24'>PROGRESS & DEADLINES</p>
                <div className='d-flex justify-content-between'>
                  <p></p>
                  <p className=' font-24'>Next</p>
                  <p className='text_blue'>Final</p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='text_green font-18 mt-1'>100%</p>
                  <p className='text_blue_opacity'>00.00.2021</p>
                  <p className='text_blue_opacity'>00.00.2021</p>
                </div>
                <div className='d-flex align-items-center mt-3 justify-content-between'>
                  <p className='font-18 mt-1'>60%</p>
                  <p className='text_blue_opacity'>00.00.2021</p>
                  <p className='text_red'>00.00.2021</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='sampleClient'>
        <div class='container'>
          <div class='col-md-6'>
            <div class='content'>
              <div className=' d-flex justify-content-between align-items-start'>
                <h3 class='heading_font_black font-normal'>
                  Here are sample of clients currently considering you as part
                  of an Egtos team for projects:
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class='container'>
          <div class='col-md-12 row'>
            <div class='col-md-4 teamProject'>
              <div className='blue-head'>
                <b class='text-white'>FINANCE</b>
              </div>

              <div className='white-body'>
                <img className='mb-4' src={BAR} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>6 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject'>
              <div className='blue-head'>
                <b class='text-white'>TECHNOLOGY</b>
              </div>

              <div className='white-body'>
                <img className='mb-4' src={IBM} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>12 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject'>
              <div className='blue-light-head'>
                <b class='text-white'>PHARMACEUTICAL</b>
              </div>

              <div className='white-body'>
                <img className='mb-4 minus-margin' src={Astra} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>4 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
          </div>
          <div className='mt-4 text-center'>
            <img src={showmore} className='cursor-pointer' alt='' />
          </div>
        </div>
      </section>
      <section class='sampleClient1'>
        <div class='container'>
          <div class='col-md-6'>
            <div class='content'>
              <div className=' d-flex justify-content-between align-items-start'>
                <h3 class='heading_font_black font-normal'>
                  Here are sample of clients currently considering you as part
                  of an Egtos team for projects:
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class='container'>
          <div class='col-md-12 row'>
            <div class='col-md-4 teamProject'>
              <div className='black-head'>
                <b class='text-white'>FINANCE</b>
              </div>

              <div className='white-body'>
                <img className='mb-4 grayscale' src={BAR} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>6 weeks</p>
                <p className=''>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject'>
              <div className='black-head'>
                <b class='text-white'>TECHNOLOGY</b>
              </div>

              <div className='white-body'>
                <img className='mb-4 grayscale' src={IBM} alt='' />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>12 weeks</p>
                <p className=''>$XXX</p>
              </div>
            </div>
            <div class='col-md-4 teamProject'>
              <div className='black-head'>
                <b class='text-white'>PHARMACEUTICAL</b>
              </div>

              <div className='white-body'>
                <img
                  className='mb-4 minus-margin grayscale'
                  src={Astra}
                  alt=''
                />
                <p>
                  Short descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines or so, something like this short descriptive
                  text...
                </p>
                <p className='font-18 text_blue'>4 weeks</p>
                <p className='text_green'>$XXX</p>
              </div>
            </div>
          </div>
          <div className='mt-4 text-center'>
            <img src={showmore} className='cursor-pointer' alt='' />
          </div>
        </div>
      </section>
      <div>
        <div className='d-flex'>
          <div className=' col-md-6 recommand'></div>
          <div
            className='col-md-6 refer'
            onClick={() => history.push('/project/create')}
          ></div>
        </div>
      </div>
      <MainFooter />
    </div>
  )
}
