import React, { useState } from 'react'
import downicon from '../../assets/svg/Group 1110.svg'
export default function Card ({ image, title, noArrow }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <div className={'borderTop row d-flex align-items-center'}>
        <img src={image} width={60} height={50} />
        <h4 className={'ml-2 widthfixed80 text_green'}>{title}</h4>
      </div>
      <p className={'mt-2 mlm-5'}>
        Short descriptive text about the project goes over a couple of lines
        descriptive text about the project goes over a couple of lines Short
        descriptive text about the project goes over a couple of lines.
      </p>
      {isOpen && (
        <div className='mt-2'>
          <p className='text_blue text-weight-bold'>Biotechnology</p>
          <p className='text_blue text-weight-bold'>
            Health Care Equipment & Supplies
          </p>
          <p className='text_blue text-weight-bold'>
            Health Care Providers & Services
          </p>
          <p className='text_blue text-weight-bold'>Health Care Technology</p>
          <p className='text_blue text-weight-bold'>
            Life Sciences Tools & Services
          </p>
          <p className='text_blue text-weight-bold'>Pharmaceuticals</p>
        </div>
      )}
      {!noArrow && (
        <img
          onClick={() => setIsOpen(!isOpen)}
          src={downicon}
          style={{ transform: isOpen && 'rotate(180deg)' }}
          className={'mt-3 mb-4 mlm-5'}
        />
      )}
    </div>
  )
}
