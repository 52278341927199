import React, { useState } from 'react'
import { HomeFooter, MainFooter, HomeHeader, Card } from '../../components'
import Scroll, { Link, Element } from 'react-scroll'
import Humility from '../../assets/svg/Group 6179.svg'
import curiosity from '../../assets/svg/Group 6314.svg'
import excellence from '../../assets/svg/Group 6345.svg'
import diversity from '../../assets/svg/diversity.svg'
import governence from '../../assets/svg/governence.svg'
import social from '../../assets/svg/social.svg'
import Model from '../../assets/svg/Model.svg'
import environment from '../../assets/svg/environment.svg'
import monitor from '../../assets/svg/monitor.svg'
import positioning from '../../assets/svg/Group 7124.svg'
import positioning1 from '../../assets/svg/Group 7123.svg'
import respect from '../../assets/svg/Group 6360.svg'
import screenshot from '../../assets/svg/a.svg'
import microsoft from '../../assets/svg/microsoft.svg'
import stripe from '../../assets/svg/stripe.svg'
import downicon from '../../assets/svg/Group 1110.svg'
import comingsoon from '../../assets/svg/comingsoon.svg'
import inexus from '../../assets/svg/inexus.svg'
import gartner from '../../assets/svg/gartner.svg'
import prosci from '../../assets/svg/prosci.svg'
import VIDEO from '../../assets/video/my.mp4'
import ReactPlayer from 'react-player'
import affairs from '../../assets/svg/affairs.svg'
import image20 from '../../assets/svg/Group 5634.svg'
import image21 from '../../assets/svg/Group 5635.svg'
import image22 from '../../assets/svg/Group 5636.svg'
import './index.css'

export default function WhoChooseUs ({}) {
  const [state, setState] = useState({
    week: ''
  })
  const { week, code, usertype } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const [play, setplay] = useState(false)
  const [played, setPlayed] = useState(false)
  var scroll = Scroll.animateScroll
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      {/* <!-- banner section start --> */}
      <Element name='Element1' className='element'>
        <section class='whoweare'>
          <div class='container height100 d-flex align-items-center mt-5'>
            <div class='banner_content'>
              <h1 className={'font-80-home'}>Why choose us?</h1>
              <p className={'text-white homeSubText mt-3'}>
                We produce results, not decks
              </p>
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      {/* <!-- second section start --> */}
      <Element name='Element2'>
        <section class='bg-grey'>
          <div class='container height100'>
            <div
              class='joinVideo'
              //  onClick={() => goToSignupProcess()}
            >
              <div class='joinVideoBox'>
                {!play && (
                  <span onClick={() => setplay(true)}>
                    <i class='cursor-pointer fas fa-play'></i>
                  </span>
                )
                // :
                // <span onClick={() => setplay(false)}><i class="cursor-pointer fas fa-pause"></i></span>
                }
                <ReactPlayer
                  class={'videoemail'}
                  url={VIDEO}
                  onProgress={e => {
                    console.log(
                      'e.playedSeconds.toFixed(0)',
                      e.playedSeconds.toFixed(0)
                    )
                    if (e.playedSeconds.toFixed(0) == 117) {
                      setPlayed(true)
                      setplay(false)
                    }
                  }}
                  playing={play}
                />

                {/* <video
                                    autoPlay
                                    width={"100%"}
                                    controls
                                    src={VIDEO} /> */}
              </div>
            </div>
            <div className='col-md-12 generation'>
              <h1 className={'text_blue font-open-bold font-mobile-24 mt-4'}>
                Next generation consulting
              </h1>
              <p className={'text_blue col-md-8 phm-1 mb-5 mt-3'}>
                World beating, high-performance consulting tailored to client
                needs for a fraction of the cost through empowered expert
                blanked teams. We build the right engagement teams to help
                clients deliver the value only we can.
              </p>
            </div>
            <div className='row displayMobileHide'>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>Diverse teams</span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>Experts work flexibly</span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>???</span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>Peer to peer expert support</span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>
                  Teams balance complementary skills and aptitudes
                </span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>
                  Consultants are matched to your project on capability and
                  personality
                </span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>
                  Experts enjoy work/life balance
                </span>
              </div>
              <div className='col-md-3'>
                <i class='fas fa-check-circle text_green mr-2'></i>
                <span className='text_blue '>
                  Teams have global and top-tier experience
                </span>
              </div>
            </div>
            <div className='text-center displayMobileHide text_blue mt-3 font-weight-bold'>
              and more…
            </div>
          </div>
        </section>
        <section class=''>
          <div class='container height100 d-flex align-items-center'>
            <div className='col-md-12 generation'>
              <h1 className={'text_blue font-open-bold font-mobile-24 mt-4'}>
                Why we are different - we{' '}
                <span className='displayMobileHide'>
                  empower companies to scale, fast!
                </span>
              </h1>
              <h5 className={'text_green font-weight-bold phm-1 mt-4'}>
                Shortening your 8-10 week procurement process to under 8 minutes
              </h5>
              <p className={'text_blue col-md-8 displayMobileHide mb-5 mt-3'}>
                World beating, high-performance consulting tailored to client
                needs for a fraction of the cost through empowered expert
                blanked teams. We build the right engagement teams to help
                clients deliver the value only we can.
              </p>
            </div>
          </div>
          <div className='container hideWeb'>
            <div class='row container mt-4 align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '1'}
                  onChange={() => handleChange('week', '1')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '1' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week One
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '2'}
                  onChange={() => handleChange('week', '2')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '2' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week Two
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '3'}
                  onChange={() => handleChange('week', '3')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '3' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week Three
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '4'}
                  onChange={() => handleChange('week', '4')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '4' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week Four
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '5'}
                  onChange={() => handleChange('week', '5')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '5' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week Five
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '6'}
                  onChange={() => handleChange('week', '6')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '6' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week Six
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '7'}
                  onChange={() => handleChange('week', '7')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '7' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder`}
              >
                Week Seven
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
            <div className={'verticalLineWidth'} />
            <div class='row container align-items-center justify-content-between'>
              <span className='widthHiehgt'>
                <input
                  class='form-check-input  smallWidth'
                  type='radio'
                  checked={week === '8'}
                  onChange={() => handleChange('week', '8')}
                  value='option1'
                />
              </span>
              <p
                className={`${
                  week === '8' ? 'text_green' : 'text_blue'
                } font-14 font-weight-bolder text-left`}
              >
                Week Eight
              </p>
              <p className='text_blue font-mobile-20'>Project idea</p>
            </div>
          </div>
          <div class='container displayMobileHide'>
            <div className='row align-items-center justify-content-between'>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '1'}
                    onChange={() => handleChange('week', '1')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '1' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week One
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '2'}
                    onChange={() => handleChange('week', '2')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '2' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Two
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '3'}
                    onChange={() => handleChange('week', '3')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '3' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Three
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '4'}
                    onChange={() => handleChange('week', '4')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '4' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Four
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '5'}
                    onChange={() => handleChange('week', '5')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '5' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Five
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input weeks smallWidth'
                    type='radio'
                    checked={week === '6'}
                    onChange={() => handleChange('week', '6')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '6' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Six
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '7'}
                    onChange={() => handleChange('week', '7')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '7' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Seven
                  </span>
                </div>
                <div className={'horizontalLineWidth'} />
              </div>
              <div class='loginGenderRadio d-flex align-items-center'>
                <div class='form-check columnDisplay'>
                  <input
                    class='form-check-input smallWidth weeks'
                    type='radio'
                    checked={week === '8'}
                    onChange={() => handleChange('week', '8')}
                    value='option1'
                  />
                  <span
                    className={`${
                      week === '8' ? 'text_green' : 'text_blue'
                    } font-12 `}
                  >
                    Week Eight
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- second section end --> */}
        <section class='sec-4 ptm-0'>
          <div class='container'>
            <div class='row row-mobile-reverse mt-5'>
              <div class='col-md-6 d-flex align-items-center justify-content-sm-center'>
                <div class='content content-right'>
                  <h3 class=' font-open-bold heading_font_black'>
                    Data headline here
                  </h3>
                  <p>
                    {' '}
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut
                    eium experro videlentiae. Sum fuga.
                  </p>
                  <a href='' class='btn_link link_black'>
                    SEE WHAT ELSE WE CAN OFFER
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>
              <div class='col-md-6 d-flex justify-content-start'>
                <div class='image_box'>
                  <img src={positioning} alt='' />
                </div>
              </div>
            </div>
            <div class='row mt-5'>
              <div class='col-md-6 d-flex justify-content-end'>
                <div class='image_box'>
                  <img src={positioning1} alt='' />
                </div>
              </div>

              <div class='col-md-6 d-flex align-items-center justify-content-sm-center'>
                <div class='content content-left'>
                  <h3 class='heading_font font-open-bold heading_font_black font-mobile-24'>
                    Secure working environment headline
                  </h3>
                  <p>
                    {' '}
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut
                    eium experro videlentiae. Sum fuga.
                  </p>
                  <a href='' class='btn_link link_black'>
                    SEE WHAT ELSE WE CAN OFFER
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>
            </div>
            <div class='row row-mobile-reverse mt-5'>
              <div class='col-md-6 d-flex align-items-center justify-content-sm-center'>
                <div class='content content-right'>
                  <h3 class='heading_font font-open-bold heading_font_black'>On boarding .</h3>
                  <p>
                    {' '}
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut
                    eium experro videlentiae. Sum fuga.
                  </p>
                  <a href='' class='btn_link link_black'>
                    SEE WHAT ELSE WE CAN OFFER
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>
              <div class='col-md-6 d-flex justify-content-start'>
                <div class='image_box'>
                  <img src={positioning} alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- offer section start --> */}
        <section class='offer_section sec_pd '>
          <div class='container'>
            <div className='col-md-12 text-center'>
              <h1
                className={
                  'text_blue font-open-bold font-mobile-24 mt-4 mb-5'
                }
              >
                Egtos’ simplicity in creating{' '}
                <span className='displayMobileHide'>
                  qualified, screened, global team of experts.
                </span>
              </h1>
            </div>
            <div class='row'>
              <div class='col-md-4'>
                <img src={screenshot} alt='' />
                <p className={'text_blue text-center mt-2'}>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines.
                </p>
              </div>
              <div class='col-md-4'>
                <img src={screenshot} alt='' />
                <p className={'text_blue text-center mt-2'}>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines.
                </p>
              </div>
              <div class='col-md-4'>
                <img src={screenshot} alt='' />
                <p className={'text_blue text-center mt-2'}>
                  Short descriptive text about the project goes over a couple of
                  lines descriptive text about the project goes over a couple of
                  lines Short descriptive text about the project goes over a
                  couple of lines.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* <!-- offer section end --> */}
      <Element>
        <section class='bg-grey'>
          <div class='container justify-content-center'>
            <h2 className='text_blue font-open-bold mb-5 font-mobile-24 text-center'>
              Everything you need in one platform
            </h2>
            <div class='row justify-content-between justify-content-sm-around'>
              <div class='text-center col-xs-6 mb-5'>
                <img src={respect} className='widthMobile1' />
                <h4 className={'text_blue mt-2'}>File Centralisation</h4>
              </div>
              <div class='text-center col-xs-6 mb-5'>
                <img src={diversity} className='widthMobile1' />
                <h4 className={'text_blue mt-2'}>Diversity</h4>
              </div>
              <div class='text-center col-xs-6 mb-5'>
                <img src={excellence} className='widthMobile1' />
                <h4 className={'text_blue mt-2'}>App Centralisation</h4>
              </div>
              <div class='text-center col-xs-6 mb-5'>
                <img src={curiosity} className='widthMobile1' />
                <h4 className={'text_blue mt-2'}>Built in time tracking</h4>
              </div>
              <div class='text-center col-xs-6 mb-5'>
                <img src={Humility} className='widthMobile1' />
                <h4 className={'text_blue mt-2'}>Performance Reviews</h4>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <Element>
        <section class='agile-section'>
          <div class='container'>
            <h2 className='text_blue mb-5 font-open-bold text-center'>
              Join our happy clients
            </h2>
            <div class='row justify-content-between'>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon} className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
            </div>
            <div class='row justify-content-around'>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
              <div class='col-md-2 mb-5'>
                <img src={comingsoon}className={"web80Mobile100"} />
              </div>
            </div>
          </div>
        </section>
      </Element>

      {/* <!--section 4 end--> */}

      <div className='hideWeb'>
        <HomeFooter displayMobileHide />
      </div>
      <MainFooter />
    </div>
  )
}
