import React, { useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import LogoImage from '../../assets/img/LogoImage.png'
import LogoImage1 from '../../assets/img/LogoImage1.png'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import { Redo } from '@mui/icons-material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import Admocs from '../../assets/img/Admocs.png'
import BAR from '../../assets/img/BAR.png'
import IBM from '../../assets/img/IBM.png'
import { useHistory } from 'react-router-dom'
const list = [
  { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' },
  { image: LogoImage1, name: 'Stephen Holmes', sub: 'Turkey' },
  { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' },
  { image: LogoImage1, name: 'Stephen Holmes', sub: 'Turkey' },
  { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' }
]

const MuiAccordion = styled(props => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none'
  },
  marginBottom: 10
}))

const MuiAccordionSummary = styled(props => (
  <AccordionSummary expandIcon={false} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)'
}))

export default function ProjectOverview ({}) {
  const history = useHistory()
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChangeAccordion = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [state, setState] = useState({
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  })
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  var scroll = Scroll.animateScroll
  return (
    <div>
      <MainHeader />

      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class=''>
              <div class='content'>
                <div className='row justify-content-between align-items-start'>
                  <div className='col-md-6 ph-0 col-xs-12'>
                    <img src={IBM} />
                    <h4 class='heading_font_black mt-5'>Project name 1</h4>
                    <h4 class='text_green mt-3'>Brief</h4>
                    <p className='text_blue'>
                      In 2 minutes or less, please tell us how you would help
                      clients accelerate strategy execution to enable growth.
                      Please give tangible examples such as: I’ve led at least
                      four post-merger integration, I enjoy transforming
                      clinical trial operations, Agile operating model is my
                      forte, helping companies build internal change management
                      capability is what clients call me for, I help transform
                      legacy banking infrastructure to 21st digital power-house,
                      etc.
                    </p>
                    <button class={`btn btn-primary mt-5 blue-btn`}>
                      {'Update brief'}
                    </button>
                  </div>
                  <div className='col-md-3 mtm-5'>
                    <div class='content'>
                      <p className='text_blue font-weight-bold font-16'>
                        PROGRESS & DEADLINES
                      </p>
                      <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className=''>
                          <p>{'-'}</p>
                          <p className='text_green font-18 mt-1'>0%</p>
                        </div>
                        <div className=''>
                          <p className='text_blue'>Next</p>
                          <p className='text_blue_opacity'>00.00.2021</p>
                        </div>
                        <div className=''>
                          <p className='text_blue'>Final</p>
                          <p className='text_blue_opacity'>00.00.2021</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
      <section class='sec-4'>
        <div class='container'>
          <p class='text_blue font-24'>Overview</p>
          <div className='overviewDiv '>
            <div className='typeDiv col-sm-12'>
              <p className='text_blue mt-4'>{'Company'}</p>
              <p className='text_blue_opacity font-18'>{'IBM'}</p>
            </div>
          </div>
          <div className='overviewDiv border-top '>
            <div className='typeDiv col-md-6 col-sm-12 border-right'>
              <p className='text_blue'>{'Project'}</p>
              <p className='text_blue_opacity font-24'>
                {'Strategy Development'}
              </p>
            </div>
            <div className='col-md-6 col-sm-12'>
              <p className='text_blue'>{'Business Function'}</p>
              <p className='text_blue_opacity font-24'>{'Finance'}</p>
            </div>
          </div>

          <div className='overviewDiv border-top'>
            <div className='typeDiv col-md-3 col-sm-12 border-right'>
              <p className='text_blue'>{'Start Date:'}</p>
              <p className='text_blue_opacity font-24'>{'00/00/2021'}</p>
            </div>
            <div className='typeDiv col-md-3 col-sm-12 border-right'>
              <p className='text_blue'>{'End Date:'}</p>
              <p className='text_blue_opacity font-24'>{'00/00/2021'}</p>
            </div>
            <div className='typeDiv col-md-3 col-sm-12 border-right'>
              <p className='text_blue'>{'Team members:'}</p>
              <p className='text_blue_opacity font-24'>{'5'}</p>
            </div>
            <div className='col-md-3 col-sm-12'>
              <p className='text_blue'>{'Balanced Expert Team Score:'}</p>
              <p className='text_blue_opacity font-24'>{'98%'}</p>
            </div>
          </div>
          <div className='overviewDiv border-top'>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Budget:'}</p>
              <p className='text_blue_opacity font-24'>{'$000,000'}</p>
            </div>
            <div className='typeDiv col-md-4 col-sm-12 border-right'>
              <p className='text_blue'>{'Business registration number:'}</p>
              <p className='text_blue_opacity font-24'>{'0000000000000'}</p>
            </div>
            <div className='col-md-4 col-sm-12'>
              <p className='text_blue'>{'Pre-screen:'}</p>
              <p className='text_blue_opacity font-24'>{'No'}</p>
            </div>
          </div>
          <div className='overviewDiv border-top'>
            <div className='typeDiv col-sm-12'>
              <p className='text_blue'>{'Additional Language Requirements:'}</p>
              <p className='text_blue_opacity font-24'>
                {'Spanish, Mandarin (Chinese) & Hindi'}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class='col-md-12'>
              <div class='content'>
                <h4 class=' heading_font_black mt-4 mb-5'>The Team</h4>
                {list.map((item, index) => (
                  <MuiAccordion
                    expanded={expanded === index}
                    onChange={handleChangeAccordion(index)}
                  >
                    <MuiAccordionSummary
                      aria-controls='panel1d-content'
                      id='panel1d-header'
                      style={{
                        backgroundColor:
                          index === 0
                            ? 'rgba(0, 171, 231, 0.05)'
                            : 'rgba(0, 0, 0, .03)'
                      }}
                    >
                      {/* <div className='teamBox'> */}
                      <div className='row' style={{ width: '30%' }}>
                        <img
                          src={item.image}
                          className='logoImage mr-2'
                          alt={''}
                        />
                        <div>
                          <h5 className='text_blue mb-2'>{item.name}</h5>
                          <p className='text_blue_opacity reduceMargin font-16'>
                            {item.sub}
                          </p>
                          <p className='text_blue_opacity'>View full profile</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='mr-5'>
                          <p className='text_blue'>{'Current level:'}</p>
                          <p className='text_blue_opacity font-18'>
                            {'Associate'}
                          </p>
                        </div>
                        <div className='mr-5'>
                          <p className='text_blue'>{'Client rating:'}</p>
                          <p className='text_blue_opacity font-18'>{'92%'}</p>
                        </div>
                        <div className='mr-5'>
                          <p className='text_blue'>{'Projects completed:'}</p>
                          <p className='text_blue_opacity font-18'>{'17'}</p>
                        </div>
                        <div className='mr-5'>
                          <p className='text_blue'>
                            {'Years of professional experience:'}
                          </p>
                          <p className='text_blue_opacity font-18'>{'20'}</p>
                        </div>
                      </div>
                      <i class='fas fa-redo-alt'></i>
                      {/* </div> */}
                    </MuiAccordionSummary>
                    <MuiAccordionDetails
                      style={{
                        // backgroundColor:
                        //   index === 0
                        //     ? 'rgba(0, 171, 231, 0.1)'
                        //     : 'rgba(0, 0, 0, .1)'
                      }}
                    >
                      <div className='overviewDiv'>
                        <div className='typeDiv1 pb-4 col-md-5 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>{'Education'}</p>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Highest Level'}
                              </p>
                              <p className='text_blue_opacity font-13 reduceMargin'>
                                {
                                  'PhD, Pharmaceutical Science (University of Australia)'
                                }
                              </p>
                            </div>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Industry Certifications'}
                              </p>
                              <p className='text_blue_opacity font-13 reduceMargin'>
                                {
                                  'BDCP – Biopharmaceutical Development Certified Professional PDCP – Pharmaceutical Development Certified Professional'
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='typeDiv1 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4 '>
                            {'Country of citizenship:'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'Mexico'}
                          </p>
                        </div>
                        <div className='typeDiv1 col-md-4 col-sm-12'>
                          <p className='text_blue mt-4'>{'Technology'}</p>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Expert*'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {'R'}
                              </p>
                            </div>
                            <div>
                              <p className='text_blue_opacity font-14 mb-1'>
                                {'Expert*'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {'SASS'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='overviewDiv border-top'>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>{'Current level:'}</p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'Associate'}
                          </p>
                        </div>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4 '>
                            {'Teams average rating:'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'97%'}
                          </p>
                        </div>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>
                            {'Projects completed:'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'17'}
                          </p>
                        </div>
                        <div className='typeDiv pb-4 col-md-3 col-sm-12'>
                          <p className='text_blue mt-4'>{'Client rating:'}</p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'92%'}
                          </p>
                        </div>
                      </div>
                      <div className='overviewDiv border-top'>
                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>
                            {'Years of professional experience::'}
                          </p>
                          <p className='text_blue_opacity font-24 reduceMargin'>
                            {'20'}
                          </p>
                        </div>
                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                          <p className='text_blue mt-4 '>
                            {'Vertical experience (years):'}
                          </p>
                          <p className='text_blue_opacity font-16'>
                            {
                              'Clinical Trial Operations (12) Pharma-covigilance (8)'
                            }
                          </p>
                        </div>
                        <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                          <p className='text_blue mt-4'>
                            {'Previous organisations'}
                          </p>
                          <div className='d-flex justify-content-between align-items-center mt-2'>
                            <img
                              src={Admocs}
                              alt={''}
                              width={'30%'}
                              style={{ height: 25 }}
                            />
                            <img
                              src={IBM}
                              alt={''}
                              width={'25%'}
                              style={{ height: 40 }}
                            />
                            <img
                              src={BAR}
                              alt={''}
                              width={'30%'}
                              style={{ height: 25 }}
                            />
                          </div>
                        </div>
                      </div>
                    </MuiAccordionDetails>
                  </MuiAccordion>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Element>
      <section class='sec-4'>
        <div class='container'>
          <div class='d-flex'>
            <div class='logingroup width30 mr-3'>
              <button
                onClick={() => history.push('/dashboard')}
                class='btn btn-outline-grey-warning btn_grey'
              >
                Cancel
              </button>
            </div>
            <div class='logingroup width30'>
              <button
                onClick={() => history.push('/expert/project')}
                class='btn btn-outline-green-warning'
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </section>

      <MainFooter />
    </div>
  )
}
