import React, { useState, useEffect } from 'react'
import moment from 'moment'

import {
  businessFunctionlist,
  companyList,
  countryList,
  currencyList,
  ethnicityList,
  genderList,
  industryList,
  referencesourceList,
  sectorList,
  projectList
} from '../../api/auth'
import { MainFooter, ClientProcess2, MainHeader } from '../../components'
import { upsertProject } from '../../api/client'
import { getSingleProject } from '../../api/projects'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

export default function CreateProject() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [stateProjectRoles, setStateProjectRoles] = useState({
    projectRoles: []
  })
  const [state, setState] = useState({
    companypayableEmail: '',
    projectName: '',
    projectClassification: '',
    projectRFP: [],
    projectSupportdocs: [],
    budget: '',
    formalGoverance: false,
    onsiteManager: false,
    onsitepercentageengagement: '',
    isProjectManagerAssigned: false,
    expectStartDate1: '',
    expectStartDate2: '',
    expectStartDate3: '',
    expectedEndDate1: '',
    expectedEndDate2: '',
    expectedEndDate3: '',
    primarilyLanguageRequired: false,
    primarilyLanguage1: '',
    primarilyLanguage2: '',
    egtosTeamExpectation: '',
    prescreening: false,
    loading: false,
    update: false,
    process: 'process1'
  })

  const {
    loading,
    companypayableEmail,
    projectName,
    projectClassification,
    projectRFP,
    projectSupportdocs,
    budget,
    formalGoverance,
    onsiteManager,
    onsitepercentageengagement,
    isProjectManagerAssigned,
    expectStartDate1,
    expectStartDate2,
    expectStartDate3,
    expectedEndDate1,
    expectedEndDate2,
    expectedEndDate3,
    primarilyLanguageRequired,
    primarilyLanguage1,
    primarilyLanguage2,
    egtosTeamExpectation,
    prescreening,
    files,
    fileRFP,
    filesupporting,
    project,
    projectRolesSelected,
    businesslist,
    industrylist,
    sectorlist,
    sector,
    industry,
    businessfunction,
    update
  } = state
  const { projectRoles } = stateProjectRoles
  const { id } = useParams()
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  useEffect(() => {
    if (id) {
      handleChange('update', true)
      _getProject()
    } else {
      handleChange('update', false)
    }
    getDropDownLists()
  }, [])
  const _getProject = async () => {
    try {
      handleChange('loading', true)
      const body = { _id: id }
      const resProject = await getSingleProject(body)
      console.log('resProject.data.result', resProject.data.result)
      const project =
        resProject?.data?.result?.length > 0 && resProject?.data?.result[0]
      handleChange('budget', project?.budget)
      handleChange('businessfunction', project?.businessFunction)
      handleChange('projectClassification', project?.projectClassification)
      handleChange('company', project?.company)
      handleChange('companypayableEmail', project?.companypayableEmail)
      handleChange('deliverables', project?.deliverables)
      handleChange('egtosTeamExpectation', project?.egtosTeamExpectation)
      handleChange('expectStartDate1', project?.expectStartDate)
      handleChange('expectedEndDate1', project?.expectedEndDate)
      handleChange(
        'primarilyLanguageRequired',
        project?.primarilyLanguageRequired
      )
      handleChange('formalGoverance', project?.formalGoverance)
      handleChange('industry', project?.industry)
      handleChange('interviewRequired', project?.interviewRequired)
      handleChange('prescreening', project?.prescreening)
      handleChange('projectRolesSelected', project?.projectRoles)
      handleChange(
        'isProjectManagerAssigned',
        project?.isProjectManagerAssigned
      )
      handleChange('isactive', project?.isactive)
      handleChange('primarilyLanguage1', project?.primarilyLanguage1)
      handleChange('primarilyLanguage2', project?.primarilyLanguage2)
      handleChange('projectName', project?.projectName)
      handleChange('sector', project?.sector)
      handleChange(
        'onsitepercentageengagement',
        project?.onsitepercentageengagement
      )
    } catch (error) {
      handleChange('loading', false)
    }
  }

  const getDropDownLists = async () => {
    try {
      handleChange('loading', true)
      const country = await countryList()
      const business = await businessFunctionlist()
      const project = await projectList()
      const company = await companyList()
      const currency = await currencyList()
      const ethnicity = await ethnicityList()
      const gender = await genderList()
      const industry = await industryList()
      const referencesource = await referencesourceList()
      const sector = await sectorList()
      handleChange('countrylist', country.data.result)
      handleChange('businesslist', business.data.result)
      handleChange('companylist', company.data.result)
      handleChange('currencylist', currency.data.result)
      handleChange('ethnicitylist', ethnicity.data.result)
      handleChange('genderlist', gender.data.result)
      handleChange('industrylist', industry.data.result)
      handleChange('referencesourcelist', referencesource.data.result)
      handleChange('sectorlist', sector.data.result)
      handleChange('project', project.data.result)
      handleChange('loading', false)
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error);
      // alert(error.message)
    }
  }
  const handleChangeProjectRoles = (key, value) => {
    setStateProjectRoles(pre => ({ ...pre, [key]: value }))
  }

  const handleCreateClient = async () => {
    try {
      handleChange('loading', true)
      let userData = localStorage.getItem('userData')
      userData = JSON.parse(userData)
      const payload = {
        clientID: userData?._id,
        companypayableEmail,
        projectName,
        projectClassification,
        projectSupportdocs,
        budget,
        formalGoverance,
        onsiteManager,
        onsitepercentageengagement,
        isProjectManagerAssigned,
        expectStartDate: expectStartDate1,
        expectedEndDate: expectedEndDate1,
        primarilyLanguageRequired,
        primarilyLanguage1,
        primarilyLanguage2,
        egtosTeamExpectation,
        prescreening,
        sector,
        industry,
        businessFunction: businessfunction
      }
      if (id) {
        payload['_id'] = id;
        delete payload.clientID;
        delete payload.companypayableEmail;
        delete payload.projectName;
        delete payload.projectSupportdocs
        delete payload.projectClassification;
        delete payload.sector;
        delete payload.industry;
        delete payload.businessFunction;
        console.log(expectStartDate1, 'expectStartDate1');
        console.log(expectedEndDate1, 'expectedEndDate1');
        if (moment(expectStartDate1).toString() == 'Invalid date') {

          delete payload.expectStartDate;
        }
        if (moment(expectedEndDate1).toString() == 'Invalid date') {
          delete payload.expectedEndDate;
        }
        // delete payload.expectStartDate;
        // delete payload.expectedEndDate;
      }
      console.log('payload', payload)
        const res = await upsertProject(payload)
        if (res.data.result) {
          handleChange('loading', false)
          // alert("Project has been created!")


          history.push({
            pathname: '/project/team/options',
            state: {
              projectid: id,
              clientid: userData?._id || localStorage.getItem('_id')
            }
          });



        } else {
          handleChange('loading', false)
          enqueueSnackbar(`Error: ${res.data.name}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })
        }
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error);
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }

  return (
    <div>
      <MainHeader />
      <ClientProcess2
        onClickButton={handleCreateClient}
        handleChange={handleChange}
        companypayableEmail={companypayableEmail}
        projectName={projectName}
        projectClassification={projectClassification}
        projectRFP={projectRFP}
        projectSupportdocs={projectSupportdocs}
        budget={budget}
        loading={loading}
        formalGoverance={formalGoverance}
        onsiteManager={onsiteManager}
        onsitepercentageengagement={onsitepercentageengagement}
        isProjectManagerAssigned={isProjectManagerAssigned}
        primarilyLanguageRequired={primarilyLanguageRequired}
        primarilyLanguage1={primarilyLanguage1}
        primarilyLanguage2={primarilyLanguage2}
        egtosTeamExpectation={egtosTeamExpectation}
        prescreening={prescreening}
        project={project}
        sectorlist={sectorlist}
        expectStartDate1={expectStartDate1}
        expectedEndDate1={expectedEndDate1}
        sector={sector}
        industry={industry}
        businessfunction={businessfunction}
        businesslist={businesslist}
        industrylist={industrylist}
        projectRoles={projectRoles}
        projectRolesSelected={projectRolesSelected}
        fileRFP={fileRFP}
        createproject={true}
        update={update}
        handleChangeProjectRoles={handleChangeProjectRoles}
      />
      <MainFooter />
    </div>
  )
}
