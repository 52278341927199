import React, { useEffect, useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import LogoImage from '../../assets/img/LogoImage.png'
import LogoImage1 from '../../assets/img/LogoImage1.png'
import Scroll, { Element } from 'react-scroll'
import './index.css'
import { useHistory } from 'react-router'
import { Redo } from '@mui/icons-material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import Admocs from '../../assets/img/Admocs.png'
import BAR from '../../assets/img/BAR.png'
import IBM from '../../assets/img/IBM.png'
import {
  upsertClient,
  getteammatching,
  getteamfeasibility,
  getclienttagsengine,
  generateclientteams
} from '../../api/client'
import { Loader } from 'rsuite'
import { useSnackbar } from 'notistack'
const list = [
  { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' },
  { image: LogoImage1, name: 'Stephen Holmes', sub: 'Turkey' },
  { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' },
  { image: LogoImage1, name: 'Stephen Holmes', sub: 'Turkey' },
  { image: LogoImage, name: 'Anil Taneja', sub: 'Mexico | Project Leader' }
]

const MuiAccordion = styled(props => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none'
  },
  marginBottom: 20
}))

const MuiAccordionSummary = styled(props => (
  <AccordionSummary expandIcon={false} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)'
}))

export default function TeamOptions ({}) {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const projectid =
    history?.location?.state?.projectid || '622d7b71bde89816581dc377'
  const [expanded, setExpanded] = React.useState('panel1')
  const _id = history?.location?.state?.clientid || '621b450486bb099bc07115ad'
  // const projectid = localStorage.getItem('projectid') || '622d7b71bde89816581dc377'
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    console.log()
    setValue(newValue)
  }

  const handleChangestate = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const changeteamindex = () => {
    console.log(teamindex)
    console.log(teams.length)
    if (teamindex == teams.length - 2) {
      handleChangestate('teamindex', 0)
    } else {
      handleChangestate('teamindex', teamindex + 1)
    }
  }

  const handleChangeAccordion = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [state, setState] = useState({
    loading: false,
    roles: [],
    teamindex: 0,
    teams: [],
    project: {},
    projectleaderindex: 0
  })

  const {
    loading,
    teamindex,
    teams,
    roles,
    project,
    projectleaderindex
  } = state

  const selectedTeam = selectedteam => {
    console.log(selectedteam, 'team')

    history.push({
      pathname: '/project/team/choosen',
      state: {
        selectedteam: selectedteam,
        project: project
      }
    })
  }

  useEffect(() => {
    getTeams()
  }, [])

  const swap = (x, y) => ([...xs]) =>
    xs.length > 1 ? (([xs[x], xs[y]] = [xs[y], xs[x]]), xs) : xs

  const getTeams = async () => {
    try {
      handleChangestate('loading', true)
      const teampayload = {
        _id: _id,
        projectid: projectid
      }
      try {
        const clienttagsres = await getclienttagsengine(teampayload)
        handleChangestate('project', clienttagsres.data.result)
      } catch (ex) {
        enqueueSnackbar(`Client team Building Failed  `, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        handleChangestate('loading', false)
      }

      try {
        const feasbilityres = await getteamfeasibility(teampayload)
        if (feasbilityres.data.result) {
          var verdict = feasbilityres.data.result.teamfeasibility.verdict
          console.log(verdict)
          console.log(feasbilityres.data.result.teamfeasibility)
          var Reason = feasbilityres.data.result.teamfeasibility.Reason
          if (verdict == 'Project Building is Feasible') {
            try {
              enqueueSnackbar(verdict, {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              })
              const matchingres = await getteammatching(teampayload)
              console.log(matchingres)

              if (matchingres.data.result) {
                console.log(matchingres.data.result)
                enqueueSnackbar('Teams are Available', {
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                })

                const finalteams = await generateclientteams(teampayload)
                if (finalteams.data.result) {
                  var roleskeys = Object.keys(finalteams.data.result.rolesdata)
                  handleChangestate('roles', roleskeys)

                  var projectleaderidx = roleskeys.findIndex(element => {
                    if (element.includes('Project Leader')) {
                      return true
                    }
                  })
                  console.log(projectleaderidx, 'projectleaderidx')
                  handleChangestate('projectleaderindex', projectleaderidx)
                  finalteams.data.result.teams.forEach(element => {
                    element.forEach((expert, index) => {
                      expert.role = roleskeys[index]
                    })
                    ;[element[projectleaderidx], element[0]] = [
                      element[0],
                      element[projectleaderidx]
                    ]
                  })
                  console.log(roles, 'roles')
                  console.log(finalteams.data.result, 'teams')

                  handleChangestate('teams', finalteams.data.result.teams)
                  handleChangestate('loading', false)
                }
              } else {
                console.log(`Team Matching  Failed `)
                handleChangestate('loading', false)
                enqueueSnackbar(`Team Matching  Failed `, {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  }
                })
              }
            } catch (ex) {
              enqueueSnackbar(`Team Matching Failed  `, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              })
              handleChangestate('loading', false)
            }
          } else {
            handleChangestate('loading', false)
            enqueueSnackbar(`Project Building is not Feasible as ${Reason}`, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            })
            history.push('/project/edit/' + projectid)
          }
        } else {
          handleChangestate('loading', false)
          enqueueSnackbar(`Project Building is not Feasible as ${Reason}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })
          history.push('/project/edit/' + projectid)
        }
      } catch (ex) {
        enqueueSnackbar(`Team Feasibility Failed  `, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        handleChangestate('loading', false)
      }
    } catch (error) {
      enqueueSnackbar(`Issue in Team Building `, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
      handleChangestate('loading', false)
    }
  }

  console.log('teams', teams)

  var scroll = Scroll.animateScroll
  return (
    <div>
      <MainHeader />

      <Element name='Element3'>
        <section
          class='sec-4'
          style={{ minHeight: teams.length === 0 ? '80vh' : '73vh' }}
        >
          <div class='container'>
            <h4 class='heading_font_black'>
              Welcome here's an overview of <br /> your team options…
            </h4>

            <div class='row justify-content-between'>
              <div class='col-md-5'>
                <div class='content'>
                  {teams.length != 0 && (
                    <div className='teamOptionToggle mt-4 mb-5'>
                      <h4 class='heading_font_black'>Team {teamindex + 1}</h4>
                      <input
                        // className='form-check-input'
                        type='radio'
                        onChange={() => selectedTeam(teams[teamindex])}
                        value='option2'
                      />
                    </div>
                  )}
                  {teams.length != 0 &&
                    teams[teamindex] != null &&
                    teams[teamindex].map((item, index) => (
                      <MuiAccordion
                        expanded={expanded === index}
                        onChange={handleChangeAccordion(index)}
                      >
                        <MuiAccordionSummary
                          aria-controls='panel1d-content'
                          id='panel1d-header'
                        >
                          {/* <div className='teamBox'> */}
                          <div className='row'>
                            <img
                              src={item.image || LogoImage1}
                              className='logoImage mr-2'
                              alt={''}
                            />
                            <div>
                              <h5 className='text_blue mb-2'>
                                {item.firstName + ' ' + item.lastName}
                              </h5>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {item.role}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {typeof item.ai_data.wss === 'number'
                                  ? 'WSS : ' + item.ai_data.wss.toFixed(2)
                                  : 'WSS : ' + item.ai_data.wss}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {typeof item.ai_data.wss === 'number'
                                  ? 'Value Score : ' +
                                    item.ai_data.value_score.toFixed(2)
                                  : 'Value Score : ' + item.value_score.wss}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {typeof item.ai_data.wss === 'number'
                                  ? 'bets : ' + item.ai_data.bets.toFixed(2)
                                  : 'bets : ' + item.ai_data.bets}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {item.livingCountry}
                              </p>
                              <p className='text_blue_opacity'>
                                View full profile
                              </p>
                            </div>
                          </div>
                          <i class='fas fa-redo-alt'></i>
                          {/* </div> */}
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                          <div className='overviewDiv'>
                            <div className='typeDiv1 pb-4 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>
                                {'Country of citizenship:'}
                              </p>
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <p className='text_blue_opacity font-14'>
                                    {item.livingCountry}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Planning : ' +
                                      item.ai_data.wss_data.planning}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Collaborating : ' +
                                      item.ai_data.wss_data.collaborating}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Collaborating2 : ' +
                                      item.ai_data.wss_data.collaborating2}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Inspiration : ' +
                                      item.ai_data.wss_data.inspiration}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='typeDiv1 pb-4 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>{'bets Scores*'}</p>
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <p className='text_blue_opacity font-14'>
                                    {'Listening : ' +
                                      item.ai_data.bets_data.listening}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Communication : ' +
                                      item.ai_data.bets_data.communication}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Management : ' +
                                      item.ai_data.bets_data.management}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Analysis : ' +
                                      item.ai_data.bets_data.analysis}
                                  </p>
                                  <p className='text_blue_opacity font-14'>
                                    {'Thinking : ' +
                                      item.ai_data.bets_data.thinking}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className='typeDiv1 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4 '>
                                {'Country of citizenship:'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {item.livingCountry}
                              </p>
                            </div>
                            <div className='typeDiv1 col-md-6 col-sm-12'>
                              <p className='text_blue mt-4'>{'WSS Scores*'}</p>
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <p className='text_blue mt-4'>
                                    {'planning : ' +
                                      item.ai_data.wss_data.planning}
                                  </p>
                                  <p className='text_blue mt-4'>
                                    {'collaborating : ' +
                                      item.ai_data.wss_data.collaborating}
                                  </p>
                                  <p className='text_blue mt-4'>
                                    {'collaborating2 : ' +
                                      item.ai_data.wss_data.collaborating2}
                                  </p>
                                  <p className='text_blue mt-4'>
                                    {'inspiration : ' +
                                      item.ai_data.wss_data.inspiration}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='overviewDiv border-top'>
                            <div className='typeDiv pb-4 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>{'bets Scores*'}</p>

                              <p className='text_blue mt-4'>
                                {'listening : ' +
                                  item.ai_data.bets_data.listening}
                              </p>
                              <p className='text_blue mt-4'>
                                {'communication : ' +
                                  item.ai_data.bets_data.communication}
                              </p>
                              <p className='text_blue mt-4'>
                                {'management : ' +
                                  item.ai_data.bets_data.management}
                              </p>
                              <p className='text_blue mt-4'>
                                {'analysis : ' +
                                  item.ai_data.bets_data.analysis}
                              </p>
                              <p className='text_blue mt-4'>
                                {'thinking : ' +
                                  item.ai_data.bets_data.thinking}
                              </p>
                            </div>
                            <div className='typeDiv1 pb-4 col-md-6 col-sm-12'>
                              <p className='text_blue mt-4'>
                                {'value scores*'}
                              </p>

                              <p className='text_blue mt-4'>
                                {'respect : ' +
                                  item.ai_data.value_score_data.respect}
                              </p>
                              <p className='text_blue mt-4'>
                                {'diversity : ' +
                                  item.ai_data.value_score_data.diversity}
                              </p>

                              <p className='text_blue mt-4'>
                                {'excellence : ' +
                                  item.ai_data.value_score_data.excellence}
                              </p>

                              <p className='text_blue mt-4'>
                                {'learning : ' +
                                  item.ai_data.value_score_data.learning}
                              </p>
                              <p className='text_blue mt-4'>
                                {'humility : ' +
                                  item.ai_data.value_score_data.humility}
                              </p>
                            </div> */}
                          </div>
                          <div className='overviewDiv border-top'>
                            <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>
                                {'Value Scores*'}
                              </p>
                              <p className='text_blue_opacity font-14'>
                                {'Respect : ' +
                                  item.ai_data.value_score_data.respect}
                              </p>
                              <p className='text_blue_opacity font-14'>
                                {'Diversity : ' +
                                  item.ai_data.value_score_data.diversity}
                              </p>

                              <p className='text_blue_opacity font-14'>
                                {'Excellence : ' +
                                  item.ai_data.value_score_data.excellence}
                              </p>

                              <p className='text_blue_opacity font-14'>
                                {'Learning : ' +
                                  item.ai_data.value_score_data.learning}
                              </p>
                              <p className='text_blue_opacity font-14'>
                                {'Humility : ' +
                                  item.ai_data.value_score_data.humility}
                              </p>
                            </div>
                            {/* <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                              <p className='text_blue mt-4 '>
                                {'Vertical experience (years):'}
                              </p>
                              <p className='text_blue_opacity font-16'>
                                {
                                  'Clinical Trial Operations (12) Pharma-covigilance (8)'
                                }
                              </p>
                            </div> */}
                            <div className='typeDiv1 pb-4 col-md-8 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>
                                {'Previous organisations'}
                              </p>
                              <div className='d-flex justify-content-between align-items-center mt-2'>
                                <img
                                  src={Admocs}
                                  alt={''}
                                  width={'30%'}
                                  style={{ height: 25 }}
                                />
                                <img
                                  src={IBM}
                                  alt={''}
                                  width={'25%'}
                                  style={{ height: 40 }}
                                />
                                <img
                                  src={BAR}
                                  alt={''}
                                  width={'30%'}
                                  style={{ height: 25 }}
                                />
                              </div>
                            </div>
                          </div>
                        </MuiAccordionDetails>
                      </MuiAccordion>
                    ))}
                </div>
              </div>

              <div class='col-md-5'>
                <div class='content'>
                  {teams.length != 0 && (
                    <div className='teamOptionToggle mt-4 mb-5'>
                      <h4 class='heading_font_black'>Team {teamindex + 2}</h4>
                      <input
                        // className='form-check-input'
                        type='radio'
                        onChange={() => selectedTeam(teams[teamindex + 1])}
                        value='option2'
                      />
                    </div>
                  )}
                  {teams.length != 0 &&
                    teams[teamindex + 1] != null &&
                    teams[teamindex + 1].map((item, index) => (
                      <MuiAccordion
                        expanded={expanded === index + '1'}
                        onChange={handleChangeAccordion(index + '1')}
                      >
                        <MuiAccordionSummary
                          aria-controls='panel1d-content'
                          id='panel1d-header'
                        >
                          {/* <div className='teamBox'> */}
                          <div className='row'>
                            <img
                              src={item.image || LogoImage1}
                              className='logoImage mr-2'
                              alt={''}
                            />
                            <div>
                              <h5 className='text_blue mb-2'>
                                {item.firstName + ' ' + item.lastName}
                              </h5>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {item.role}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {typeof item.ai_data.wss === 'number'
                                  ? 'WSS : ' + item.ai_data.wss.toFixed(2)
                                  : 'WSS : ' + item.ai_data.wss}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {typeof item.ai_data.wss === 'number'
                                  ? 'Value Score : ' +
                                    item.ai_data.value_score.toFixed(2)
                                  : 'Value Score : ' + item.value_score.wss}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {typeof item.ai_data.wss === 'number'
                                  ? 'bets : ' + item.ai_data.bets.toFixed(2)
                                  : 'bets : ' + item.ai_data.bets}
                              </p>
                              <p className='text_blue_opacity reduceMargin font-16'>
                                {item.livingCountry}
                              </p>
                              <p className='text_blue_opacity'>
                                View full profile
                              </p>
                            </div>
                          </div>
                          <i class='fas fa-redo-alt'></i>
                          {/* </div> */}
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                          <div className='overviewDiv'>
                            {/* <div className='typeDiv1 pb-4 col-md-5 col-sm-12 border-right'>
                                                        <p className='text_blue mt-4'>{"Education"}</p>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <p className='text_blue_opacity font-14 mb-1'>{"Highest Level"}</p>
                                                                <p className='text_blue_opacity font-13 reduceMargin'>{"PhD, Pharmaceutical Science (University of Australia)"}</p>
                                                            </div>
                                                            <div>
                                                                <p className='text_blue_opacity font-14 mb-1'>{"Industry Certifications"}</p>
                                                                <p className='text_blue_opacity font-13 reduceMargin'>{"BDCP – Biopharmaceutical Development Certified Professional PDCP – Pharmaceutical Development Certified Professional"}</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                            <div className='typeDiv1 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4 '>
                                {'Country of citizenship:'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {item.livingCountry}
                              </p>
                            </div>
                            <div className='typeDiv1 col-md-6 col-sm-12'>
                              <p className='text_blue mt-4'>{'WSS Scores*'}</p>
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <p className='text_blue mt-4'>
                                    {'planning : ' +
                                      item.ai_data.wss_data.planning}
                                  </p>
                                  <p className='text_blue mt-4'>
                                    {'collaborating : ' +
                                      item.ai_data.wss_data.collaborating}
                                  </p>
                                  <p className='text_blue mt-4'>
                                    {'collaborating2 : ' +
                                      item.ai_data.wss_data.collaborating2}
                                  </p>
                                  <p className='text_blue mt-4'>
                                    {'inspiration : ' +
                                      item.ai_data.wss_data.inspiration}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className='overviewDiv border-top'>
               
                            <div className='typeDiv pb-4 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>
                                {'Projects completed:'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {''}
                              </p>
                            </div>
                            <div className='typeDiv pb-4 col-md-6 col-sm-12'>
                              <p className='text_blue mt-4'>
                                {'Client rating:'}
                              </p>
                              <p className='text_blue_opacity font-24 reduceMargin'>
                                {''}
                              </p>
                            </div>
                          </div> */}
                          <div className='overviewDiv border-top'>
                            <div className='typeDiv pb-4 col-md-6 col-sm-12 border-right'>
                              <p className='text_blue mt-4'>{'bets Scores*'}</p>

                              <p className='text_blue mt-4'>
                                {'listening : ' +
                                  item.ai_data.bets_data.listening}
                              </p>
                              <p className='text_blue mt-4'>
                                {'communication : ' +
                                  item.ai_data.bets_data.communication}
                              </p>
                              <p className='text_blue mt-4'>
                                {'management : ' +
                                  item.ai_data.bets_data.management}
                              </p>
                              <p className='text_blue mt-4'>
                                {'analysis : ' +
                                  item.ai_data.bets_data.analysis}
                              </p>
                              <p className='text_blue mt-4'>
                                {'thinking : ' +
                                  item.ai_data.bets_data.thinking}
                              </p>
                            </div>
                            <div className='typeDiv1 pb-4 col-md-6 col-sm-12'>
                              <p className='text_blue mt-4'>
                                {'value scores*'}
                              </p>

                              <p className='text_blue mt-4'>
                                {'respect : ' +
                                  item.ai_data.value_score_data.respect}
                              </p>
                              <p className='text_blue mt-4'>
                                {'diversity : ' +
                                  item.ai_data.value_score_data.diversity}
                              </p>

                              <p className='text_blue mt-4'>
                                {'excellence : ' +
                                  item.ai_data.value_score_data.excellence}
                              </p>

                              <p className='text_blue mt-4'>
                                {'learning : ' +
                                  item.ai_data.value_score_data.learning}
                              </p>
                              <p className='text_blue mt-4'>
                                {'humility : ' +
                                  item.ai_data.value_score_data.humility}
                              </p>
                            </div>
                          </div>
                          {/* <div className='overviewDiv border-top'>
                                                    <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                                                        <p className='text_blue mt-4'>{"Years of professional experience::"}</p>
                                                        <p className='text_blue_opacity font-24 reduceMargin'>{"20"}</p>
                                                    </div>
                                                    <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                                                        <p className='text_blue mt-4 '>{"Vertical experience (years):"}</p>
                                                        <p className='text_blue_opacity font-16'>{"Clinical Trial Operations (12) Pharma-covigilance (8)"}</p>
                                                    </div>
                                                    <div className='typeDiv1 pb-4 col-md-4 col-sm-12 border-right'>
                                                        <p className='text_blue mt-4'>{"Previous organisations"}</p>
                                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                                            <img src={Admocs} alt={""} width={"30%"} style={{ height: 25 }} />
                                                            <img src={IBM} alt={""} width={"25%"} style={{ height: 40 }} />
                                                            <img src={BAR} alt={""} width={"30%"} style={{ height: 25 }} />
                                                        </div>
                                                    </div>
                                                </div> */}
                        </MuiAccordionDetails>
                      </MuiAccordion>
                    ))}
                </div>
              </div>
            </div>

            {/* <div class="logingroup mt-5">
                            <button
                                onClick={() => history.push('/project/team/choosen')}
                                class="btn btn-outline-warning">Confirm</button>
                        </div> */}
            {teams.length != 0 && (
              <div class='question_box position-right mt-5'>
                <button
                  onClick={() => changeteamindex()}
                  class='btn btn-outline-warning'
                >
                  Load more Teams
                </button>
              </div>
            )}
          </div>
        </section>
        {loading ? (
          <div className='loadingTeam'>
            <Loader size={'lg'} center />
          </div>
        ) : null}
      </Element>

      <MainFooter />
    </div>
  )
}
