import React from 'react'
import { InsightFooter, MainFooter, HomeHeader } from '../../components'
import PortImage from '../../assets/img/port_img.png'
import insight3 from '../../assets/img/insight3.png'
import insight4 from '../../assets/img/insight4.png'
import insight5 from '../../assets/img/insight5.png'
import green_energey from '../../assets/img/green_energey.png'
import Scroll, { Link, Element } from 'react-scroll'
import './index.css'
import { useHistory } from 'react-router-dom'

export default function Insight ({}) {
  const history = useHistory()
  var scroll = Scroll.animateScroll
  return (
    <div>
      <HomeHeader blueTop bgBlackLight />
      {/* <!-- banner section start --> */}
      <Element name='Element1' className='element'>
        <section class='top_banner insights'>
          <div class='container height100 d-flex align-items-center mt-3'>
            <div class='banner_content'>
              <h1 className='font-80-home'>Insights</h1>
              <p className={'text-white homeSubText'}>
                Crowd sourcing, impactful insights
              </p>
              {/* <div className='readmoreBox'>
                <a href='' class='underlineNone'>
                  READ MORE<i class='ml-4 fas fa-arrow-right'></i>
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </Element>
      {/* <!-- banner section end --> */}

      {/* <!-- offer section end --> */}

      {/* <!--section 4 start--> */}
      <Element name='Element3'>
        <section class='sec-4'>
          <div
            class='container cursor-pointer'
            onClick={() => history.push('/shipping')}
          >
            <div class='row row-mobile-reverse'>
              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-custom justify-content-sm-center'>
                <div class='content content-right'>
                  <h1 class='heading_font heading_font_black'>
                    Shipping impact
                  </h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>

              <div class='col-md-6 ph-0 phm-0 d-flex justify-content-start'>
                <div class='image_box'>
                  <img src={PortImage} alt='' />
                </div>
              </div>
            </div>

            <div class='row mt-5'>
              <div class='col-md-6 d-flex ph-0 phm-0 justify-content-end'>
                <div class='image_box'>
                  <img src={green_energey} alt='' />
                </div>
              </div>

              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-start'>
                <div class='content content-left'>
                  <h1 class='heading_font heading_font_black'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class='container mt-5'>
            <div class='row row-mobile-reverse'>
              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-custom justify-content-sm-center'>
                <div class='content content-right'>
                  <h1 class='heading_font heading_font_black'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                  {/* <div>
                                        <Link to="Element4" spy={true} smooth={true}>
                                            <i class="text-black slide-arrow fas fa-arrow-down"></i>
                                        </Link>
                                    </div> */}
                </div>
              </div>

              <div class='col-md-6 ph-0 phm-0 d-flex justify-content-start'>
                <div class='image_box'>
                  <img src={insight3} alt='' />
                </div>
              </div>
            </div>

            <div class='row mt-5'>
              <div class='col-md-6 ph-0 phm-0 d-flex justify-content-end'>
                <div class='image_box'>
                  <img src={insight4} alt='' />
                </div>
              </div>

              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-start justify-content-sm-start'>
                <div class='content content-left'>
                  <h1 class='heading_font heading_font_black'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class='container mt-5'>
            <div class='row row-mobile-reverse'>
              <div class='col-md-6 ph-0 d-flex align-items-center justify-content-custom justify-content-sm-center'>
                <div class='content content-right'>
                  <h1 class='heading_font heading_font_black'>Headline (H2)</h1>
                  <p>
                    Conserf erovite ratibeatur aut asitium et eossita taspis aut{' '}
                    <br className='displayMobileHide' />
                    eium experro videlentiae. Sum fuga
                  </p>
                  <a href='' class='btn_link link_black'>
                    See what we can offer{' '}
                    <i class='ml-1 fas fa-arrow-right'></i>{' '}
                  </a>
                  {/* <div>
                                        <Link to="Element4" spy={true} smooth={true}>
                                            <i class="text-black slide-arrow fas fa-arrow-down"></i>
                                        </Link>
                                    </div> */}
                </div>
              </div>

              <div class='col-md-6 ph-0 phm-0 d-flex justify-content-start'>
                <div class='image_box'>
                  <img src={insight5} alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <InsightFooter />
      <MainFooter />
    </div>
  )
}
