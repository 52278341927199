import React from 'react'
import { SearchOutlined, ArrowForward } from '@mui/icons-material'
import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@mui/material'
import UserList from '../../assets/img/userList.png'
import image2 from '../../assets/img/userList2.png'

const list = [
  {
    title:
      'No probs, we’ll share an updated link / file at the end of this week…',
    image: UserList
  },
  {
    title: 'Hi Steve, are you able to take a look at the Financial doc on...',
    image: image2
  },
  {
    title:
      'No probs, we’ll share an updated link / file at the end of this week…',
    image: UserList
  },
  {
    title: 'Hi Steve, are you able to take a look at the Financial doc on...',
    image: image2
  },
  {
    title:
      'No probs, we’ll share an updated link / file at the end of this week…',
    image: UserList
  },
  {
    title: 'Hi Steve, are you able to take a look at the Financial doc on...',
    image: image2
  }
]
function generate (element) {
  return list.map((element, value) =>
    React.cloneElement(element, {
      key: value
    })
  )
}

export default function Messages ({ item }) {
  const [secondary, setSecondary] = React.useState(false)
  const [dense, setDense] = React.useState('Inbox')
  return (
    <section class='sec-4 height100vh'>
      <div class='container'>
        <div class='row'>
          <div class='col-md-12'>
            <div class='content'>
              <div class='d-flex align-items-center justify-content-between'>
                <div className='d-flex'>
                  <h5
                    className={`${
                      dense === 'Inbox' ? 'text_green' : 'text_grey_dark'
                    } cursor-pointer mr-3`}
                    onClick={() => setDense('Inbox')}
                  >
                    Inbox{' '}
                  </h5>
                  <h5
                    className={`${
                      dense === 'Sent' ? 'text_green' : 'text_grey_dark'
                    } cursor-pointer mr-3`}
                    onClick={() => setDense('Sent')}
                  >
                    Sent
                  </h5>
                  <h5
                    className={`${
                      dense === 'Compose' ? 'text_green' : 'text_grey_dark'
                    } cursor-pointer`}
                    onClick={() => setDense('Compose')}
                  >
                    Compose
                  </h5>
                </div>
                <div class='searchInput'>
                  <input
                    type='text'
                    // onChange={(value) => handleChange('voucher', value.target.value)}
                    formcontrolname='text'
                    placeholder='Search'
                  />
                  <SearchOutlined />
                </div>
              </div>
            </div>
          </div>
        </div>

        <List>
          {item?.messages?.map((message, index) => (
            <ListItem
              secondaryAction={
                <div className='row align-items-center'>
                  <p className='mr-2'>00/00/00</p>
                  <p className='mr-5 mt-0'>00:00</p>
                  <p className='mt-0 ml-5'>View</p>
                  <IconButton edge='end' aria-label='delete'>
                    <ArrowForward />
                  </IconButton>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar src={UserList} />
              </ListItemAvatar>
              <ListItemText
                className='hoverGreen'
                primary={
                  'No probs, we’ll share an updated link / file at the end of this week…'
                }
                secondary={secondary ? 'Secondary text' : null}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </section>
  )
}
