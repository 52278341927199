import React from 'react'
import {
  SearchOutlined,
  ArrowForward,
  KeyboardArrowDown
} from '@mui/icons-material'
import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@mui/material'
import UserList from '../../assets/img/userList.png'
import PDFImage from '../../assets/svg/PDFImage.svg'
import { useHistory } from 'react-router-dom'

function generate (element) {
  return [0, 1, 2, 0, 1, 2, 0, 1, 2].map(value =>
    React.cloneElement(element, {
      key: value
    })
  )
}

export default function Deliverables () {
  const history = useHistory()

  const [secondary, setSecondary] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  return (
    <>
      <section class='sec-4'>
        <div class='container'>
          {!submitted ? (
            <div class=''>
              <div class='col-md-6'>
                <div class='content'>
                  <p className='heading_font heading_font_black'>
                    All files submitted{' '}
                  </p>
                  <p>
                    Short descriptive text about the project goes over a couple
                    of lines descriptive text about the project goes over a
                    couple of lines Short descriptive text about the project
                    goes over a couple of lines descriptive text about the
                    project goes over a couple of lines or so, something like
                    this short descriptive text...
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div class='row'>
                <div class='col-md-12 mb-3 mt-0'>
                  <div class='content'>
                    <div class='d-flex align-items-center justify-content-between'>
                      <div className='d-flex'>
                        <h5 className='text_green mr-2'>Mine </h5>
                        <h5>Team</h5>
                      </div>
                      <div class='searchInput'>
                        <input
                          type='text'
                          // onChange={(value) => handleChange('voucher', value.target.value)}
                          formcontrolname='text'
                          placeholder='Search'
                        />
                        <SearchOutlined />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='row'>
                <div class='col-md-12 mb-3 mt-0'>
                  <div class='content'>
                    <div class='d-flex align-items-center justify-content-between'>
                      <div className='d-flex'>
                        <p className='mr-2 font-small'>
                          TYPE <KeyboardArrowDown />
                        </p>
                        <p className='mt-0 font-small'>
                          NAME <KeyboardArrowDown />
                        </p>
                      </div>
                      <div className='d-flex'>
                        <p className='mr-2 font-small'>
                          WHO <KeyboardArrowDown />
                        </p>
                        <p className='mt-0 font-small mr-5'>
                          DATE <KeyboardArrowDown />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <section className='bg-blue-light'>
        <div class='container'>
          <div class='col-md-6'>
            <p className='heading_font heading_font_black mt-5'>
              Final documents{' '}
            </p>
          </div>
          <List>
            {generate(
              <ListItem
                secondaryAction={
                  <div className='row align-items-center'>
                    <Avatar src={UserList} className='mr-5' />
                    <p className='mr-2'>00/00/00</p>
                    <p className='mr-5 mt-0'>00:00</p>
                    <p className='mt-0 ml-5'>View</p>
                    <IconButton edge='end' aria-label='delete'>
                      <ArrowForward />
                    </IconButton>
                  </div>
                }
              >
                <ListItemAvatar>
                  <Avatar src={PDFImage} />
                </ListItemAvatar>
                <ListItemText
                  className='hoverGreen'
                  primary='Adu has uploaded a new file'
                  secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
            )}
          </List>
        </div>
      </section>
      <section class='recent_post_sec'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6 showMobile'>
              {/* <div class="gray_box rowMobile border-right">
                                <h3 className='font-mobile-small'>Most recent post (H3)</h3>
                                <i class="ml-1 font-mobile-small mobile-mr-4 fas fa-arrow-left"></i>
                            </div> */}
            </div>

            <div
              onClick={() => history.push('/expert/feedback')}
              class='cursor-pointer col-md-6 showMobile d-flex justify-content-end'
            >
              <div class='gray_box rowMobile1 text-right align-items-center arrow-center-sm'>
                <h3 className='font-mobile-small'>Continue</h3>
                <i class='ml-1 font-mobile-small mobile-mr-4 fas fa-arrow-right'></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
