import React, { useState } from 'react'
import { MainFooter, MainHeader } from '../../components'
import { Element } from 'react-scroll'
import './index.css'
import { useHistory, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { upsertexpertfeedback } from '../../api/projects'

export default function ExpertFeedback ({}) {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const history = useHistory()
  const count = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [state, setState] = useState({
    score: 0,
    scorereason: '',
    improve: '',
    favourite: '',
    usertype: false,
    loading: false
  })

  const { score, scorereason, improve, favourite } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleFeedback = async () => {
    try {
      handleChange('loading', true)
      const payload = {
        expertid: id,
        score,
        scorereason,
        improve,
        favourite
      }
      const res = await upsertexpertfeedback(payload)
      if (res.data.result) {
        handleChange('loading', false)
        enqueueSnackbar('Feedback Added Successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        history.push('/expert/feedback/reference')
      } else {
        handleChange('loading', false)
        enqueueSnackbar(`Error: ${res.data.result.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }
    } catch (error) {
      handleChange('loading', false)
      // console.log('err', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }

  return (
    <div>
      <MainHeader />

      <Element name='Element3'>
        <section class='sec-4'>
          <div class='container'>
            <div class='col-md-6'>
              <div class='content'>
                <div className='d-flex justify-content-between align-items-start'>
                  <div className=''>
                    <h4 class='heading_font_black'>
                      Help us improve the Egtos platform
                    </h4>
                    <p className='text_blue'>
                      Error in {'<number>'} team member has declined, please
                      select a new expert on continue without replacement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <section class='sec-4'>
        <div class='container'>
          <div className='col-md-10'>
            <div class='question_box'>
              <h5 className={'text_green'}>
                How likely are you to recommend us to colleagues?
              </h5>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='light_blue_opacity'>Not at all likely</p>
                <p className='light_blue_opacity'>Extremely likely</p>
              </div>
              <div className='d-flex mt-3 justify-content-between align-items-center'>
                {count.map(item => (
                  <div
                    className={score === item ? 'borderedSelected' : 'bordered'}
                    onClick={() => handleChange('score', item)}
                  >
                    <p className='light_blue_opacity'>{item}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div class='question_box mt-5'>
              <h5 className={'mt-5 text_green'}>
                Why did you give us that score?
              </h5>
              <div class='companyInput ml-0'>
                <div class='logingroup'>
                  <textarea
                    type='text'
                    aria-multiline
                    onChange={value =>
                      handleChange('scorereason', value.target.value)
                    }
                    placeholder=''
                  />
                </div>
              </div>
            </div>
            <div class='question_box mt-5'>
              <h5 className={'mt-5 text_green'}>
                Whats one thing we could improve on?
              </h5>
              <div class='companyInput ml-0'>
                <div class='logingroup'>
                  <textarea
                    type='text'
                    aria-multiline
                    onChange={value =>
                      handleChange('improve', value.target.value)
                    }
                    placeholder=''
                  />
                </div>
              </div>
            </div>
            <div class='question_box mt-5'>
              <h5 className={'mt-5 text_green'}>
                Whats your favourite part about Egtos?
              </h5>
              <div class='companyInput ml-0'>
                <div class='logingroup'>
                  <textarea
                    type='text'
                    aria-multiline
                    onChange={value =>
                      handleChange('favourite', value.target.value)
                    }
                    placeholder=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='sec-4'>
        <div class='container'>
          <div class='d-flex'>
            <div class='logingroup width30 mr-3'>
              <button
                onClick={() => history.push('/dashboard')}
                class='btn btn-outline-warning btn_red'
              >
                Cancel
              </button>
            </div>
            <div class='logingroup width30'>
              <button
                onClick={() => handleFeedback()}
                class='btn btn-outline-warning'
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </section>

      <MainFooter />
    </div>
  )
}
