import React, { useState } from 'react'
import { Loader, Uploader } from 'rsuite'
import { isEmail } from '../../utils/utilities'

export default function Process4 ({
  onClickButton,
  currencylist,
  handleChange,
  projectCertification,
  onsitepercentageengagement,
  onsiteManager,
  survey,
  focusgroup,
  clientinterview,
  preferedPayment,
  leadingEgtos,
  loading,
  expectedCurrency,
  expectedAmount,
  refemail1,
  refemail2,
  refemail3,
  refemail4,
  taxIdentifcationNumber
}) {
  const [state, setState] = useState({
    required: false,
    addEmail: ''
  })
  const { required, addEmail } = state
  const handleChangeLocal = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const disabled =
    !expectedCurrency ||
    !expectedAmount ||
    !refemail1 ||
    !refemail2 ||
    !refemail3 ||
    !refemail4 ||
    !taxIdentifcationNumber

  const _onClickButton = () => {
    if (disabled) {
      handleChangeLocal('required', true)
      return
    }
    handleChangeLocal('required', false)
    onClickButton('process5')
  }

  const emailAddress = localStorage.getItem('email')
  return (
    <section class='loginOuter ptm-0'>
      <div class='ph-0'>
        <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12 mh-0'>
          <div class='signupProgress mb-5 hideWeb pb-5 pl-2 pt-5'>
            <div class='signupProgressIn'>
              <h4>Sign-up progress</h4>
              <div class='signProgStep'>
                <ul>
                  <li>
                    <a href='#' class='active'>
                      <span class='fill'>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Experience
                    </a>
                    <a href='#' class='active'>
                      <span class='fill'>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Values
                    </a>
                    <a href='#' class='active'>
                      <span class='fill'>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      About you
                    </a>
                    <a href='#' class='active'>
                      <span>
                        <i class='fas fa-check'></i>
                      </span>{' '}
                      Financial details
                    </a>
                    <a href='#'>
                      <span></span> Elevator pitch{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='container'>
        <div class='row'>
          <div class='col-lg-12'>
            <div class='login'>
              <div class='loginIn'>
                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='loginPara'>
                      <h3>Joining Egtos</h3>
                      <p>
                        And now we get on to the nitty gritty of money - let's
                        make sure you are fairly compensated and paid on time.
                      </p>
                    </div>

                    <div class='question_box mt-5'>
                      <h3 class='title'>Expected hourly rate</h3>
                      <h5>What is your expected hourly rate?</h5>

                      <div class='logingroup'>
                        <div class='row'>
                          <div class='col-lg-6 col-md-6 col-xs-6'>
                            <label for=''>Currency</label>
                            <div class='logingroupIn'>
                              <div class='select_wrap'>
                                <select
                                  class='form-select'
                                  onChange={value =>
                                    handleChange(
                                      'expectedCurrency',
                                      value.target.value
                                    )
                                  }
                                >
                                  {/* <option value=''>
                                   $ (US dollars)
                                  </option> */}

                                  {currencylist?.map((currency, index) => (
                                    <option key={index} value={currency?.code}>
                                      {currency?.name +
                                        ' (' +
                                        currency?.code +
                                        ')'}
                                    </option>
                                  ))}
                                </select>
                                <button type='button'>
                                  <i class='fas fa-arrow-down'></i>
                                </button>
                              </div>
                            </div>
                            {required && !expectedCurrency && (
                              <div style={{ marginTop: -15 }}>
                                <small>Currency is required !</small>
                              </div>
                            )}
                          </div>

                          <div class='col-lg-6 col-md-6 col-xs-6'>
                            <label for=''>Amount</label>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange(
                                  'expectedAmount',
                                  value.target.value
                                )
                              }
                              placeholder='other'
                            />
                            {required && !expectedAmount && (
                              <div>
                                <small>Amount is required !</small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='col-lg-3 offset-lg-3 col-md-3 offset-md-1 col-sm-12'>
                    <div class='signupProgress'>
                      <div class='signupProgressIn'>
                        <h4>Sign-up progress</h4>
                        <div class='signProgStep'>
                          <ul>
                            <li>
                              <a href='#' class='active'>
                                <span class='fill'>
                                  <i class='fas fa-check'></i>
                                </span>{' '}
                                Experience
                              </a>
                              <a href='#' class='active'>
                                <span class='fill'>
                                  <i class='fas fa-check'></i>
                                </span>{' '}
                                Values
                              </a>
                              <a href='#' class='active'>
                                <span class='fill'>
                                  <i class='fas fa-check'></i>
                                </span>{' '}
                                About you
                              </a>
                              <a href='#' class='active'>
                                <span></span> Financial details
                              </a>
                              <a href='#'>
                                <span></span> Elevator pitch{' '}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='question_box'>
                      <h3 class='title'>Leadership</h3>
                      <h5>
                        Would you be interested in leading Egtos clients'
                        projects?
                      </h5>
                      <div class='row mb-4'>
                        <div class='col-lg-12'>
                          <div class='loginSwitch d-flex align-items-center'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    formControlName='leadership'
                                    type='checkbox'
                                    checked={!leadingEgtos}
                                    onChange={value =>
                                      handleChange(
                                        'leadingEgtos',
                                        value.target.checked
                                      )
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>
                        If yes, do you have any of the project management
                        certification Listed below?
                      </h5>

                      <div class='loginGenderRadio'>
                        <div class='row'>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={projectCertification === 'PMP'}
                                onClick={value =>
                                  handleChange(
                                    'projectCertification',
                                    projectCertification === 'PMP' ? '' : 'PMP'
                                  )
                                }
                                formControlName='pmp'
                                value='PMP'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio1'
                              >
                                PMP
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={projectCertification === 'PMP-ACP'}
                                onClick={value =>
                                  handleChange(
                                    'projectCertification',
                                    projectCertification === 'PMP-ACP'
                                      ? ''
                                      : 'PMP-ACP'
                                  )
                                }
                                formControlName='pmp'
                                value='PMP-ACP'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio2'
                              >
                                PMP-ACP
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={projectCertification === 'Both'}
                                onClick={value =>
                                  handleChange(
                                    'projectCertification',
                                    projectCertification === 'Both'
                                      ? ''
                                      : 'Both'
                                  )
                                }
                                formControlName='pmp'
                                value='Both'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio2'
                              >
                                Both
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>
                        If the client requires the engagement manager to be
                        onsite few days a week, would you be interested in such
                        project?
                      </h5>
                      <div class='row mb-4'>
                        <div class='col-lg-4 col-md-4 col-sm-12'>
                          <div class='loginSwitch d-flex align-items-center'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    formControlName='onsite'
                                    type='checkbox'
                                    checked={!onsiteManager}
                                    onChange={value =>
                                      handleChange(
                                        'onsiteManager',
                                        value.target.checked
                                      )
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>
                        If yes, please select one onsite option below you would
                        be willing to do as the engagement manager:
                      </h5>

                      <div class='loginGenderRadio'>
                        <div class='row'>
                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '25'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='25'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio1'
                              >
                                25%
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '50'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='50'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio2'
                              >
                                50%
                              </label>
                            </div>
                          </div>

                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '70'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='70'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio3'
                              >
                                70%
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-3 col-md-3 col-xs-6'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={onsitepercentageengagement === '100'}
                                onChange={value =>
                                  handleChange(
                                    'onsitepercentageengagement',
                                    value.target.value
                                  )
                                }
                                formControlName='percentage'
                                value='100'
                              />
                              <label
                                class='form-check-label'
                                for='ethnicRadio4'
                              >
                                100%
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>
                        Would you be interested in providing expert opinion for
                        additional compensation?
                      </h5>
                      <div class='row mb-4'>
                        <div class='col-lg-12'>
                          <div class='loginSwitch d-flex align-items-center'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    type='checkbox'
                                    checked={!survey}
                                    onChange={value =>
                                      handleChange(
                                        'survey',
                                        value.target.checked
                                      )
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>by completing a survey</p>
                          </div>
                        </div>
                      </div>

                      <div class='row mb-4'>
                        <div class='col-lg-12'>
                          <div class='loginSwitch d-flex align-items-center'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    type='checkbox'
                                    checked={!focusgroup}
                                    onChange={value =>
                                      handleChange(
                                        'focusgroup',
                                        value.target.checked
                                      )
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>being part of a focus group</p>
                          </div>
                        </div>
                      </div>

                      <div class='row mb-4'>
                        <div class='col-lg-12'>
                          <div class='loginSwitch d-flex align-items-center'>
                            <div class='toggle-button-cover'>
                              <div class='button-cover'>
                                <div class='buttonn b2' id='button-13'>
                                  <input
                                    type='checkbox'
                                    checked={!clientinterview}
                                    onChange={value =>
                                      handleChange(
                                        'clientinterview',
                                        value.target.checked
                                      )
                                    }
                                    class='checkbox'
                                  />
                                  <div class='knobs'>
                                    <span></span>
                                  </div>
                                  <div class='layer'></div>
                                </div>
                              </div>
                            </div>
                            <p class='ms-3 ml-2'>
                              being part of One-on-interview with client
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='border_line'></div>

                <div class='row'>
                  <div class='col-lg-6 col-md-8'>
                    <div class='question_box'>
                      <h3 class='title'>References</h3>
                      <h5>
                        We value your references opinion so let's hear from them
                        (Please enter four references corporate email addresses
                        below)
                      </h5>
                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange('refemail1', value.target.value)
                              }
                              placeholder='Reference #1 corporate email address'
                            />
                            {required && !refemail1 && (
                              <div>
                                <small>Reference #1 Email is required !</small>
                              </div>
                            )}
                            {/* {(refemail1 === refemail2 ||
                              refemail1 === refemail3 ||
                              refemail1 === refemail4) && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )} */}
                            {refemail1 === emailAddress && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {refemail1 &&
                              refemail1 !== emailAddress &&
                              isEmail(refemail1) && (
                                <div>
                                  <small>
                                    {' '}
                                    Email must be a valid email address !
                                  </small>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange('refemail2', value.target.value)
                              }
                              placeholder='Reference #2 corporate email address'
                            />
                            {required && !refemail2 && (
                              <div>
                                <small>Reference #2 Email is required !</small>
                              </div>
                            )}
                            {refemail2 === emailAddress && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {(refemail2 === refemail1 ||
                              refemail2 === refemail3 ||
                              refemail2 === refemail4) && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {refemail2 &&
                              refemail2 !== emailAddress &&
                              isEmail(refemail2) && (
                                <div>
                                  <small>
                                    {' '}
                                    Email must be a valid email address !
                                  </small>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange('refemail3', value.target.value)
                              }
                              placeholder='Reference #3 corporate email address'
                            />
                            {required && !refemail3 && (
                              <div>
                                <small>Reference #3 Email is required !</small>
                              </div>
                            )}
                            {refemail3 === emailAddress && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {(refemail3 === refemail1 ||
                              refemail3 === refemail2 ||
                              refemail3 === refemail4) && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {refemail3 &&
                              refemail3 !== emailAddress &&
                              isEmail(refemail3) && (
                                <div>
                                  <small>
                                    {' '}
                                    Email must be a valid email address !
                                  </small>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div class='row'>
                        <div class='companyInput1'>
                          <div class='logingroup'>
                            <input
                              type='text'
                              onChange={value =>
                                handleChange('refemail4', value.target.value)
                              }
                              placeholder='Reference #4 corporate email address'
                            />
                            {required && !refemail4 && (
                              <div>
                                <small>Reference #4 Email is required !</small>
                              </div>
                            )}
                            {refemail4 === emailAddress && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {(refemail4 === refemail1 ||
                              refemail4 === refemail3 ||
                              refemail4 === refemail2) && (
                              <div>
                                <small>
                                  References email must be different !
                                </small>
                              </div>
                            )}
                            {required &&
                              refemail4 &&
                              refemail4 === emailAddress &&
                              isEmail(refemail4) && (
                                <div>
                                  <small>
                                    {' '}
                                    Email must be a valid email address !
                                  </small>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h3 class='title'>Taxes</h3>
                      <h5>Yeah, we all have to pay it.</h5>
                      <h5>
                        What is your national identification number for
                        reporting your taxes in your country?
                      </h5>
                      <div class='logingroup'>
                        <input
                          type='text'
                          onChange={value =>
                            handleChange(
                              'taxIdentifcationNumber',
                              value.target.value
                            )
                          }
                          placeholder='National identification number'
                        />
                        {required && !taxIdentifcationNumber && (
                          <div>
                            <small>
                              National identification number is required !
                            </small>
                          </div>
                        )}
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>
                        If you pay your taxes through a registered business
                        entity as an independent consultant, please upload the
                        official document with your business registration number
                        or employer identification number:
                      </h5>
                      <div class='logingroupUpload'>
                        <div class='upload-btn-wrapper'>
                          <Uploader
                            listType='picture-text'
                            accept='application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            action='//jsonplaceholder.typicode.com/posts/'
                            renderFileInfo={(file, fileElement) => {
                              return (
                                <div>
                                  <span>File Name: {file.name}</span>
                                  <p style={{ marginLeft: 13 }}>
                                    File type: {file.blobFile.type}
                                  </p>
                                </div>
                              )
                            }}
                          >
                            <button class='btn'>
                              Upload your company details
                            </button>
                          </Uploader>
                        </div>

                        <span>File formates: Word or PDF</span>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>What is your prefered payment option?</h5>

                      <div class='loginGenderRadio'>
                        <div class='row'>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={preferedPayment === 'ACH'}
                                onChange={value =>
                                  handleChange(
                                    'preferedPayment',
                                    value.target.value
                                  )
                                }
                                value='ACH'
                              />
                              <label class='form-check-label' for=''>
                                Bank transfer - ACH
                              </label>
                            </div>
                          </div>
                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check mobilemb'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={preferedPayment === 'PayPal'}
                                onChange={value =>
                                  handleChange(
                                    'preferedPayment',
                                    value.target.value
                                  )
                                }
                                value='PayPal'
                              />
                              <label class='form-check-label' for=''>
                                PayPal
                              </label>
                            </div>
                          </div>

                          <div class='col-lg-4 col-md-4 col-xs-6'>
                            <div class='form-check'>
                              <input
                                class='form-check-input'
                                type='radio'
                                checked={preferedPayment === 'Wise'}
                                onChange={value =>
                                  handleChange(
                                    'preferedPayment',
                                    value.target.value
                                  )
                                }
                                value='Wise'
                              />
                              <label class='form-check-label' for=''>
                                Wise
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='question_box'>
                      <h5>
                        Please enter the applicable bank information for
                        earnings transfer
                      </h5>
                      <div class='row'>
                        <div class='col-sm-6'>
                          <div class='logingroup inputMargin2'>
                            <input
                              type='text'
                              formcontrolname='text'
                              placeholder='Account Number'
                            />
                          </div>
                        </div>
                        <div class='col-sm-6'>
                          <div class='logingroup inputMargin2'>
                            <input
                              type='text'
                              formcontrolname='text'
                              placeholder='Routing Number'
                            />
                          </div>
                        </div>
                      </div>
                      <div class='row marginInputMobile'>
                        <div class='col-sm-6'>
                          <div class='logingroup inputMargin2'>
                            <input
                              type='text'
                              formcontrolname='text'
                              placeholder='IBAN'
                            />
                          </div>
                        </div>
                        <div class='col-sm-6'>
                          <div class='logingroup inputMargin2'>
                            <input
                              type='text'
                              formcontrolname='text'
                              placeholder='Bank code (BIC/SWIFT)'
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='logingroup mt-5'>
                      <button
                        onClick={() => _onClickButton()}
                        class='btn btn-outline-warning'
                      >
                        {loading ? <Loader /> : 'Save and move on'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
